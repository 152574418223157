import {instance, onErrorFunction} from "../../config/axios-cnf";


export const apiGetSubjects = (callback, error) => {
    instance.get(`/api/v2/subjects`)
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}
export const apiGetSubjectById = (id, callback, error) => {
    instance.get(`/api/v2/subjects/${id}`)
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}
export const apiUpdateSubject = (values, callback, error) => {
    instance.post(`/api/v2/subject`, values)
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}
export const apiCreateSubject = (values, callback, error) => {
    instance.put(`/api/v2/subject`, values)
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}
