import React, {useEffect, useState} from "react";
import {apiPostFilterTicket} from "./Actions";
import {DefaultLoader} from "../../../components/DefaultLoader";
import TicketTable from "./TicketTable";
import Toast from "cogo-toast";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import Widget from "../../../components/widget";
import {Form} from "react-final-form";
import InputField from "../../../FinalForm/InputField";
import {Button} from "../../../components/My/Button";
import {FiRefreshCcw} from "react-icons/fi";
import ApiTariffSelectField from "../../../FinalForm/api/ApiTariffSelectField";
import UserArealSelect from "../Turnstiles/UserArealSelect";
import TicketFormModal from "./TicketFormModal";
import {useParams} from "react-router-dom";


const Ticket = () => {
   let [ticketModal, setTicketModal] = useState(false)
   let [data, setData] = useState(null);
   let [loading, setLoading] = useState(false);
   let user = useSelector((state) => state.user)
    let {filterTicket} = useSelector(state => ({filterTicket: state.filterTicket}), shallowEqual);
    const dispatch = useDispatch();

    let filterValues = null;


    const getData = (filterValues) => {
            if (filterValues?.tariff || filterValues?.bc || filterValues?.name || filterValues?.note) {
                setLoading(true);
                dispatch({
                    type: 'SET_FILTER_TICKET',
                    payload: filterValues
                })
                apiPostFilterTicket(user.activeAreal.id, filterValues, (data) => {
                    setData(data);
                    setLoading(false);
                }, (error) => {
                    Toast.error("Při načítání nahraných vstupenek nastala chyba.");
                    setData(null)
                    setLoading(false);
                })
            }
    }

   const _renderBody = () => {
      if (loading) return <DefaultLoader text={"Načítám vstupenky..."} />;
      if (!loading && data) return <TicketTable data={data} setData={setData} />
      else return <TicketTable data={[]} setData={setData} />
   }

   const renderFilterForm = () => (
      <Widget>
         <div className={"text-sm"} >
            <Form onSubmit={getData}
                  initialValues={filterTicket.filterTicketData}
                  render={({values, handleSubmit}) => {
                     filterValues = values;
                     return (
                        <div className={"grid lg:grid-cols-4 grid-cols-2 gap-2"} >
                           <ApiTariffSelectField isMulti={false} disabled={false} onEnter={handleSubmit} />
                           <InputField onEnter={handleSubmit} label={"BC"} name={"bc"} isMandatory={false}
                                       inputType={"text"} />
                           <InputField onEnter={handleSubmit} label={"Jméno"} name={"name"} isMandatory={false} />
                           <InputField onEnter={handleSubmit} label={"Poznámka"} name={"note"} isMandatory={false} />
                           <div className={"lg:col-start-4 flex justify-end w-full"} >
                              <Button onClick={handleSubmit} loading={loading} text={"Aktualizovat"}
                                      icon={<FiRefreshCcw />} />
                           </div >
                        </div >
                     );
                  }} />
         </div >
      </Widget >
   )


   return (
      <>
         <UserArealSelect />
         <Widget >
            <div className={"flex flex-row text-black"} >
               <h5 className={"align-middle"} >Vstupenky</h5 >
               <span className="ml-auto" ></span >
               <div className={"flex"} >
                  {user.activeAreal && <>
                     <div className={"mr-1"} ><Button text={"Přidat vstupenky"} link={"/ticket/add"} /></div >
                     <div ><Button text={"Import vstupenek"} onClick={() => setTicketModal(true)} /></div >
                  </>}
               </div >
            </div >
         </Widget >
         {user.activeAreal && renderFilterForm()}
         {user.activeAreal && _renderBody()}

         <TicketFormModal
            visible={ticketModal}
            close={(renew) => {
               setTicketModal(false)
               if (renew) getData()
            }} />

      </>
   )

}
export default Ticket;
