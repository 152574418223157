import {FiX} from "react-icons/fi";
import Portal from "../portal";
import React, {useEffect} from "react";


const Modal = ({visible, accept, close, title, body, footer}) => {
    useEffect(() => {
        if (visible) {
            document.body.style.overflow = "hidden";
            return () => {
                document.body.style.overflow = "unset";
            };
        }
    }, [visible]);
    if(!visible) return null

    return(
       <Portal selector="#portal">
           <div className="modal-backdrop fade-in "></div>
           <div className={`modal show`} data-background={"white"}>
               <div className="fixed inset-0 bg-grey-800" style={{ opacity: 0.4 }}></div>
               <div className="relative min-w-sm w-auto mx-auto lg:w-1/2 sm:w-full">
                   <div className="modal-content bg-white" style={{ maxHeight: 'calc(100vh - 100px)', overflowY: 'auto' }}>
                       <div className="modal-header">
                           <h3 className="text-xl font-semibold">{title}</h3>
                           <button className="modal-close btn btn-transparent" onClick={close}>
                               <FiX size={18} className="stroke-current" />
                           </button>
                       </div>
                       <div className="relative p-4 flex-auto">{body}</div>
                       <div className="modal-footer space-x-2">{footer}</div>
                   </div>
               </div>
           </div>
       </Portal>
    )
}

export default Modal
