import React from "react"
import Modal from "../../components/My/Modal";
import {Button} from "../My/Button";


const ModalSimple = ({visible, close, accept, body, title}) => {


    if (!visible) return null
    return (
        <>
            <Modal body={body}
                   title={title}
                   visible={visible}
                   close={() => close()}
                   footer={<>
                       <Button text={"Zrušit"} onClick={() => close()}/>
                       <Button text={"Potvrdit"} onClick={()=> accept()}/>
                   </>
                   }
            />
        </>
    )
}

export default ModalSimple
