import React, {useEffect, useState} from "react";
import {apiPostZoneStats} from "./Actions";
import {useSelector} from "react-redux";
import UserArealSelect from "../Turnstiles/UserArealSelect";
import Header from "../../../components/My/Header";
import Widget from "../../../components/widget";
import {Form} from "react-final-form";
import moment from "moment";
import DateTimeField from "../../../FinalForm/DateTimeField";
import { format, eachDayOfInterval } from 'date-fns';
import {Button} from "../../../components/My/Button";


const ZoneStats = (props) => {
    let [data, setData] = useState(null);
    let [loading, setLoading] = useState(false);
    let [days, setDays] = useState([]);

    let user = useSelector((state) => state.user)

    useEffect(()=>{
        (user?.activeAreal?.id && days.length>0)&& getData();
    },[])

    useEffect(() => {
        (user?.activeAreal?.id && days.length>0)&& getData();
    }, [user.activeAreal,days]);

    const getData = () => {
        setLoading(true);
        apiPostZoneStats(user.activeAreal.id, {type: 0, days: days}, (response) => {
            setData(response);
            setLoading(false);
        }, (err) => {
            setData(null);
            setLoading(false);
        })
    }

    const _renderBody = () => {
        if (loading) return <>Načítám data</>
        if (!loading && !data) return <>Žádná data</>
        return <Widget>
            <div className={"grid grid-cols-5 gap-2"}>
                {data.map((item, index) => (
                    <div className={"p-2 rounded bg-blue-500 text-white flex flex-col"} key={index}>
                        <span className={"text-xl font-bold"}>{item.day}</span>
                        <span className={"text-lg"}><b>V areálu:</b> {item.inAreal}</span>
                        <span className={"text-lg"}><b>Celková návštěvnost:</b> {item.attendence}</span>
                    </div>
                ))}
            </div>

        </Widget>
        return <>{JSON.stringify(data, null, 2)}</>
    }

    return (
        <>
            <UserArealSelect/>
            <Widget>
                <Form initialValues={{dateFrom:format(new Date(),'yyyy-MM-dd')}} onSubmit={(values)=>{
                    if(values.dateTo){
                        const startDate = new Date(values.dateFrom);
                        const endDate = new Date(values.dateTo);

                        // Získá pole všech dní v zadaném rozsahu
                        const daysInRange = eachDayOfInterval({ start: startDate, end: endDate });

                        // Převede objekty Date zpět na stringy ve formátu YYYY-MM-DD
                        const formattedDays = daysInRange.map((day) => format(day, 'yyyy-MM-dd'));
                        setDays(formattedDays)
                    }else {
                        setDays([values.dateFrom]);
                    }

                }} render={({handleSubmit, values}) => {
                    return (
                        <div className={"grid grid-cols-2 gap-2"}>
                            <DateTimeField name={"dateFrom"} isMandatory={true} valueToCompare={values.dateTo}
                                           validateFun={(date1, date2) => {
                                               if (moment(date1).isAfter(moment(date2))) {
                                                   return "Den od nemůže být po dni do"
                                               } else {
                                                   return "";
                                               }
                                           }} label={"Den od"} type={"from"} placeholder={"Datum od"}
                                           showTimeSelect={false} onEnter={handleSubmit}/>
                            <DateTimeField name={"dateTo"} isMandatory={false} valueToCompare={values.dateFrom}
                                           validateFun={(date1, date2) => {
                                               if (moment(date2).isAfter(moment(date1))) {
                                                   return "Den od nemůže být po dni do"
                                               } else {
                                                   return "";
                                               }
                                           }} label={"Den do"} type={"to"} placeholder={"Datum do"}
                                           showTimeSelect={false} onEnter={handleSubmit}/>
                            <div className={"col-start-2 flex justify-end w-full"}>
                                <Button text={"Aktualizovat"} onClick={handleSubmit}/>
                            </div>
                        </div>
                    )
                }}/>
            </Widget>
            <Header data={[{text: "Zóny", isMain: false, link: "/zone"}, {text: "Statistiky zón", isMain: true}]}>
                {user.activeAreal && _renderBody()}
            </Header>
        </>
    )
}

ZoneStats.propTypes = {}
export default ZoneStats;
