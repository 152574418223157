import React, {useEffect, useState} from "react"
import TableData from "./TableData";
import Widget from "../../components/widget";
import {apiGetReaders} from "./Actions";

const Readers = () => {

    let [loading, setLoading] = useState(false)
    let [data, setData] = useState(null)

    useEffect(() => {
        getReaders()
    }, [])

    const getReaders = () => {
        setLoading(true)
        apiGetReaders(null,(data) => {
            setData(data)
            setLoading(false)
        }, (err) => {
            setLoading(false)
        })
    }


    return (
        <>
            <Widget>
                <div className={"flex flex-row text-black"}>
                    <h5 className={"align-middle"}>Čtečky</h5>
                    <span className="ml-auto"></span>
                </div>
            </Widget>
            <TableData data={data} loading={loading}/>

        </>
    )
}

export default Readers
