import React, {useState} from "react"
import Widget from "../../components/widget";
import Datatable from "../../components/datatable";
import {Button} from "../../components/My/Button";
import {EnigooLoader} from "../../components/My/EnigooLoader";
import moment from "moment/moment";
import ArenaExternTicketsModal from "./ArenaExternTicketsModal";
import ApproveModal from "./Modal/ApproveModal";
import {apiImportEventToTurnstile} from "./Actions";

const TableData = ({data, loading, subjectId}) => {

    let [arenaExternTicketsModal, setArenaExternTicketsModal] = useState(false)
    let [importTicketToTurnstile, setImportTicketToTurnstile] = useState(false)
    let [item, setItem] = useState(null)

    if (loading) return <EnigooLoader/>
    if (!data) return null


    const importTicket = () => {

        apiImportEventToTurnstile(subjectId, importTicketToTurnstile, (data)=>{

        }, error=>{

        })

        setImportTicketToTurnstile(null)

    }


    let columns = [
            {Header: 'Název události', accessor: "name"},
            {
                Header: 'Datum události', accessor: d => (
                    <span>{moment(d.startTime).format("DD.MM.YYYY")}</span>

                )
            },
            {
                Header: 'Vstupenek/importováno', accessor: d => (
                    <span>{d.ticketCount ? d.ticketCount : 0}/{d.importedCount ? d.importedCount : 0}</span>

                )
            },
            {Header: 'Počet průchodů', accessor: "entranceCount"},

            {
                Header: 'Stažení', accessor: d => (
                    <Button text={"Stáhnout"} link={"/.../" + d.id}/>
                )
            },
            {
                Header: 'Vložit externí vstupenky', accessor: d => (
                    <Button text={"Vložit"} onClick={() => {
                        setItem(d)
                        setArenaExternTicketsModal(true)
                    }}/>
                )
            },
            {
                Header: 'Importovat', accessor: d => (
                    <Button text={"Import"} onClick={()=> setImportTicketToTurnstile(d.id)}/>
                )
            },
        ]
    ;


    return (
        <>
            <Widget>
                <Datatable data={data} columns={columns}/>
            </Widget>
            <ArenaExternTicketsModal
                visible={arenaExternTicketsModal}
                event={item}
                accept={() => {
                    setArenaExternTicketsModal(false)
                }}
                close={() => {
                    setArenaExternTicketsModal(false)
                }}/>

            <ApproveModal visible={!!importTicketToTurnstile} close={()=>setImportTicketToTurnstile(null)}
                          accept={()=>importTicket()} text={"Opravdu chcete importovat vstupenky ? "} />

        </>

    )
}

export default TableData
