import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import Toast from "cogo-toast";
import Datatable from "../../../components/datatable";
import {apiDeleteDevice} from "./Actions";
import {FiDelete, FiEdit} from "react-icons/fi";

const DeviceTable = ({data,setData}) => {


    let tableData = React.useMemo(() => {
        return data;
    }, [data])

    /*const deleteItem = (id) => {
        apiDeleteDevice(id,(response)=>{
            let array = [...data];
            array = array.filter((val)=>val.id!=id);
            setData(array);
            Toast.success("Zařízení bylo odstraněno");
        },(error)=>{
            let errorMessage = "Nepodařilo se odstranit zařízení.\n"
            switch (error.data){
                case "DEVICE_NOT_FOUND":
                    errorMessage+="Nebyl nalezen.";
                    break;
                default:
                    errorMessage+="Neznámá chyba";
                    break;
            }
            Toast.error(errorMessage);
        })

    }*/

    let columns = React.useMemo(() => [
        {Header: '#ID', accessor: 'id'},
        {Header: 'IP adresa', accessor: 'ipAddress'},
        {Header: 'Název', accessor: 'name'},
        {Header: 'Aktivní', accessor: d=>d.active&&d.active===1?"ANO":"NE"},
        {Header: 'Typ', accessor: d => {
            if(d.type!==null){
                switch (d.type){
                    case 0:
                        return "Brána";
                    case 1:
                        return "Displej";
                    default:
                        return "-";
                }
            }
            }},
        {Header: 'Port', accessor: d => d.port!==null?d.port:"-"},
        {Header: "Spouštět původní akci", accessor: d=>d.doDefault&&d.doDefault===1?"ANO":"NE"},
        {Header: 'Tarify', accessor: d => <div className={"bg-blue-500 text-white rounded text-center font-bold"}> {d?.tariff?.length} </div>},
        {
            Header: "Možnosti", accessor: d => (
                <div className={"flex flex-row"}>
                    <Link to={"/device/detail/" + d.id}>
                        <button
                            className={"btn btn-default rounded btn-icon bg-blue-500 hover:bg-blue-400 space-x-2 mt-1 px-5 text-white "}>
                            <FiEdit size={20}/>
                        </button>
                    </Link>
                        {/*<button
                            className={"btn btn-default rounded btn-icon bg-blue-500 hover:bg-blue-400 space-x-2 mt-1 px-5 text-white ml-2 "}
                            onClick={() => {
                                deleteItem(d.id)
                            }}>
                            <FiDelete size={20}/>
                        </button>*/}
                </div>
            )
        }
    ])

    return <Datatable data={tableData} columns={columns} selectable={false} />
}

export default DeviceTable;
