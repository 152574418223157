import React, {useEffect, useState} from "react"
import {apiGetEvents, apiGetUserSubjects} from "./Actions";
import TableData from "./TableData";
import {Filter} from "./Filter";
import Widget from "../../components/widget";
import {Button} from "../../components/My/Button";
import ArenaEventsModal from "./ArenaEventsModal";
import Toast from "cogo-toast";

const ArenaEvents = () => {

    let [arenaModal, setArenaModal] = useState(false)
    let [loading, setLoading] = useState(false)
    let [data, setData] = useState(null)
    let [subjectId, setSubjectId] = useState(null)
    let [showFilter, setShowFilter] = useState(false)

    useEffect(() => {
        getUserSubjects()
    }, []);


    const getUserSubjects = () => {
        apiGetUserSubjects(data => {
            if (data.length === 1){
                setShowFilter(false)
                getEvent(data[0].id)
            }else {
                setShowFilter(true)
            }
        }, error => {
        })
    }
    const getEvent = (values) => {
        const _subjectId = values?.subjectId || values;
        setSubjectId(_subjectId)
        setData(null)
        setLoading(true)
        apiGetEvents(_subjectId, data => {
            setData(data)
            setLoading(false)
        }, error => {
            switch (error.error.type) {
                case "SUBJECT_NOT_EXIST":
                    Toast.error(" Subjekt neexistuje.")
                    break;
                default:
                    Toast.error(" Nastala neočekávaná chyba.")
            }
            setLoading(false)
            setData(null)
        })
    }

    return (
        <>
            {showFilter && <Filter getEvent={getEvent} loading={loading} setData={setData}/>}
            <div className={"widget w-full p-4 mb-4 rounded-lg bg-blue-100 border border-grey-100"}>
                <p><span className="font-bold">Stáhnout</span> - Stáhne všechny barcody, které jsou k akci přiřazeny.
                    Lze i opakovaně.</p>
                <p><span className="font-bold">Vložit externí</span> - Naimportuje externí kódy k akci ze souboru csv.
                    Lze i opakovaně.</p>
                <p><span className="font-bold">Importovat</span> - Provede import všech kódů, které jsou u akce
                    vytvořený/vloženy importem. Lze i opakovaně.</p>
            </div>
            {data &&
                <Widget title={<div className={"text-xl"}>Události</div>}
                        right={<Button text={"Přidat událost"} onClick={() => {
                            setArenaModal(true)
                        }}/>}>
                    <TableData data={data} subjectId={subjectId}/>
                </Widget>
            }

            <ArenaEventsModal
                visible={arenaModal}
                subjectId={subjectId}
                accept={() => {
                    setArenaModal(false)
                }}
                close={() => {
                    setArenaModal(false)
                }}/>

        </>
    )
}

export default ArenaEvents
