import {instance, onErrorFunction, onSuccessFunction} from "../../../config/axios-cnf";

const url = "/api/v2/enigoo/"

export const apiGetTurnstile = (subjectId, id = null, callback, error) => {
    if (id == null) {
        instance.get("/api/v2/enigoo/" + subjectId + "/turnstile")
            .then((response) => callback(onSuccessFunction(response.data)))
            .catch((err) => error(onErrorFunction(err)));
    } else {
        instance.get("/api/v2/enigoo/" + subjectId + "/turnstile/" + id)
            .then((response) => callback(onSuccessFunction(response.data)))
            .catch((err) => error(onErrorFunction(err)));
    }
}

export const apiPostTurnstile = (subjectId, data, callback, error) => {
    instance.post("/api/v2/enigoo/" + subjectId + "/turnstile", data)
        .then((response) => callback(onSuccessFunction(response.data)))
        .catch((err) => error(onErrorFunction(err)));
}

export const apiDeleteTurnstile = (subjectId, id, callback, error) => {
    instance.delete("/api/v2/enigoo/" + subjectId + "/turnstile/" + id)
        .then((response) => callback(onSuccessFunction(response.data)))
        .catch((err) => error(onErrorFunction(err)));
}

export const apiGetUnknownTurnstile = (subjectId,id, callback, error) => {
    instance.get("/api/v2/enigoo/" + subjectId + "/turnstile/unknown/" + id)
        .then((response) => callback(onSuccessFunction(response.data)))
        .catch((err) => error(onErrorFunction(err)));
}
