import React, {useEffect} from "react";
import {useDropzone} from "react-dropzone";

const DropZone = ({onFileUpload, input , height="48"}) => {
    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
        maxFiles: 1
    });

    useEffect(()=>{
        if (acceptedFiles.length>0) {
            const file = acceptedFiles[0]
            onFileUpload(file)
        }
    },[acceptedFiles])
    

    const files = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));

    return (
        <section className={"flex flex-col items-center p-5 bg-gray-100 space-y-5"}>
            <div {...getRootProps(
                {className: `dropzone w-full h-${height} p-6 border-2 border-dashed border-gray-400 rounded-md text-center cursor-pointer hover:bg-gray-400 flex items-center justify-center`})}>
                <input {...getInputProps()}/>
                <h6 className={"flex-center"}>Přesuňte soubor zde nebo klikněte pro výběr.</h6>
            </div>
            <aside className={"w-full"}>
                <h6 className={"mb-2 font-light text-gray-800"}>{acceptedFiles.length>0 ? "Vybraný soubor: " : ""}</h6>
                <ul className={"list-decimal pl-5"}>{files}</ul>
            </aside>
        </section>
    )
}

export default DropZone