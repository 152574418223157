

export default function filterEntrance(
    state = {
        filterEntranceData:null,
        filterEntranceLogData:null
    },action
){
    switch (action.type){
        case "SET_FILTER_ENTRANCE":
            return{
                ...state,
                filterEntranceData:action.payload
            }
        case "SET_FILTER_ENTRANCE_LOG":
            return{
                ...state,
                filterEntranceLogData:action.payload
            }
        case "FILTER_ENTRANCE_RESET":
            return {
                ...state, filterEntranceData:null,filterEntranceLogData: null
            }
        default:
            return state;
    }
}
