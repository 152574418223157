import {instance, onErrorFunction} from "../../config/axios-cnf";


export const apiGetTurniketRules = (callback, error) => {
    instance.get(`/api/v2/turniket/rule`)
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}
export const apiCreateTurniketRule = (values, callback, error) => {
    instance.put(`/api/v2/turniket/rule`, values)
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}
export const apiUpdateTurniketRule = (values, callback, error) => {
    instance.post(`/api/v2/turniket/rule`, values)
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}
export const apiGetRuleById = (id, callback, error) => {
    instance.get(`/api/v2/turniket/rule/${id}`)
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}