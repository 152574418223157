import React from "react"
import Widget from "../../components/widget";
import {Calendar} from "../../components/calendar";

const Test = () => {
   let events = []

   return (
      <Widget >
         <Calendar events={events} />
      </Widget >
   )
}

export default Test;
