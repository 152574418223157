import React, {useState} from "react"

import Datatable from "../../components/datatable";
import Loader from "../../components/loader";
import Widget from "../../components/widget";
import moment from "moment";


const TableData = ({data,   loading}) => {

   if(loading) return <Loader/>
   if (!data) return null

   let columns = [
      {Header: '#', accessor: "name"},
      {Header: 'Spuštěn', accessor: d => (d.on ? "ANO" : "NE")},
      {Header: 'Aktuálně běží', accessor: d => (d.running ? "ANO" : "NE")},
      {Header: 'Poslední spuštění', accessor: d => d.lastDate ? moment(d.lastDate).format("DD.MM.YYYY HH:mm") : "-"},
   ];


   return (
      <Widget title={"Cron"}>
         <Datatable data={data} columns={columns} showPagination={data.length>=10}/>
      </Widget>
   )
}

export default TableData
