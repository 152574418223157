import React from 'react'
import App from './App'
import { ClearCacheProvider } from 'react-clear-cache';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {createRoot} from "react-dom/client";

import * as serviceWorker from './serviceWorker'

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
    <ClearCacheProvider auto={true} duration={60000} basePath={process.env.NODE_ENV === "development" ? '/' :'/'}>
        <Router basename={process.env.NODE_ENV === "development" ? "/" : "/app/"}>
            <Routes>
                <Route path={'*'} element={<App />} />
            </Routes>
        </Router>
    </ClearCacheProvider>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
