import React, {useEffect, useState} from "react";
import Toast from "cogo-toast";
import {DefaultLoader} from "../../../components/DefaultLoader";
import {apiGetZone} from "./Actions";
import {Button} from "../../../components/My/Button";
import Widget from "../../../components/widget";
import UserArealSelect from "../Turnstiles/UserArealSelect";
import {useSelector} from "react-redux";
import ZoneTable from "./ZoneTable";
import Header from "../../../components/My/Header";

const Zone = () => {
    let [data, setData] = useState(null);
    let [loading, setLoading] = useState(false);
    let user = useSelector((state) => state.user)


    useEffect(() => {
        user.activeAreal && getData()
    }, [user.activeAreal]);

    const getData = () => {
        setLoading(true);
        apiGetZone(user.activeAreal.id, null, (data) => {
            setData(data);
            setLoading(false);
        }, (error) => {
            Toast.error("Při načítání zón nastala chyba.");
            setLoading(false);
        })
    }

    const _renderBody = () => {
        if (loading) return <DefaultLoader text={"Načítám zóny..."}/>;
        if (!loading && data) return <ZoneTable data={data} setData={setData}/>
        else return <>Žádná data</>
    }

    return (
        <>
            <UserArealSelect/>
            <Header data={[ {text: "Zóny", isMain: true}]}
                    rightContent={ <Button text={"Přidat zónu"} link={"/zone/add"} loading={loading}/>
                    }>
            {user.activeAreal && _renderBody()}
            </Header>
        </>
    )
}
export default Zone;
