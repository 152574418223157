import React from "react";
import Widget from "../widget";


const ErrorMessage = ({text, title, color}) => {

    return <Widget
        title={title}
        // description={"Nejde to "}
    >
        <h3>{text}</h3>
    </Widget>
}

export default ErrorMessage