import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import Datatable from "../../../components/datatable";
import Toast from "cogo-toast";
import {apiDeleteToken} from "./Actions";
import {FiCopy, FiDelete, FiEdit} from "react-icons/fi";
import {useSelector} from "react-redux";

const AccessTokenTable = ({data, setData}) => {

    let tableData = React.useMemo(() => {
        return data;
    }, [data])
    let user = useSelector((state) => state.user);

    const deleteItem = (id) => {
        apiDeleteToken(user.activeAreal.id,id, (response) => {
            let array = [...data];
            array = array.filter((val) => val.id !== id);
            setData(array);
            Toast.success("Token byl odstraněn");
        }, (error) => {
            let errorMessage = "Nepodařilo se odstranit token.\n"
            switch (error.data) {
                case "TOKEN_NOT_FOUND":
                    errorMessage += "Nebyl nalezen.";
                    break;
                default:
                    errorMessage += "Neznámá chyba";
                    break;
            }
            Toast.error(errorMessage);
        })

    }


    let columns = React.useMemo(() => [
        {Header: 'ID', accessor: 'id'},
        {Header: 'Název', accessor: 'name'},
        {
            Header: 'Token', accessor: d => d.token ? <div className={"flex flex-row"}>{d.token}{' '}<div className={"ml-2 mt-1"} onClick={() => {
                navigator.clipboard.writeText(d.token);
                Toast.info("Token byl zkopírován do schránky")
            }}><FiCopy style={{cursor:"pointer"}}/></div></div> : "-"
        },
        {Header: 'Datum od', accessor: d => d.dateFrom ? d.dateFrom : "-"},
        {Header: 'Datum do', accessor: d => d.dateTo ? d.dateTo : "-"},
        {Header: 'Právo', accessor: d=>{
            switch (d.competency){
                case "full": return "Plná práva"
                case "external": return "Externí přístup"
                case "reader": return "Čtečka"
                default: return "";
            }
            }},
        {Header: 'Poslední akce', accessor: d => d.lastAction ? d.lastAction : "-"},
        {
            Header: "Možnosti", accessor: d => (
                <div className={"flex flex-row"}>
                    <Link to={"/access/token/detail/" + d.id}>
                        <button
                            className={"btn btn-default rounded btn-icon bg-blue-500 hover:bg-blue-400 space-x-2 mt-1 px-5 text-white "}>
                            <FiEdit size={20}/>
                        </button>
                    </Link>
                    <button onClick={()=>deleteItem(d.id)}
                        className={"btn btn-default rounded btn-icon bg-blue-500 hover:bg-blue-400 space-x-2 mt-1 px-5 text-white ml-2"}>
                        <FiDelete size={20}/>
                    </button>
                </div>
            )
        }
    ])

    return <Datatable data={tableData} columns={columns} selectable={false} />
}
export default AccessTokenTable;
