import {instance, onErrorFunction, onSuccessFunction} from "../../../config/axios-cnf";

const url = "/api/v1/tariff"

export const apiGetTariff = (subjectId, id = null, callback, error) => {
    if (id == null) {
        instance.get("/api/v2/enigoo/" + subjectId + "/tariff")
            .then((response) => callback(onSuccessFunction(response.data)))
            .catch((err) => error(onErrorFunction(err)));
    } else {
        instance.get("/api/v2/enigoo/" + subjectId + "/tariff/" + id)
            .then((response) => callback(onSuccessFunction(response.data)))
            .catch((err) => error(onErrorFunction(err)));
    }
}

export const apiPostTariff = (subjectId, data, callback, error) => {
    instance.post("/api/v2/enigoo/" + subjectId + "/tariff", data)
        .then((response) => callback(onSuccessFunction(response.data)))
        .catch((err) => error(onErrorFunction(err)));
}


export const apiDeleteTariff = (subjectId, id, callback, error) => {
    instance.delete("/api/v2/enigoo/" + subjectId + "/tariff/" + id)
        .then((response) => callback(onSuccessFunction(response.data)))
        .catch((err) => error(onErrorFunction(err)));
}

