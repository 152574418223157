import React, {useEffect, useState} from "react"
import Modal from "../../components/My/Modal";
import {Button} from "../../components/My/Button";
import {Form} from "react-final-form";
import {apiImportExternalTickets} from "./Actions";
import Toast from "cogo-toast";
import FileField from "./FileField";


const ArenaExternTicketsModal = ({visible, close, accept, event}) => {

    let submit
    let [loading, setLoading] = useState(false)
    let [uploadedFile, setUploadedFile] = useState(null)

    useEffect(() => {
        if (!visible){
            setUploadedFile(null)
        }
    }, [visible]);

    if (!visible) return null

    const submitForm = (values) => {
        setLoading(true)
        values.file = uploadedFile

        let formData = new FormData();
        formData.append("files", uploadedFile)
        formData.append("eventId", event.id)

        apiImportExternalTickets((formData), (data) => {
            setLoading(false)
            Toast.success("Úspěšně přidáno.")
            setUploadedFile(null)
            close(true)
        }, error => {
            switch (error.error.type) {
                case "EVENT_NOT_EXIST":
                    Toast.error(" Událost nebyla nalezena.")
                    close(true)
                    break;
                    case "TICKETS_ALREADY_EXIST":
                    Toast.error(" Nahrávané lístky už existují.")
                    break;
                default:
                    Toast.error(" Nastala neočekávaná chyba.")
                    close(true)
            }
            setUploadedFile(null)
            setLoading(false)
        })

    }

    const validate = (values) => {
        let errors = {};

        if (!uploadedFile) {
            errors.file = "File is required!";
        }

        return errors;
    }

    return (
        <>
            <Modal title={"Upozornění"}
                   body={
                       <Form
                           validate={validate}
                           onSubmit={submitForm}
                           render={({handleSubmit, errors, values, touched, submitting}) => {
                               submit = handleSubmit
                               return (
                                   <>
                                       <div className={"flex justify-center"}>
                                           <h4>Import nových externích kódů</h4>
                                       </div>
                                       <div className={"w-full"}>
                                           <FileField name={"file"}
                                                      onChange={setUploadedFile}/>
                                           {errors.file && submitting &&
                                               <div className={"text-red-500 mt-2"}>{errors.file}</div>}
                                       </div>
                                   </>
                               )
                           }}/>
                   }
                   visible={visible}
                   close={() => close()}
                   footer={<>
                       <Button text={"Potvrdit"} onClick={() => uploadedFile && submit()} loading={loading}/>
                       <Button text={"Zrušit"} onClick={() => close()}/>
                   </>
                   }
            />
        </>
    )
}

export default ArenaExternTicketsModal
