import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Form} from "react-final-form";
import {apiGetDevice, apiPostDevice, apiPutDevice} from "./Actions";
import Toast from "cogo-toast";
import {DefaultLoader} from "../../../components/DefaultLoader";
import {Button} from "../../../components/My/Button";
import InputField from "../../../FinalForm/InputField";
import Widget from "../../../components/widget";
import {IconTabs} from "../../../components/tabs";
import SelectField from "../../../FinalForm/SelectField";
import ApiTariffSelectField from "../../../FinalForm/api/ApiTariffSelectField";
import {useSelector} from "react-redux";
import {FiInfo, FiSave} from "react-icons/fi";
import {FieldArray} from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import ModalSimple from "../../../components/modals/modal-simple";

const DeviceForm = () => {


    let {id} = useParams();


    const displayTypes = [
        {label: "ENTRY_SUCCESS", value: "ENTRY_SUCCESS"},
        {label: "EXIT_SUCCESS", value: "EXIT_SUCCESS"},
        {label: "IN_ZONE", value: "IN_ZONE"},
        {label: "NOT_IN_ZONE", value: "NOT_IN_ZONE"},
        {label: "CARD_USED", value: "CARD_USED"},
        {label: "NOT_FOUND", value: "NOT_FOUND"},
        {label: "INCORRECT_ZONE", value: "INCORRECT_ZONE"},
    ]

    let [loading, setLoading] = useState(false);
    let [saving, setSaving] = useState(false);
    let [data, setData] = useState(null);
    let [item, setItem] = useState(null)
    let [modalRule, setModalRule] = useState(false)

    let user = useSelector((state) => state.user)


    const navigate = useNavigate();

    const deviceTypes = [
        {label: "Brána", value: -1},
        {label: "Displej", value: 1}
    ];


    useEffect(() => {
        user.activeAreal && getData();
    }, [user.activeAreal]);


    const getData = () => {
        if (id) {
            setLoading(true);
            apiGetDevice(user.activeAreal.id, id, (data) => {
                setData({
                    ...data,
                    type: data.type === 0 ? -1 : data.type,
                    doDefault: data.doDefault === 0 ? -1 : data.doDefault,
                    active: data.active === 0 ? -1 : data.active
                });
                setLoading(false);
            }, (err) => {
                setLoading(false);
                handleError(err);
                navigate("/device");
            })
        }
    }

    const handleError = (err) => {
        let error = "Nepodařilo se načíst data o zařízení.\n";
        switch (err.data) {
            case "DEVICE_NOT_FOUND":
                error += "Zařízení nebylo nalezeno.";
                break;
            case "UNKNOWN_ERROR":
            default:
                if (error.startsWith("TARIFF_")) {
                    error += "Neznámý tarif";
                } else {
                    error += "Neznámá chyba.";
                }
        }
        Toast.error(error);
    }

    const onSubmit = (values) => {
        setSaving(true);
        if (id) {
            apiPutDevice(user.activeAreal.id,id, {
                ...values,
                type: values.type === -1 ? 0 : values.type,
                doDefault: values.doDefault === -1 ? 0 : 1,
                active: values.active === -1 ? 0 : 1
            }, (data) => {
                setData({
                    ...data,
                    type: data.type === 0 ? -1 : data.type,
                    doDefault: data.doDefault === 0 ? -1 : data.doDefault,
                    active: data.active === 0 ? -1 : data.active
                });
                setSaving(false);
                Toast.success("Úspěšně uloženo.");
            }, (err) => {
                setSaving(false);
                handleError(err);
            })
        } else {
            apiPostDevice(user.activeAreal.id,{...values, type: values.type === -1 ? 0 : 1}, (data) => {
                setData({
                    ...data,
                    type: data.type === 0 ? -1 : data.type,
                    doDefault: data.doDefault === 0 ? -1 : data.doDefault,
                    active: data.active === 0 ? -1 : data.active
                });
                setSaving(false);
                navigate("/device/detail/" + data.id);
                Toast.success("Úspěšně uloženo.");
            }, (err) => {
                setSaving(false);
                handleError(err);
            })
        }
    }

    const validate = (values) => {
        const errors = {}
        const rules = values.rules || {};
        errors.rules = {}

        if (values?.rules){
            const ticketAction = values.rules?.ticketAction ?? []
            errors.rules.ticketAction = []
            ticketAction.forEach((ticketActionItem, index)=> {
              const type = ticketActionItem?.type ?? {}
                errors.rules.ticketAction[index] = {}
                if (parseInt(type)<0){
                    errors.rules.ticketAction[0].type = "Vložte nezáporné číslo"
                    }
            })
        }
        return errors
    }

    const _renderBody = (values, form) => {
        if (loading) return <DefaultLoader text={"Načítám data turniketu..."} />;
        else return <Widget ><IconTabs tabs={[{
            index: 0,
            title: (<><FiInfo size={18} className={"stroke-current"} /><span
                className={"ml-2"} >Základní informace</span > </>),
            content: <>
                <InputField label={"Název"} name={"name"} isMandatory={true}
                            inputType={"text"} />
                <InputField label={"IP adresa"} name={"ipAddress"}
                            isMandatory={true} inputType={"text"} />
                <SelectField name={"type"} options={deviceTypes}
                             label={"Typ zařízení"} isMandatory={true} />

                {values.type === 1 ? <div >
                    <div className={"flex flex-row ml-5"} >
                        <InputField name={`rules.basicImageUrl`}
                                    isMandatory={true}
                                    inputType={"text"}
                                    label={"Defaultní url obrázku"} />
                    </div >
                    <div className={"ml-5 mb-2 flex"} >
                        <div className={"flex flex-col w-1/2 mx-1"} >
                            <Button text={"Přidat základní pravidlo zařízení"}
                                    onClick={() => form.mutators.push('rules.basicAction', undefined)} />
                            <FieldArray name="rules.basicAction" >
                                {({fields}) =>
                                    fields.map((name, index) => (
                                        <div key={"rule_row" + index} className={"flex flex-row justify-between rounded-lg border-2"} >
                                            <div className={"flex flex-col mt-1 ml-2 w-3/4"} >
                                                <span>#{index}</span>
                                                <SelectField name={`${name}.result`}
                                                             options={displayTypes}
                                                             label={`Akce`}
                                                             isMandatory={true} />
                                                <InputField name={`${name}.imageUrl`}
                                                            isMandatory={true}
                                                            placeholder={"url obrázku"}
                                                            inputType={"text"}
                                                            label={"Adresa obrázku"} />
                                            </div >
                                            <div className={"flex items-center justify-center w-1/6 mr-3"} >
                                                <Button text={`Odstranit řádek s indexem ${index}`}
                                                        color={"bg-red-500"} textSize={"sm"}
                                                        type={"button"}
                                                        extraStyle={"h-20"}
                                                        onClick={() => {
                                                            setModalRule(true)
                                                            setItem({fields, index})
                                                        }}
                                                />
                                            </div >
                                        </div >
                                    ))
                                }
                            </FieldArray >
                        </div >
                        <div className={"flex flex-col w-1/2 mx-1"} >
                            <Button text={"Přidat vstupenkové pravidlo zařízení"}
                                    onClick={() => form.mutators.push('rules.ticketAction', undefined)} />
                            <FieldArray name="rules.ticketAction" >
                                {({fields, meta}) =>
                                    fields.map((name, index) => (
                                        <div key={"rule_row" + index} className={"flex flex-row justify-between rounded-lg border-2 "} >
                                            <div className={"flex flex-col mt-1 w-3/4 ml-2 "} >
                                                <span>#{index}</span>
                                                <InputField inputType={"number"}
                                                            name={`${name}.type`}
                                                            isMandatory={true}
                                                            label={`Typ vstupenky`} />
                                                {/*{meta?.error[index]?.type ?? <div className={"text-red-500"}>meta.error[index].type</div>}*/}
                                                <SelectField name={`${name}.action`}
                                                             options={[{value: "light", label: "Rozsvícení 5s"},
                                                                 {value: "flash", label: "Bliknutí 4x"}]}
                                                             label={"Akce"}
                                                             isMandatory={true} />
                                                <InputField name={`${name}.imageUrl`}
                                                            isMandatory={true}
                                                            placeholder={"url obrázku"}
                                                            inputType={"text"}
                                                            label={"Adresa obrázku"} />
                                            </div >
                                            <div className={"flex items-center w-1/6 mr-5 "} >
                                                <Button text={`Odstranit řádek s indexem ${index}`}
                                                        color={"bg-red-500"} textSize={"sm"}
                                                        type={"button"}
                                                        extraStyle={"h-20"}
                                                        onClick={() => {
                                                            setModalRule(true)
                                                            setItem({fields, index})
                                                        }}
                                                />
                                            </div >
                                        </div >
                                    ))
                                }
                            </FieldArray >
                        </div >
                    </div >
                </div > : values.rules = null}
                <SelectField name={"doDefault"}
                             options={[{label: "ANO", value: 1}, {label: "NE", value: -1}]}
                             label={"Vykonat i výchozí akci"} isMandatory={true} />
                <SelectField name={"active"}
                             options={[{label: "ANO", value: 1}, {label: "NE", value: -1}]} label={"Aktivní"}
                             isMandatory={true} />
                <InputField label={"Port"} name={"port"} isMandatory={false}
                            inputType={"number"} />
                <ApiTariffSelectField />
            </>

        }]} />
        </Widget >
    }

    return (
        <div className={"w-full flex justify-center bg-grey-50"} >
            <Form onSubmit={onSubmit}
                  initialValues={data}
                  mutators={{...arrayMutators}}
                  validate={validate}
                  render={({handleSubmit, values, form, errors, ...props}) => {
                      return (
                          <div className={"flex flex-col w-full items-center"} >
                              <Widget >
                                  <div className={"flex flex-row text-black"} >
                                      <h5 className={"align-middle"} >Nové zařízení</h5 >
                                      <span className="ml-auto" ></span >
                                      <div className={"flex"} >
                                          <Button text={"Uložit"} onClick={() => handleSubmit()} icon={<FiSave />}
                                                  loading={saving} />
                                      </div >
                                  </div >
                              </Widget >
                              {_renderBody(values, form)}
                          </div >
                          )
                      }} />

            <ModalSimple
                body={"Opravdu chete smazat?"}
                title={"Vymazat pravidlo pro zařízení"}
                visible={modalRule}
                accept={() => {
                    item.fields.remove(item.index)
                    setModalRule(false)
                    setItem(null)
                }}
                close={() => {
                    setItem(null)
                    setModalRule(false)
                }} />

        </div >
    )
}
export default DeviceForm;
