import React from "react";
import {Field} from "react-final-form";
import PropTypes from "prop-types";
import Select from "react-select";


const SelectMultiField = (props) => {


    const getParse = (value) => {
        return value && value.map(item => item.value);
    };
    const parseInitValue = (values) => {
        if (!values) return [];
        console.log(values)
        let array = [];

        values.forEach(value => {
            props.options.forEach(optValue => {
                if (optValue.value === value) {
                    array.push(optValue);
                }
            })
        })
        return array;
    }

    const errorMessage = (message) => {
        return (
            <span style={{color: "red", marginRight: "10px"}} className={"text-sm"}>
                <i className="fa fa-arrow-up"> </i> {message}
            </span>
        );
    };

    const renderError = (_props) => {
        if (_props.meta.error && _props.meta.touched) {
            if (!_props.input.value || _props.input.value === "") return errorMessage("Povinné pole");
        }
        return null;
    };

    return (
        <Field
            {...props}
            name={props.name}

            validate={(value) => {
                if (props.isMandatory) {
                    if (!value) return true;
                }
                return false;
            }}
            render={(_props) => (
                <div className={"w-full flex-col"} data-test={props.dataTest}>
                    <div className={"form-label font-bold pb-1 pt-1"}>
                        {props.label!==""&&props.label + ": "}
                        {props.label!==""&&props.isMandatory ? <sup className={"text-legendyColor"}>*</sup> : ""}
                    </div>
                    <Select
                        {...props}
                        menuPortalTarget={document.body}
                        classNamePrefix={"form-input"}
                        styles={{
                            menu: (base) => ({...base, zIndex: 9999}),
                            menuPortal: (base) => ({...base, zIndex: 9999}),
                        }}
                        isMulti={true}
                        placeholder={props.placeholder ? props.placeholder : "Vyberte..."}
                        onChange={(value) => {
                            _props.input.onChange(value)
                            if(props.onChange){
                                props.onChange(value);
                            }
                        }}
                        options={props.options}
                        value={_props.input.value? parseInitValue(_props.input.value) :null}
                        isDisabled={props.disabled}
                        isClearable
                        // data-test={props.dataTest}
                    />
                    {renderError(_props)}
                </div>
            )}
            parse={getParse}
        />
    );
};

SelectMultiField.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    label: PropTypes.string.isRequired,
    isMandatory: PropTypes.bool,
    isMulti: PropTypes.bool,
    placeholder: PropTypes.string,
    validate: PropTypes.func,
    value: PropTypes.object,
    dataTest: PropTypes.string,
    defaultValue: PropTypes.array
};
SelectMultiField.defaultProps = {
    isMandatory: false,
    isMulti: false,
    defaultValue: ["id", "name"]
};

export default SelectMultiField;
