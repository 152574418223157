import React, {useEffect, useState} from "react"
import Modal from "../../../components/My/Modal";
import {Button} from "../../../components/My/Button";



const ApproveModal = ({visible, close, accept, text}) => {

    if (!visible) return null

    return (
        <>
            <Modal title={"Upozornění"}
                   body={<div>{text}</div>}
                   visible={visible}
                   close={() => close()}
                   footer={<>
                       <Button text={"Potvrdit"} onClick={() => accept()}/>
                       <Button text={"Zrušit"} onClick={() => close()}/>
                   </>
                   }
            />
        </>
    )
}

export default ApproveModal
