import {instance, onErrorFunction, onSuccessFunction} from "../../../config/axios-cnf";

const url = "/api/v1/token"

export const apiGetToken = (subjectId, id = null, callback, error) => {
    instance.get("/api/v2/enigoo/" + subjectId + "/token" + (id ? ("/" + id) : ""))
        .then((response) => callback(onSuccessFunction(response.data)))
        .catch((err) => error(onErrorFunction(err)));
}

export const apiPostGenerateToken = (subjectId, data, callback, error) => {
    instance.post("/api/v2/enigoo/" + subjectId + "/token/generate", data)
        .then((response) => callback(onSuccessFunction(response.data)))
        .catch((err) => error(onErrorFunction(err)));
}

export const apiPutUpdateToken = (subjectId, id, data, callback, error) => {
    instance.put("/api/v2/enigoo/" + subjectId + "/token/" + id, data)
        .then((response) => callback(onSuccessFunction(response.data)))
        .catch((err) => error(onErrorFunction(err)));
}

export const apiDeleteToken = (subjectId,id, callback, error) => {
    instance.delete("/api/v2/enigoo/"+subjectId+"/token/"+id)
        .then((response) => callback(onSuccessFunction(response.data)))
        .catch((err) => error(onErrorFunction(err)));
}

