import React, {useEffect, useState} from "react";
import SelectField from "../SelectField";
import SelectMultiField from "../SelectMultiField";
import {useSelector} from "react-redux";
import {apiGetZone} from "../../pages/ENIGOOTurnstiles/Zone/Actions";

const ApiZoneSelectField = ({disabled = false, isMulti = true,onChange=null,isMandatory=false,onEnter=null, name="zone",label="Zóna"}) => {

    let [data, setData] = useState([]);
    let [loading, setLoading] = useState(false);
    let user = useSelector((state) => state.user)


    useEffect(() => {
        user.activeAreal && getData();
    }, [user.activeAreal])

    const getData = () => {
        setLoading(true);
        apiGetZone(user.activeAreal.id, null, (data) => {
            setData(data);
            setLoading(false);
        }, (error) => {
            setData([]);
            setLoading(false);
        })
    }

    const getOptions = () => {
        return data?.map((item, index) => {
            return {label: item.name, value: item.id}
        })
    }
    if (isMulti) {
        return (
            <SelectField onEnter={onEnter} isMandatory={isMandatory} disabled={disabled}
                              placeholder={loading ? "Načítám data.." : "Zóny"} name={name}
                              options={loading ? [] : getOptions()} label={label} isMulti={true} onChange={onChange}/>
        )
    }else{
        return (
            <SelectField onEnter={onEnter} isMandatory={isMandatory} name={name} options={loading? [] : getOptions()}
                         label={label} isMulti={false} onChange={onChange}/>
        )
    }
}

export default ApiZoneSelectField;
