import React from 'react';
import PropTypes from "prop-types";
import SelectField from "./SelectField";


const DaySelectField = ({name = "days", isMulti = false, withoutDays = [],onEnter,isMandatory=false,disabled=false,label="Dny",onChange}) => {
    const days = [
        {value: 1, label: "Pondělí"},
        {value: 2, label: "Úterý",},
        {value: 3, label: "Středa"},
        {value: 4, label: "Čtvrtek"},
        {value: 5, label: "Pátek"},
        {value: 6, label: "Sobota"},
        {value: 7, label: "Neděle"},
    ];

    const getOptions = ()=>{
        return days.map((item)=> {
            if(!withoutDays.includes(item.value)){
                return item;
            }
        })
    }

    if (isMulti) {
        return (
            <SelectField onEnter={onEnter} isMandatory={isMandatory} disabled={disabled}
                         placeholder={"Dny"} name={name}
                         options={getOptions()} label={label} isMulti={true} onChange={onChange}/>
        )
    }else{
        return (
            <SelectField onEnter={onEnter} isMandatory={isMandatory} name={name} options={getOptions()}
                         label={label} isMulti={false} onChange={onChange}/>
        )
    }



}
DaySelectField.propTypes = {
    name: PropTypes.string,
    isMulti: PropTypes.bool,
    withoutDays: PropTypes.array,
    onEnter: PropTypes.func,
    isMandatory: PropTypes.bool,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    onChange: PropTypes.func

}
export default DaySelectField;
