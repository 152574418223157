import {instance, onErrorFunction, onSuccessFunction} from "../../../config/axios-cnf";

export const apiGetZone = (subjectId, id = null, callback, error) => {
    if (id == null) {
        instance.get("/api/v2/enigoo/" + subjectId + "/zone")
            .then((response) => callback(onSuccessFunction(response.data)))
            .catch((err) => error(onErrorFunction(err)));
    } else {
        instance.get("/api/v2/enigoo/" + subjectId + "/zone/"+id)
            .then((response) => callback(onSuccessFunction(response.data)))
            .catch((err) => error(onErrorFunction(err)));
    }
}

export const apiPostZone = (subjectId,id=null, data, callback, error) => {
    instance.post("/api/v2/enigoo/" + subjectId + "/zone"+(id?("/"+id):""), data)
        .then((response) => callback(onSuccessFunction(response.data)))
        .catch((err) => error(onErrorFunction(err)));
}
