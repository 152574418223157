import React from 'react'
import {FiCalendar, FiCompass, FiList, FiUser, FiUsers,} from 'react-icons/fi'
import {AiOutlineLock} from "react-icons/ai";
import {HiOutlineTicket} from "react-icons/hi";
import {BiBarcodeReader, BiDevices} from "react-icons/bi";
import {BsFillFileRuledFill, BsPostageHeartFill} from "react-icons/bs";
import {GiTurnstile} from "react-icons/gi";
import {MdAttachMoney, MdBarcodeReader, MdOutlineSubject} from "react-icons/md";
import {TbPlugConnected} from "react-icons/tb";
import {SiJsonwebtokens} from "react-icons/si";
import {GrValidate} from "react-icons/gr";
import {LuArrowLeftRight} from "react-icons/lu";
import {PiMapPinFill} from "react-icons/pi";
import {TfiStatsUp} from "react-icons/tfi";


export const SET_MENU = "SET_MENU";
export const CLEAR_MENU = "CLEAR_MENU";


export default function navigation(state = [], action) {
    switch (action.type) {
        case SET_MENU: {
            return getMenuItems(action.rights)
        }
        case CLEAR_MENU: {
            return []
        }
        default:
            return state
    }
}

const getMenuItems = (rights) => {
    const initialState = [
        {
            title: 'Možnosti',
            items: [{url: '/dashboard', icon: <FiCompass size={20}/>, title: 'Dashboard', items: []}]
        }
    ]
    if (rights?.events) {
        initialState[0].items.push({url: '/events', icon: <FiCalendar size={20}/>, title: 'Události', items: []})
    }
    if (rights?.validation) {
    initialState[0].items.push({
        url: '/validation', icon: <GrValidate size={20}/>, title: 'Validace vstupenky', items: [],
        key: "validation"
    })
    }

    if (rights?.arenaEvents) {
        initialState[0].items.push({
            url: '/arena-events', icon: <FiCalendar size={20}/>, title: 'Aréna události', items: [],
            key: "arenaEvents"
        })
        initialState[0].items.push({
            url: '/arena-entrances', icon: <LuArrowLeftRight size={20}/>, title: 'Aréna průchody', items: [],
            key: "arenaEntrances"
        })
    }
    if (rights?.users) {
        initialState[0].items.push({
            url: '/users', icon: <FiUser size={20}/>, title: 'Uživatelé', items: [],
            key: "users"
        })
    }

    if (rights?.kioskLog) {
        initialState[0].items.push({
            url: '/kiosk-log', icon: <FiUser size={20}/>, title: 'Logy z kiosků', items: [],
            key: "kioskLog"
        })
    }

    if (rights?.cron) {
        initialState[0].items.push({
            url: '/cron', icon: <FiUser size={20}/>, title: 'Cron', items: [],
            key: "cron"
        })
    }

    if (rights?.vcb) {
        initialState[0].items.push({
            url: '', icon: <BsPostageHeartFill size={20}/>, title: 'Výstaviště ČB', items: [
                {
                    url: '/vcb/actual', icon: <FiUser size={20}/>, title: 'Aktuální stav', items: [],
                    key: "vcb-actual"
                }
            ],
            key: "vcb"
        })
    }
    if (rights?.readers) {
        initialState[0].items.push({
            url: '/readers', icon: <MdBarcodeReader size={20}/>, title: 'Čtečky', items: [],
            key: "readers"
        })
    }
    if (rights?.subjects) {
        initialState[0].items.push({
            url: '/subjects', icon: <MdOutlineSubject size={20}/>, title: 'Subjekty', items: [],
            key: "subjects"
        })
    }
    if (rights?.turniketRule) {
        initialState[0].items.push({
            url: '/rules', icon: <BsFillFileRuledFill size={20}/>, title: 'Pravidla turniketů', items: [],
            key: "turniketRules"
        })
    }
    if (rights?.connections) {
        initialState[0].items.push({
            url: '/connections', icon: <TbPlugConnected size={20}/>, title: 'Připojení', items: [],
            key: "connections"
        })
    }

    if (rights?.enigooTurnikets) {
        initialState[0].items.push({
            url: '', icon: <GiTurnstile size={20}/>, title: 'ENIGOO turnikety', items: [
                {
                    url: '/turnstile', icon: <BiBarcodeReader size={20}/>, title: 'Turnikety', items: [],
                    key: "turnstile"
                },
                {
                    url: '/tariff', icon: <MdAttachMoney size={20}/>, title: 'Tarify', items: [],
                    key: "tariff"
                },
                {
                    url: '/device', icon: <BiDevices size={20}/>, title: 'Zařízení', items: [],
                    key: "device"
                },
                {
                    url: '', icon: <HiOutlineTicket size={20}/>, title: 'Vstupenky', items: [
                        {
                            url:'/ticket', icon:<FiList size={20}/>, title:'Nahrané',
                            key:'imported-ticket', items:[]
                        },
                        {
                            url:'/ticket/check', icon:<MdBarcodeReader size={20}/>, title:'Kontrola',
                            key:'check-ticket', items:[]
                        }
                    ],
                    key: "ticket"
                },
                {
                    url: '/entrance', icon: <LuArrowLeftRight size={20}/>, title: 'Průchody', items: [],
                    key: "entrance"
                },
                {
                    url: '/entrance-old', icon: <LuArrowLeftRight size={20}/>, title: 'Průchody-old', items: [],
                    key: "entrance"
                },
                {
                    url: '', icon: <PiMapPinFill size={20}/>, title: 'Zóny', items: [
                        {
                            url:'/zone',icon:<FiList size={20}/>, title: 'Seznam',items:[],key:'zone-list'
                        },
                        {
                            url:'/zone/stats',icon:<TfiStatsUp size={20}/>,title:"Statistiky",items:[],key:'zone-stats'
                        }
                    ],
                    key: "zone"
                },
                {
                    url: '/access/token', icon: <AiOutlineLock size={20}/>, title: 'Přístupové tokeny', items: [],
                    key: "access-token"
                }

            ],
            key: "enigooTurnikets"
        })
    }


    return initialState
}
