import {instance, onErrorFunction, onSuccessFunction} from "../../../config/axios-cnf";

const url = "/api/v1/device"

export const apiGetDevice = (subjectId, id = null, callback, error) => {
    if (id == null) {
        instance.get("/api/v2/enigoo/" + subjectId + "/device")
            .then((response) => callback(onSuccessFunction(response.data)))
            .catch((err) => error(onErrorFunction(err)));
    } else {
        instance.get("/api/v2/enigoo/" + subjectId + "/device/"+id)
            .then((response) => callback(onSuccessFunction(response.data)))
            .catch((err) => error(onErrorFunction(err)));
    }
}

export const apiDeleteDevice = (subjectId,id, callback, error) => {
    instance.delete("/api/v2/enigoo/" + subjectId + "/device/"+id)
        .then((response) => callback(onSuccessFunction(response.data)))
        .catch((err) => error(onErrorFunction(err)));
}

export const apiPostDevice = (subjectId,data, callback, error) => {
    instance.post("/api/v2/enigoo/"+subjectId + "/device", data)
        .then((response) => callback(onSuccessFunction(response.data)))
        .catch((err) => error(onErrorFunction(err)));
}

export const apiPutDevice = (subjectId,id, data, callback, error) => {
    instance.put("/api/v2/enigoo/"+subjectId + "/device/"+id, data)
        .then((response) => callback(onSuccessFunction(response.data)))
        .catch((err) => error(onErrorFunction(err)));
}

export const apiOpenTurniket = (subjectId,id, callback, error) => {
    instance.put("/api/v2/enigoo/"+subjectId + "/turnstile/open/" + id)
        .then((response) => callback(response.data))
        .catch((err) => error(onErrorFunction(err)));
}
