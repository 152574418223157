import React from "react";
import {Link} from "react-router-dom";


export const Button = ({
                           text,
                           onClick,
                           loading,
                           textSize,
                           type = "submit",
                           color = "bg-blue-500",
                           link = null,
                           icon,
                           extraStyle = "",
                           sizeButton = "btn-sm",
                            disabled = false
                       }) => {


    let button =
        <button
            className={"btn rounded btn-icon space-x-1 mt-1 text-white btn-rounded " + color + " " + extraStyle + " " + sizeButton}
            onClick={onClick} disabled={loading||disabled} type={type}>
            {icon}
            <span className={textSize ? textSize : "text-sm"}>{loading ? "Pracuji..." : text}</span>

        </button>;


    if (link) return <Link to={link}>{button}</Link>
    else return button;
}
