import React, {useEffect, useRef, useState} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {DefaultLoader} from "../../../components/DefaultLoader";
import {Button} from "../../../components/My/Button";
import {FiRefreshCcw} from "react-icons/fi";
import EntranceTable from "./EntranceTable";
import {apiPostEntrances} from "./Actions";
import Widget from "../../../components/widget";
import {Form} from "react-final-form";
import DateTimeField from "../../../FinalForm/DateTimeField";
import InputField from "../../../FinalForm/InputField";
import SelectField from "../../../FinalForm/SelectField";
import moment from "moment";
import LogMessageSelectField from "../../../FinalForm/api/LogMessageSelectField";
import UserArealSelect from "../Turnstiles/UserArealSelect";
import Toast from "cogo-toast";

/**
 * @deprecated Old implementation - use EntranceV2
 */
const Entrance = () => {
    let [data, setData] = useState([]);
    let [loading, setLoading] = useState(false);
    let user = useSelector((state) => state.user)

    let {filterEntrance} = useSelector(
        state => ({
            filterEntrance: state.filterEntrance,
        }),
        shallowEqual
    );

    useEffect(() => {
        if (user.activeAreal){
            if(!filterEntrance.filterEntranceData || !filterEntrance.filterEntranceData.dateFrom || !filterEntrance.filterEntranceData.dateTo){
                let filData = {dateFrom:getTodayDateFrom(), dateTo:getTodayDateTo(), bc:null,message:null};
                dispatch({
                    type: 'SET_FILTER_ENTRANCE',
                    payload: filData
                })

            }
        }
    }, [user.activeAreal])

    const getTodayDateFrom = ()=>{
        let date = new Date().toISOString();
        return date.slice(0, date.indexOf("T")) + "T00:00";
    }

    const getTodayDateTo = ()=>{
        let date = new Date().toISOString();
        return date.slice(0, date.indexOf("T")) + "T23:59";
    }

    const dispatch = useDispatch();
    let submit;


    const getData = (filterValues) => {

            if (filterValues.dateFrom && filterValues.dateTo) {
                setLoading(true);
                dispatch({
                    type: 'SET_FILTER_ENTRANCE',
                    payload: filterValues
                })
                apiPostEntrances(user.activeAreal.id, {
                    ...filterValues,
                    dateFrom: convertDate(filterValues.dateFrom),
                    dateTo: convertDate(filterValues.dateTo),
                    archive: filterValues.archive && filterValues.archive === 1 ? 1 : 0
                }, (data) => {
                    if (data == null) setData([]);
                    else setData(data);
                    setLoading(false);
                },(user?.activeAreal?.ip ? user.activeAreal.ip : null)
                ,(error) => {
                    Toast.error("Při načítání průchodů nastala chyba.");
                    setData([]);
                    setLoading(false);
                })
            }
        }

    const convertDate = (date) => {
        if (date) {
            let dateInString = date.replace('T', " ");
            dateInString += ":00";
            return dateInString;
        } else {
            return null;
        }
    }

    const onEnter = (e) => {

        if(e.key === `Enter`){
            submit();
        }
    }

    const renderFilterForm = () => (
        <Widget>
            <div className={"text-sm"}>
                <Form onSubmit={getData} initialValues={filterEntrance.filterEntranceData}
                      render={({values, handleSubmit}) => {
                          submit = handleSubmit;
                          return (
                              <div className={"grid grid-cols-2 lg:grid-cols-4 gap-2"}>
                                  <DateTimeField name={"dateFrom"} isMandatory={true} valueToCompare={values.dateTo}
                                                 validateFun={(date1, date2) => {
                                                     if (moment(date1).isAfter(moment(date2))) {
                                                         return "Datum od nemůže být po datu do"
                                                     } else {
                                                         return "";
                                                     }
                                                 }} label={"Datum od"} type={"from"} placeholder={"Datum od"}
                                                 showTimeSelect={true} onEnter={submit}/>
                                  <DateTimeField name={"dateTo"} isMandatory={true} valueToCompare={values.dateFrom}
                                                 validateFun={(date1, date2) => {
                                                     if (moment(date2).isAfter(moment(date1))) {
                                                         return "Datum od nemůže být po datu do"
                                                     } else {
                                                         return "";
                                                     }
                                                 }} label={"Datum do"} type={"to"} placeholder={"Datum do"}
                                                 showTimeSelect={true} onEnter={submit}/>
                                  <InputField label={"Událost id"} name={"eventId"} isMandatory={false}
                                              inputType={"number"} onEnter={submit}/>
                                  <InputField label={"BC"} name={"bc"} isMandatory={false} onEnter={submit}/>

                                  <SelectField name={"withLog"} options={[
                                      {label: "ANO", value: 1}, {label: "NE", value: -1}
                                  ]} label={"Včetně neúspěšných"} isMandatory={false} onEnter={submit}/>

                                  <SelectField name={"archive"} options={[
                                      {label: "ANO", value: 1}, {label: "NE", value: -1}
                                  ]} label={"Včetně archivovaných"} isMandatory={false} onEnter={submit}/>
                                  <LogMessageSelectField onEnter={submit}/>

                                  <div className={"lg:col-start-4 mt-6 mb-5 flex justify-end w-full"}><Button onClick={handleSubmit} loading={loading} text={"Aktualizovat"} icon={<FiRefreshCcw/>}/></div>
                              </div>
                          );

                      }}/>
            </div>
        </Widget>
    )

    const _renderBody = () => {
        if (loading) return <DefaultLoader text={"Načítám vstupenky..."}/>;
        if (!loading && data) return <EntranceTable data={data} setData={setData}/>
        else return <>Žádná data</>
    }

    return (
        <>
            <UserArealSelect loading={false}/>
            <Widget>
                <div className={"flex flex-row text-black"}>
                    <h5 className={"align-middle"}>Průchody</h5>
                    <span className="ml-auto"></span>
                </div>
            </Widget>
            {user.activeAreal && renderFilterForm()}
            {user.activeAreal && _renderBody()}
        </>
    )



}

export default Entrance;
