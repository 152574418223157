import {instance, onErrorFunction, onSuccessFunction} from "../../../config/axios-cnf";

const url = "/api/v1/ticket"

export const apiGetTicket = (subjectId, id, callback, error) => {
    instance.get("/api/v2/enigoo/" + subjectId + "/ticket/" + id)
        .then((response) => callback(onSuccessFunction(response.data)))
        .catch((err) => error(onErrorFunction(err)));
}
export const apiPostFilterTicket = (subjectId, data, callback, error) => {
    instance.post("/api/v2/enigoo/" + subjectId + "/ticket/filter", data)
        .then((response) => callback(onSuccessFunction(response.data)))
        .catch((err) => error(onErrorFunction(err)));
}

export const apiPostCreateTicket = (subjectId, data, callback, error) => {
    instance.post("/api/v2/enigoo/" + subjectId + "/ticket", data)
        .then((response) => callback(onSuccessFunction(response.data)))
        .catch((err) => error(onErrorFunction(err)));
}

export const apiPutUpdateTicket = (subjectId, id, data, callback, error) => {
    instance.put("/api/v2/enigoo/" + subjectId + "/ticket/" + id, data)
        .then((response) => callback(onSuccessFunction(response.data)))
        .catch((err) => error(onErrorFunction(err)));
}


export const apiPostImportTicket = (subjectId, data, callback, error) => {
    instance.post("/api/v2/enigoo/" + subjectId + "/ticket/import", data, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    })
        .then((response) => callback(onSuccessFunction(response.data)))
        .catch((err) => error(onErrorFunction(err)))
}


export const apiDeleteTicket = (subjectId, data, callback, error) => {
    instance.delete("/api/v2/enigoo/"+subjectId+"/ticket", {data: data})
        .then((response) => callback(onSuccessFunction(response.data)))
        .catch((err) => error(onErrorFunction(err)));
}





