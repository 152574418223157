import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {apiGetUser} from "../AccessUser/Actions";
import Toast from "cogo-toast";
import {DefaultLoader} from "../../../components/DefaultLoader";
import AccessUserTable from "../AccessUser/AccessUserTable";
import {Button} from "../../../components/My/Button";
import {FiPlus} from "react-icons/fi";
import {apiGetToken} from "./Actions";
import AccessTokenTable from "./AccessTokenTable";
import Widget from "../../../components/widget";
import UserArealSelect from "../Turnstiles/UserArealSelect";

const AccessToken = () =>{
    let [data, setData] = useState(null);
    let [loading, setLoading] = useState(false);
    let user = useSelector((state) => state.user)


    useEffect(() => {
        user.activeAreal && getData();
    }, [user.activeAreal])

    const getData = () => {
        setLoading(true);
        apiGetToken(user.activeAreal.id,null, (data) => {
            setData(data);
            setLoading(false);
        }, (error) => {
            Toast.error("Při načítání tokenů nastala chyba.");
            setLoading(false);
        })
    }

    const _renderBody = () => {
        if (loading) return <DefaultLoader text={"Načítám tokeny..."}/>;
        if (!loading && data) return <AccessTokenTable data={data} setData={setData}/>
        else return <>Žádná data</>
    }

    return (
        <>
            <UserArealSelect />

            <Widget>
                <div className={"flex flex-row text-black"}>
                    <h5 className={"align-middle"}>Tokeny</h5>
                    <span className="ml-auto"></span>
                    <div className={"flex"}>
                        {user.activeAreal && <Button text={"Přidat"} link={"/access/token/add"} icon={<FiPlus/>}/>}
                    </div>
                </div>
            </Widget>

            {user.activeAreal && _renderBody()}
        </>
    )
}
export default AccessToken;
