import React, {useEffect} from 'react'
import {useTable, usePagination, useRowSelect, useSortBy} from 'react-table'
import {PageWithText} from '../pagination'
import {
    FiChevronDown,
    FiChevronLeft,
    FiChevronRight,
    FiChevronsLeft,
    FiChevronsRight, FiChevronUp,
} from 'react-icons/fi'

const TableCheckBox = React.forwardRef(
    ({indeterminate, ...rest}, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <>
                <input className={"form-check"} type="checkbox" ref={resolvedRef} {...rest} />
            </>
        )
    }
)

const Datatable = ({
                       columns,
                       data,
                       selectable = false,
                       setSelectedRows = null,
                       onPageSelectChange = null,
                       pageSelectDefaultValue = null,
                       onPageChange = null,
                       defaultPage = null,
                       sortable = false,
                       showPagination = true
                   }) => {


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        selectedFlatRows,
        state: {pageIndex, pageSize},
    } = useTable({
        columns,
        data,
        initialState: {pageIndex: 0, hideOnSinglePage: true}
    }, useSortBy, usePagination, useRowSelect, hooks => {
        selectable &&
        hooks.visibleColumns.push(columns => [
            {
                id: 'selection',
                Header: ({getToggleAllRowsSelectedProps}) => (
                    <div>
                        <TableCheckBox {...getToggleAllRowsSelectedProps()}/>
                    </div>
                ),
                Cell: ({row}) => (
                    <div>
                        <TableCheckBox {...row.getToggleRowSelectedProps()}/>
                    </div>
                ),
                width: 2
            },
            ...columns
        ])
    });

    const selectedRows = React.useMemo(() => {
        const array = [];
        if (setSelectedRows) {
            selectedFlatRows.map(d => array.push(d.original.id))
            setSelectedRows(array);
        }
        return array;
    }, [selectedFlatRows]);

    useEffect(() => {
        if (pageSelectDefaultValue) {
            setPageSize(pageSelectDefaultValue);
        }
        if (defaultPage) {
            gotoPage(defaultPage);
        }
    }, [])

    return (
        <>
            <div className={"bg-white"}>
                <div className={"relative overflow-x-auto"}>
                    <table className={"table striped"} {...getTableProps()}>
                        <thead className={"table-header-group"}>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps)}>
                                        <div className={"flex flex-row"}> {column.render('Header')}
                                            {/* Add a sort direction indicator */}
                                            <span className={"text-black font-bold ml-2"}>
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? <FiChevronDown color={"black"} size={15}/>
                                                    : <FiChevronUp color={"black"} size={15}/>
                                                : ''}
                                        </span>
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                        </thead>
                        <tbody {...getTableBodyProps()} className={""}>
                        {data.length === 0 ? <tr>
                                <td colSpan={columns.length}>Žádná data k zobrazení</td>
                            </tr> :
                            page.map((row, i) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()} className={"table-row"}>
                                        {row.cells.map(cell => {
                                            return <td {...cell.getCellProps()}
                                                       className={"table-cell "}>{cell.render('Cell')}</td>
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            {showPagination &&
                <div className="flex flex-row items-center justify-between my-4 bg-white p-2 border rounded">
                    <div className="flex flex-wrap items-center justify-start space-x-2 pagination">
                        <PageWithText active={pageIndex !== 0} onClick={() => {
                            gotoPage(0);
                            if (onPageChange) onPageChange(0);
                        }}><FiChevronsLeft
                            size={20}/></PageWithText>
                        <PageWithText active={canPreviousPage} onClick={() => {
                            previousPage();
                            if (onPageChange) onPageChange(pageIndex - 1);
                        }}><FiChevronLeft
                            size={20}/></PageWithText>
                        <PageWithText active={canNextPage} onClick={() => {
                            nextPage()
                            if (onPageChange) onPageChange(pageIndex + 1);
                        }} disabled={!canNextPage}>
                            <FiChevronRight size={20}/>
                        </PageWithText>
                        <PageWithText active={!(pageIndex === pageCount - 1) && pageCount !== 0}
                                      onClick={() => {
                                          gotoPage(pageCount - 1)
                                          if (onPageChange) onPageChange(pageCount - 1);
                                      }}
                                      disabled={!(pageIndex === pageCount - 1)}>
                            <FiChevronsRight size={20}/>
                        </PageWithText>
                    </div>

                    <span>
          Stránka{' '}
                        <b>
            {pageIndex + 1} z {pageOptions.length}
          </b>{' '}

        </span>
                    <span><b>Celkem:</b>{' '} {data.length + " záznamů"}</span>

                    <select
                        className="form-select text-sm bg-white dark:bg-grey-800 dark:border-grey-800 outline-none shadow-none focus:shadow-none"
                        value={pageSize}
                        onChange={e => {
                            if (onPageSelectChange) onPageSelectChange(e.target.value);
                            setPageSize(Number(e.target.value))
                        }}>
                        {[10, 30, 50, 100].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                Zobrazit {pageSize}
                            </option>
                        ))}
                    </select>
                </div>
            }

        </>
    )
}

export default Datatable
