import React, {useEffect} from "react";
import Toast from "cogo-toast";
import Datatable from "../../../components/datatable";
import {apiDeleteTariff} from "./Actions";
import {Link} from "react-router-dom";
import {FiDelete, FiEdit} from "react-icons/fi";

const ZoneTable = ({data, setData}) => {

    let tableData = React.useMemo(() => {
        return data;
    }, [data])


    const deleteItem = (id) => {
        /*apiDeleteTariff(id, (response) => {
            let array = [...data];
            array = array.filter((val) => val.id != id);
            setData(array);
            Toast.success("Tarif byl odstraněna");
        }, (error) => {
            let errorMessage = "Nepodařilo se odstranit tarif.\n"
            switch (error.data) {
                case "TARIFF_NOT_FOUND":
                    errorMessage += "Nebyl nalezen.";
                    break;
                default:
                    errorMessage += "Neznámá chyba";
                    break;
            }
            Toast.error(errorMessage);
        })
*/
    }

    let columns = React.useMemo(() => [
        {Header: 'ID', accessor: 'id'},
        {Header: 'Název', accessor: 'name'},
        {Header: 'Nadřazená zóna', accessor: 'parentZone.name'},
        {Header: 'Kapacita', accessor: 'capacity'},
        {Header: 'Časový limit', accessor: 'timeLimit'},
        {Header: 'Tarify',
            accessor: d => <div
                className={"bg-blue-500 text-white rounded text-center font-bold"}> {d?.tariffs?.length} </div>
        },
        {
            Header: "Možnosti", accessor: d => (
                <div className={"flex flex-row"}>
                    <Link to={"/zone/detail/" + d.id}>
                        <button
                            className={"btn btn-default rounded btn-icon bg-blue-500 hover:bg-blue-400 space-x-2 mt-1 px-5 text-white "}>
                            <FiEdit size={20}/>
                        </button>
                    </Link>
                </div>
            )
        }
    ])

    return <Datatable data={tableData} columns={columns} selectable={false} />
}
export default ZoneTable;
