import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import Datatable from "../../../components/datatable";

/**
 *
 * @deprecated Old implementation
 */
const EntranceTable = ({data,setData})=>{

    let tableData = React.useMemo(() => {
        return data;
    }, [data])


    let columns = React.useMemo(() => [
        {Header: 'BC', accessor: 'bc'},
        {Header: 'Datum', accessor: 'date'},
        {Header: 'Událost id', accessor: 'eventId'},
        {Header: 'Turniket',accessor: d=>d.turnstile?d.turnstile.description + " ("+d.turnstile.ipAddress+")":"-"},
        {Header: 'Výsledek',accessor: d=>d.result==="OK"?<span className={"text-green-600 font-bold"}>{d.result}</span>:<span className={"text-red-600 font-bold"}>{d.result}</span>},
        {Header: 'Typ', accessor: d => d.typeEntrance === 1 ? "Vchod" : "Východ"}
    ])

    return <Datatable data={tableData} columns={columns} selectable={false} />
}
export default EntranceTable;
