import React, {useEffect, useState} from "react";
import {apiGetDevice} from "./Actions";
import {Button} from "../../../components/My/Button";
import {DefaultLoader} from "../../../components/DefaultLoader";
import {useSelector} from "react-redux";
import Toast from "cogo-toast";
import DeviceTable from "./DeviceTable";
import Widget from "../../../components/widget";
import UserArealSelect from "../Turnstiles/UserArealSelect";

const Device = () =>{

    let[data,setData] = useState(null);
    let[loading,setLoading] = useState(false);
    let user = useSelector((state) => state.user)


    useEffect(() => {
        user.activeAreal && getData()
    }, [user.activeAreal]);

    const getData = ()=>{
        setLoading(true);
        apiGetDevice(user.activeAreal.id, null,(data)=>{
            setData(data);
            setLoading(false);
        },(error)=>{
            Toast.error("Při načítání zařízení nastala chyba.");
            setData(null)
            setLoading(false);
        })
    }

    const _renderBody = () => {
        if(loading) return <DefaultLoader text={"Načítám zařízení..."}/>;
        if(!loading && data) return <DeviceTable data={data} setData={setData}/>
        else return <>Žádná data</>
    }

    return (
        <>
            <UserArealSelect />
            <Widget>
                <div className={"flex flex-row text-black"}>
                    <h5 className={"align-middle"}>Zařízení</h5>
                    <span className="ml-auto"></span>
                    <div className={"flex"}>
                        {user.activeAreal && <Button text={"Přidat zařízení"} link={"/device/add"}/>}
                    </div>
                </div>
            </Widget>
            {user.activeAreal && _renderBody()}
        </>
    )

}
export default Device;
