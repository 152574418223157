import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import Datatable from "../../../components/datatable";
import {FiBookOpen, FiCheckCircle, FiDelete, FiEdit, FiXCircle} from "react-icons/fi";
import {Button} from "../../../components/My/Button";
import Modal from "../../../components/My/Modal";
import {apiOpenTurniket} from "../Device/Actions";
import Toast from "cogo-toast";
import {useSelector} from "react-redux";

const TurnstileTable = ({data}) => {

    let [openTurnstileModal, setOpenTurnstileModal] = useState(null)

    let user = useSelector((state) => state.user)

    let tableData = React.useMemo(() => {
        return data.turnstile;
    }, [data])

    const openTurnstile = () => {
        apiOpenTurniket(user.activeAreal.id, openTurnstileModal, (response) => {
            switch (response.result) {
                case "TURNSTILE_WAS_OPEN":
                    Toast.success("Turniket byl otevřen.")
            }
            setOpenTurnstileModal(null)
        }, error => {
            console.log(error)
        })
    }

    let columns = React.useMemo(() => [
        {Header: '#ID', accessor: 'id'},
        {Header: 'IP adresa', accessor: 'ipAddress'},
        {
            Header: 'Typ',
            accessor: d => d.type && d.type === 1 ? <div className={"text-green-500 font-bold"}>Vchod</div> :
                <div className={"text-red-400 font-bold"}>Východ</div>
        },
        {Header: 'Popis', accessor: 'description'},
        {
            Header: 'Výrobce', accessor: d => {
                switch (d.turnstileType){
                    case 1:
                        return "RFID čtečka Papouch";
                    case 2:
                        return "ELVIS";
                    case 0:
                    default:
                        return "VGUANG IP";

                }
            }
        },
        {
            Header: 'Tarify',
            accessor: d => <div
                className={"bg-blue-500 text-white rounded text-center font-bold"}>{d?.tariff?.length}</div>
        },
        {
            Header: 'Sp. pravidla',
            accessor: d => <div
                className={"bg-blue-500 text-white rounded text-center font-bold"}>{d?.specialRules?.length}</div>
        },
        {
            Header: 'Zařízení', accessor: d => {
                let str = d.device.map((item, index) => {
                    return item.name + " ";
                });
                return str;
            }
        },
        {
            Header: 'Zóna', accessor: d => {
                if (d.zone?.name) {
                    return d.zone.name;
                } else {
                    return "";
                }
            }
        },
        {Header: 'Poslední akce', accessor: 'lastAction'},
        {
            Header: 'Připojen',
            accessor: d => d.connected ? <FiCheckCircle color={"green"} size={20}/> :
                <FiXCircle color={"red"} size={20}/>
        },
        {
            Header: "Možnosti", accessor: d => (
                <div className={"flex flex-row"}>
                    <Link to={"/turnstile/detail/" + d.id}>
                        <button
                            className={"btn btn-default rounded btn-icon bg-blue-500 hover:bg-blue-400 space-x-2 mt-1 px-5 text-white "}>
                            <FiEdit size={20}/>
                        </button>
                    </Link>
                    {/*<button title={"Otevřít turniket"}
                            className={"btn btn-default rounded btn-icon bg-blue-500 hover:bg-blue-400 space-x-2 mt-1 px-5 text-white ml-2 "}
                            onClick={() => {
                                setOpenTurnstileModal(d.id)
                            }}>
                        <FiBookOpen size={20}/>
                    </button>*/}
                </div>
            )
        }
    ])

    return (
        <>
            <Datatable data={tableData} columns={columns} selectable={false}/>
            <Modal title={"Otevřít turniket"}
                   body={<h3>Opravdu chcete otevřít turniket?</h3>}
                   visible={openTurnstileModal}
                   close={() => setOpenTurnstileModal(null)}
                   footer={<>
                       <Button text={"Otevřít"} onClick={() => openTurnstile()}/>
                       <Button text={"Zrušit"} onClick={() => setOpenTurnstileModal(null)}/>
                   </>
                   }
            />
        </>
    )
}

export default TurnstileTable;
