import React, {useState} from "react"

import Modal from "../../../components/My/Modal";
import {Field, Form} from "react-final-form";
import {Button} from "../../../components/My/Button";
import InputField from "../../../FinalForm/InputField";
import DropZone from "../../ArenaEvents/DropZone";
import DateTimeField from "../../../FinalForm/DateTimeField";
import moment from "moment/moment";
import ApiTariffSelectField from "../../../FinalForm/api/ApiTariffSelectField";
import {useSelector} from "react-redux";
import {apiPostImportTicket} from "./Actions";
import Toast from "cogo-toast";


const TicketFormModal = ({visible, close}) => {

   let [loading, setLoading] = useState(false)
   let [dropzoneFile, setDropzoneFile] = useState(null)
   let user = useSelector((state) => state.user)


   let submit

   const convertDate = (date) => {
      if (date) {
         let dateInString = date.replace('T', " ");
         dateInString += ":00";
         return dateInString;
      } else {
         return null;
      }
   }
   const save = (values) => {
      setLoading(true)
      values.dateTo = convertDate(values.dateTo)
      values.dateFrom = convertDate(values.dateFrom)

      let formData = new FormData();
      formData.append('files', dropzoneFile);


      apiPostImportTicket(user.activeAreal.id, (formData), (data) => {
         setLoading(false)
         close(true)
         Toast.success("Úspěšně nahráno.")
      }, error => {
         Toast.error(error.data)
         setLoading(false)
      })
   }

   const getBody = () => {
      return (
         <Form onSubmit={save}
               render={({handleSubmit, values}) => {
                  submit = handleSubmit
                  return (
                     <div >
                        {/*<InputField label={"Počet vstupů"} name={"entrance"} isMandatory={true} inputType={"number"}/>
                        <InputField label={"ID události"} name={"eventId"} isMandatory={true} inputType={"number"}/>
                        <DateTimeField name={"dateFrom"} isMandatory={true} valueToCompare={values.dateTo}
                                       validateFun={(date1, date2) => {
                                          if (moment(date1).isAfter(moment(date2))) {
                                             return "Datum od nemůže být po datu do"
                                          } else {
                                             return "";
                                          }
                                       }} label={"Datum od"} type={"from"} placeholder={"Datum od"}
                                       showTimeSelect={true}/>
                        <DateTimeField name={"dateTo"} isMandatory={true} valueToCompare={values.dateFrom}
                                       validateFun={(date1, date2) => {
                                          if (moment(date2).isAfter(moment(date1))) {
                                             return "Datum od nemůže být po datu do"
                                          } else {
                                             return "";
                                          }
                                       }} label={"Datum do"} type={"to"} placeholder={"Datum do"}
                                       showTimeSelect={true}/>
                        <InputField label={"Typ vstupenek"} name={"type"} isMandatory={false} inputType={"number"}/>
                        <ApiTariffSelectField name={"tariffId"} isMulti={false} isMandatory={true}/>*/}
                        <div className={"widget w-full p-4 mb-4 rounded-lg bg-blue-100 border border-grey-100"}>
                           <p><span className="font-bold">Soubor</span> - Nahrávejte soubor csv s barcody v prvním sloupci. Ostatní sloupce budou ignorovány.</p>
                        </div>
                        <DropZone name={"dropZone"} onFileUpload={acceptedFiles => setDropzoneFile(acceptedFiles)} height={"38"}/>

                     </div >
                  )
               }} />)

   }

   if (!visible) return null
   return (
      <>
         <Modal body={getBody()} title={`Import vstupenek pro ${user?.activeAreal?.name}`} visible={visible}
                close={() => close(null)}
                footer={<>
                   <Button outline={true} text={"Zavřít"} onClick={() => close()} />
                   <Button text={"Potvrdit"} onClick={() => submit()} loading={loading} />
                </>
         }
         />
      </>
   )

}

export default TicketFormModal
