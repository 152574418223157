import React, {useEffect, useState} from "react"
import Widget from "../../components/widget";
import {Form} from "react-final-form";
import arrayMutators from 'final-form-arrays'
import {Button} from "../../components/My/Button";
import {useNavigate, useParams} from "react-router-dom";
import Toast from "cogo-toast";
import DateField from "../../FinalForm/DateField";
import {apiCreateConnection, apiGetConnectionById, apiUpdateConnection} from "./Actions";
import InputField from "../../FinalForm/InputField";
import { parse } from "date-fns";
import {EnigooLoader} from "../../components/My/EnigooLoader";

const ConnectionsForm = () => {
    let [item, setItem] = useState(null)
    let [modalRule, setModalRule] = useState(false)
    let [loading, setLoading] = useState(false)
    let [data, setData] = useState(null)
    const {id} = useParams()
    let navigate = useNavigate()

    useEffect(() => {
        id && getConnections()
    }, [])

    const getConnections = () => {
        setLoading(true)
        apiGetConnectionById((id), (data) => {
            const connectionData = data[0]
            if (connectionData && (connectionData.expireToken !== null)){
                connectionData.expireToken = parse(connectionData.expireToken, 'dd.MM.yyyy', new Date())
            }
            setData(connectionData)
            setLoading(false)
        }, (err) => {
            switch (err.error.type) {
                case "CONNECTION_NOT_EXIST":
                    Toast.error("Připojení neexistuje.")
                    break;
                default:
                    Toast.error("Neočekávaná chyba.")
            }
            setLoading(false)
        })
    }

    const onSubmit = values => {
        setLoading(true)

        if (id){
            apiUpdateConnection((values), (data) => {
                setLoading(false)
                Toast.success("Dokončeno")
                navigate("/connections")
            }, (err) => {
                switch (err.error.type) {
                    case "CONNECTION_NOT_EXIST":
                        Toast.error("Připojení neexistuje.")
                        break;
                    default:
                        Toast.error("Nastala neočekávaná chyba.")
                }
                setLoading(false)
            })
        }else{
            apiCreateConnection((values), (data) => {
                setLoading(false)
                Toast.success("Dokončeno")
                navigate("/connections")
            }, (err) => {
                switch (err.error.type) {
                    default:
                        Toast.error("Nastala neočekávaná chyba.")
                }
                setLoading(false)
            })
        }

    }


    const validate = (values) => {
        const errors = {};
        //const rules = values.rules || [];


        if (!values.type) {
            errors.type = true
        }
        if (!values.ip) {
            errors.ip = true
        }
        if (!values.login) {
            errors.login = true
        }
        if (!values.pass) {
            errors.pass = true
        }
        if (!values.other) {
            errors.other = true
        }
        if (!values.note) {
            errors.note = true
        }

        return errors
    }

    if(loading) return <EnigooLoader/>

    return (
        <>
            <Widget>
                <div className={"flex flex-row text-black"}>
                    <h5 className={"align-middle"}>{data ? "Upravit připojení" : "Vytvořit připojení"}</h5>
                    <span className="ml-auto"></span>
                </div>
            </Widget>
            <Form onSubmit={onSubmit} mutators={{
                ...arrayMutators
            }}
                  validate={validate}
                  initialValues={data ? data : {}}
                  render={({handleSubmit, form, values, errors}) => {
                      return (
                          <>
                              <Widget>
                                  <div className={"flex flex-wrap"}>
                                      <div className={"w-full"}>
                                          <InputField label={"Název připojení"} name={"name"}
                                                      isMandatory={true}
                                                      inputType={"text"}/>
                                      </div>
                                      <div className={"w-full"}>
                                          <InputField label={"Key"} name={"key"}
                                                      isMandatory={false}
                                                      inputType={"text"}/>
                                      </div>
                                      <div className={"w-full"}>
                                          <InputField label={"Typ"} name={"type"}
                                                      isMandatory={true}
                                                      inputType={"text"}/>
                                      </div>
                                      <div className={"w-full"}>
                                          <InputField label={"IP adresa"} name={"ip"}
                                                      isMandatory={true}
                                                      inputType={"text"}/>
                                      </div>
                                      <div className={"w-full"}>
                                          <InputField label={"Uživatelské jméno"} name={"login"}
                                                      isMandatory={true}
                                                      inputType={"text"}/>
                                      </div>
                                      <div className={"w-full"}>
                                          <InputField label={"Heslo"} name={"pass"}
                                                      isMandatory={true}
                                                      inputType={"text"}/>
                                      </div>
                                      <div className={"w-full"}>
                                          <InputField label={"Ostatní"} name={"other"}
                                                      isMandatory={true}
                                                      inputType={"text"}/>
                                      </div>
                                      <div className={"w-full"}>
                                          <InputField label={"Poznámka"} name={"note"}
                                                      isMandatory={true}
                                                      inputType={"text"}/>
                                      </div>
                                      <div className={"w-full"}>
                                          <InputField label={"Token"} name={"token"}
                                                      isMandatory={false}
                                                      inputType={"text"}/>
                                      </div>
                                      <div className={"w-full"}>
                                          <DateField name={"expireToken"}
                                                     label={"Platnost tokenu"}
                                                     isMandatory={false}
                                          />
                                      </div>
                                  </div>

                              </Widget>

                              {Object.keys(errors).length !== 0 &&
                                  <Widget>
                                      {errors?.name && <h6 className={"text-red-400"}>Zadejte název připojení.</h6>}
                                      {errors?.type && <h6 className={"text-red-400"}>Zadejte typ připojení.</h6>}
                                      {errors?.ip && <h6 className={"text-red-400"}>Zadejte ip připojení.</h6>}
                                      {errors?.login && <h6 className={"text-red-400"}>Vytvořte přihlašovací jméno.</h6>}
                                      {errors?.pass && <h6 className={"text-red-400"}>Vytvořte heslo pro přihlášení.</h6>}
                                      {errors?.other && <h6 className={"text-red-400"}>Zadejte ostatní možnosti.</h6>}
                                      {errors?.note && <h6 className={"text-red-400"}>Přidejte poznámku.</h6>}
                                  </Widget>
                              }
                              <Button text={"Uložit"} onClick={handleSubmit} loading={loading}/>
                          </>
                      )
                  }}
            />
        </>
    )
}
export default ConnectionsForm
