import React, {useEffect, useState} from 'react';
import {apiGetTariff} from "../Tariff/Actions";
import {useSelector} from "react-redux";
import SelectField from "../../../FinalForm/SelectField";
import {FieldArray} from "react-final-form-arrays";
import InputField from "../../../FinalForm/InputField";
import DaySelectField from "../../../FinalForm/DaySelectField";
import {Button} from "../../../components/My/Button";
import {FiPlus} from "react-icons/fi";
import ZoneTariffPriceListItem from "./ZoneTariffPriceListItem";

const ZoneTariffPriceList = ({values, push}) => {
    let [tariffs, setTariffs] = useState([]);
    let [loading, setLoading] = useState(false);

    let tariffFields = null;

    let user = useSelector((state) => state.user)

    useEffect(() => {
        getTariffs();
    }, [])

    useEffect(() => {
        if (tariffFields && !loading) {
            handleSelectChange(values?.selectedTariff, tariffFields);
        }
    }, [values?.selectedTariff, tariffFields]);


    const getTariffs = () => {
        setLoading(true);
        apiGetTariff(user.activeAreal.id, null, (data) => {
            setTariffs(data);
            setLoading(false);
        }, (err) => {
            setTariffs([]);
            setLoading(false);
        })
    }

    const getOptions = () => {
        return tariffs.map((t) => {
            return {value: t.id, label: t.name}
        })
    }

    const getTariffName = (id) => {
        let tariff = tariffs.find((t) => t.id === id);
        if (tariff?.name) {
            return tariff.name;
        } else {
            return null;
        }
    }

    const handleSelectChange = (selectedValues, fields) => {


        // Remove any values from fields that are no longer in the selected options
        fields.forEach((field, index) => {
            const value = fields.value[index].tariffId;
            if (!selectedValues || !selectedValues.includes(value)) {
                fields.remove(index);
            }
        });

        // Add new values that are not yet in fields
        selectedValues && selectedValues.forEach(value => {
            if (!fields.value || !fields.value.find(field => field.tariffId === value)) {
                const ta = tariffs.find(tariff => tariff.id === value);
                fields.push({tariffId: ta.id});
            }
        });


    };


    const _renderTariffValue = (name, index, tFields) => {
        return <div className={"flex flex-col space-y-2 p-2 border border-grey-700 rounded"} key={`tv_${index}`}>
            <div className={"flex flex-row"}>
                <span className={"font-bold w-full"}>{getTariffName(tFields.value[index]?.tariffId)}</span>
            </div>

            <div className={"hidden"}><InputField label={""} name={`${name}.tariffId`}
                                                  isMandatory={true}/></div>
            <FieldArray name={`${name}.priceList`}>
                {({fields}) => {
                    return (
                        <div className={"flex flex-col space-y-2"}>
                            {fields.map((name, index) => (
                                <div key={`${name}.priceList_${name}_${index}`}>
                                    <ZoneTariffPriceListItem name={name} index={index} remove={fields.remove}
                                                             tariffName={getTariffName(tFields.value[index]?.tariffId)}/>
                                </div>
                            ))}
                            <Button icon={<FiPlus/>} onClick={() => {
                                fields.push({days: [], timeFrom: null, timeTo: null, price: null})
                            }}/>
                        </div>
                    )
                }}
            </FieldArray>
        </div>
    }
    if (loading) {
        return <></>
    }
    return (
        <div className={"flex flex-col space-y-2"}>
            <FieldArray name={"tariffs"}>
                {({fields}) => {
                    tariffFields = fields;
                    return <>
                        <SelectField isMandatory={false} name={"selectedTariff"}
                                     options={loading ? [] : getOptions()}
                                     label={"Vybrané tarify"} isMulti={true}/>

                        {fields.length > 0 && <>
                            <div className={"widget w-full p-4 mb-4 rounded-lg bg-blue-100 border border-grey-100"}>
                                <p><span className="font-bold">Ceníky</span> - zde si můžete definovat ceníky pro
                                    jednotlivé tarify, pokud tarif nemá definovaný ceník
                                    představuje to tarif, který má průchody zdarma.</p>
                            </div>
                            <span className={"font-bold"}>Ceníky:</span>

                        </>}

                        <div className={"grid grid-cols-2 gap-2"}>
                            {fields.map((name, index) => {
                                return _renderTariffValue(name, index, fields);
                            })}

                        </div>
                    </>
                }
                }
            </FieldArray>
        </div>
    )


}

ZoneTariffPriceList.propTypes = {}
export default ZoneTariffPriceList;
