import {instance, onErrorFunction} from "../../config/axios-cnf";


export const apiGetEvents = (subjectId, callback, error) => {
    instance.get("/api/v2/arena", {params: {subjectId}})
        .then(response => callback(response.data))
        .catch(err => error(err))
}

export const apiImportEventToTurnstile = (subjectId, eventId,  callback, error) => {
    instance.post("/api/v2/arena/import", {subjectId, eventId})
        .then(response => callback(response.data))
        .catch(err => error(err))
}

export const apiCreateEvent = (values, callback, error) => {
    instance.put(`/api/v2/arena`, values)
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}

export const apiImportExternalTickets = (formData, callback, error) => {
    instance.post(`/api/v2/arena`, formData,{headers:{"Content-Type": "multipart/form-data",}})
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}

export const apiGetUserSubjects = (callback, error) => {
    instance.get("/api/v2/user/subject")
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}
