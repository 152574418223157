import React from "react"
import Widget from "../../components/widget";
import Datatable from "../../components/datatable";
import Loader from "../../components/loader";

const TableData = ({data, setItem, loading}) => {


    if(loading) return <Loader/>
    if (!data) return null


    let columns = [
        {Header: 'Datum vstupu', accessor: "datetime"},
        {
            Header: 'Služba', accessor: d => (
               d.typeOperationName ? <div>{d.typeOperationName}</div> :<div>{`${d.communicationType} (${d.serviceId})`}</div>
            )
        },
        {Header: 'Turniket', accessor: "turnstile"},
    ];

    if (data[0]?.points){
        columns = [
            ...columns.slice(0, 2),
            {Header: 'Zbývá vstupů', accessor: "points"},
            ...columns.slice(2)
        ]
    }

    return (
        <Widget title={"Zaznamenané průchody"}>
            <Datatable data={data} columns={columns} showPagination={data.length>=10}/>
        </Widget>
    )
}

export default TableData
