import React, {useEffect, useState} from "react";
import SelectField from "../../../FinalForm/SelectField";
import {useDispatch, useSelector} from "react-redux";
import {Form} from "react-final-form";
import {Button} from "../../../components/My/Button";
import Widget from "../../../components/widget";
import {USER_SET_ACTIVE_AREAL} from "../../../reducers/user";
import {instanceENIGOOTurnikety} from "../../../config/axios-cnf";


const UserArealSelect = (props) => {

    let user = useSelector((state) => state.user)
    let dispatch = useDispatch();


    useEffect(() => {
        init()
    }, []);

    const init = () => {
        if (!user.activeAreal && user.user.areal.length > 0 || user.user.areal.length === 1) {
            dispatch({type: USER_SET_ACTIVE_AREAL, payload: user.user.areal[0]})
            instanceENIGOOTurnikety.defaults.headers.common["authorization"] = user.user.areal[0].token;
            instanceENIGOOTurnikety.defaults.baseURL = "http://" + user.user.areal[0].ip;
        } else if (user.activeAreal) {
            instanceENIGOOTurnikety.defaults.headers.common["authorization"] = user.activeAreal.token;
            instanceENIGOOTurnikety.defaults.baseURL = "http://" + user.activeAreal.ip;
        }


    }

    const getOptions = () => {
        let array = [];
        user.user.areal.map((areal) => {
            array.push({
                label: areal.name,
                value: areal.id
            })
        })
        return array
    }

    const submitForm = (e) => {
        const arealObject = user.user.areal.find(areal => areal.id === e.arealId)
        dispatch({type: USER_SET_ACTIVE_AREAL, payload: arealObject})

        instanceENIGOOTurnikety.defaults.headers.common["authorization"] = arealObject.token;
        instanceENIGOOTurnikety.defaults.baseURL = "http://" + arealObject.ip;
    }

    const getBody = () => {
        if(!user?.activeAreal) return <div></div>
        if (user.user.areal.length === 1) {
            return <div><h3>Areál: {user?.activeAreal.name}</h3></div>
        } else {
            return <Form
                onSubmit={submitForm}
                initialValues={user?.activeAreal ? {arealId: user.activeAreal.id} : {}}
                render={({handleSubmit}) => {
                    return (
                        <div className={"flex flex-wrap"}>
                            <div className={"lg:w-1/3 w-full"}>
                                <SelectField
                                    name={"arealId"}
                                    label={"Vyberte areál"}
                                    options={getOptions()}
                                    isMandatory={true}
                                    disabled={props.loading}
                                    isMulti={false}/>
                            </div>
                            <div className={"lg:w-1/4 w-full mt-6 lg:ml-6"}>
                                <Button text={"Potvrdit"} onClick={handleSubmit} loading={props.loading}/>
                            </div>
                        </div>
                    )
                }}/>
        }
    }

    return (
        <Widget>
            {getBody()}
        </Widget>
    )
}

export default UserArealSelect
