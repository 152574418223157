import React, {useEffect, useState} from "react";
import {apiGetTariff} from "../../pages/ENIGOOTurnstiles/Tariff/Actions";
import SelectField from "../SelectField";
import SelectMultiField from "../SelectMultiField";
import {useSelector} from "react-redux";
import {apiGetTurnstile} from "../../pages/ENIGOOTurnstiles/Turnstiles/Actions";

const ApiTurnstileSelectField = ({
                                  disabled = false,
                                  isMulti = true,
                                  onChange = null,
                                  isMandatory = false,
                                  onEnter = null,
                                  name = "turnstile"
                              }) => {

    let [data, setData] = useState([]);
    let [loading, setLoading] = useState(false);
    let user = useSelector((state) => state.user)


    useEffect(() => {
        user.activeAreal && getData();
    }, [user.activeAreal])

    const getData = () => {
        setLoading(true);
        apiGetTurnstile(user.activeAreal.id, null, (data) => {
            setData(data.turnstile?data.turnstile:[]);
            setLoading(false);
        }, (error) => {
            setData([]);
            setLoading(false);
        })
    }

    const getOptions = () => {
        return data?.map((item, index) => {
            return {label: item.description, value: item.id}
        })
    }
    if (isMulti) {
        return (
            <SelectField onEnter={onEnter} isMandatory={isMandatory} disabled={disabled}
                         placeholder={loading ? "Načítám data.." : "Turniket"} name={name}
                         options={loading ? [] : getOptions()} label={"Turnikety"} isMulti={true} onChange={onChange}/>
        )
    } else {
        return (
            <SelectField onEnter={onEnter} isMandatory={isMandatory} name={name} options={loading ? [] : getOptions()}
                         label={"Turniket"} isMulti={false} onChange={onChange}/>
        )
    }
}

export default ApiTurnstileSelectField;
