import React, {useEffect, useState} from "react"
import TableData from "./TableData";
import Widget from "../../components/widget";
import {apiGetSubjects} from "./Actions";
import {Button} from "../../components/My/Button";

const Subjects = () => {

    let [loading, setLoading] = useState(false)
    let [data, setData] = useState(null)


    useEffect(() => {
        getSubjects();
    }, [])

    const getSubjects = () => {
        setLoading(true)
        apiGetSubjects((data) => {
            setData(data)
            setLoading(false)
        }, (err) => {
            console.log(err)
            setLoading(false)
        })
    }


    return (
       <>
           <Widget >
               <div className={"flex flex-row text-black"} >
                   <h5 className={"align-middle"} >Subjekty</h5 >
                   <span className="ml-auto" ></span >
                   <div className={"flex"} >
                       <Button text={"Přidat subjekt"} link={"/subjects/add"} />
                   </div >
               </div>
           </Widget>
           <TableData data={data} loading={loading} />
       </>
    )
}

export default Subjects
