import {instance} from "../../config/axios-cnf";

export const apiGetActualStateVcb = (data, callback, error) => {
    instance.get("/api/v2/special/vcb-by-date", {
        params: data,
        responseType: data.type === 'JSON' ? 'json' : 'arraybuffer'
    }).then(response => callback(data.type === 'JSON' ? response.data : response))
        .catch(err => error(err))
}

export const apiGetSettingsVcb = (callback, error) => {
    instance.get("/api/v2/special/vcb-options")
        .then(response => callback(response.data))
        .catch(err => error(err))
}


export const apiSetSettingsVcb = (data, callback, error) => {
    instance.post("/api/v2/special/vcb-options", data)
        .then(response => callback(response.data))
        .catch(err => error(err))
}
