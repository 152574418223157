export const getEnigooTurnstileTicketTicketType = (ticketType)=>{
    if(!ticketType){
        return "";
    }
    switch (ticketType){
        case 0:
            return "Servisní karta";
        case 1:
            return "Běžná vstupenka";
        case 2:
            return "Permanentka s kreditem";
        default:
            return ""
    }
}

export const getEnigooTurnstileTicketType = (type)=>{
    if(!type) return "";
    switch (type){
        case 1:
            return "Dětská vstupenka";
        case 2:
            return "Zlevněná vstupenka";
        default:
            return "";
    }
}
