import React from "react"
import Widget from "../../components/widget";
import Datatable from "../../components/datatable";
import {Button} from "../../components/My/Button";
import {EnigooLoader} from "../../components/My/EnigooLoader";

const TableData = ({data, loading}) => {

    if (loading) return <EnigooLoader/>
    if (!data) return null

    let columns = [
            {Header: 'ID', accessor: "id"},
            {Header: 'subjekt', accessor: "name"},
            {Header: 'IP adresa', accessor: "ip"},
            {Header: 'poznámka', accessor: "note"},

            {
                Header: 'Možnosti', accessor: d => (
                    <Button text={"Upravit"} link={"/connections/" + d.id}/>
                )
            }
        ]
    ;


    return (
        <Widget>
            <Datatable data={data} columns={columns}/>
        </Widget>
    )
}

export default TableData
