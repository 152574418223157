import { createStore, applyMiddleware } from "redux";
import storage from "redux-persist/lib/storage";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import {persistReducer, persistStore} from "redux-persist";
import {rootReducer} from "./reducers";

 const persistConfig = {
     key: "root",
     storage,
 }

 const persistedReducer = persistReducer(persistConfig, rootReducer)

let createStoreWithMiddleware = null;
 if (process.env.NODE_ENV !== "production") {
    createStoreWithMiddleware = applyMiddleware(thunk, createLogger())(createStore);
 } else {
    createStoreWithMiddleware = applyMiddleware(thunk)(createStore);
}

 export const store = createStoreWithMiddleware(persistedReducer);
 export const persistor = persistStore(store);
