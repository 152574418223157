import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import Toast from "cogo-toast";
import {apiGetCheckTicket} from "./Actions";
import {DefaultLoader} from "../../../components/DefaultLoader";
import TicketTable from "../Ticket/TicketTable";
import UserArealSelect from "../Turnstiles/UserArealSelect";
import Widget from "../../../components/widget";
import {Form} from "react-final-form";
import InputField from "../../../FinalForm/InputField";
import {Button} from "../../../components/My/Button";
import {FiEdit, FiSearch} from "react-icons/fi";
import EntranceTableV2 from "../EntranceV2/EntranceTableV2";
import {getEnigooTurnstileTicketTicketType, getEnigooTurnstileTicketType} from "../enigooTurnstileHelper";

const CheckTicket = () => {
    let [data, setData] = useState(null);
    let [loading, setLoading] = useState(false);
    let [errorMessage, setErrorMessage] = useState(null);
    let user = useSelector((state) => state.user)
    let bcRef = useRef(null);

    useEffect(() => {
        if (bcRef.current) {
            bcRef.current.focus()
            bcRef.current.select()
        }
    }, []);

    const checkTicket = (values) => {
        setLoading(true);
        apiGetCheckTicket(user.activeAreal.id, values.bc, (data) => {
            if(data){
                setData(data);
                setLoading(false);
                setErrorMessage(null);
            }else{
                Toast.error("Požadovaná vstupenka nebyla nalezena");
                setErrorMessage("Požadovaná vstupenka nebyla nalezena");
                setData(null)
                setLoading(false);
            }
        }, (error) => {
            Toast.error("Požadovaná vstupenka nebyla nalezena");
            setErrorMessage("Požadovaná vstupenka nebyla nalezena");
            setData(null)
            setLoading(false);
        })
    }

    const InfoField = ({label, value}) => {
        return <div className={"flex flex-col space-y-1"}>
            <div className={"font-bold"}>{label}:</div>
            <div className={"p-2 rounded border border-blue-500 h-full"}>
                {value ?? "-"}
            </div>
        </div>
    }

    const _renderInfo = () => {
        return <Widget>
            <div className={"flex flex-col"}>
                <h5>Informace o vstupence</h5>
                <div className={"grid grid-cols-4 gap-2 mb-3"}>
                    <div className={"flex flex-col space-y-1"}>
                        <div className={"font-bold"}>Aktivní:</div>
                        <div
                            className={`p-2 rounded font-bold border ${data.active ? "bg-green-300 text-green-800 border-green-800" : "bg-red-300 text-red-800 border-red-800"}`}>
                            {data.active ? "ANO" : "NE"}
                        </div>
                    </div>
                    <div className={"flex flex-col space-y-1"}>
                        <div className={"font-bold"}>Blokovaná:</div>
                        <div
                            className={`p-2 rounded font-bold border ${!data.blocked ? "bg-green-300 text-green-800 border-green-800" : "bg-red-300 text-red-800 border-red-800"}`}>
                            {data.blocked ? "ANO" : "NE"}
                        </div>
                    </div>
                    <InfoField label={"BC"} value={data.bc}/>
                    <InfoField label={"Platná od"} value={data.dateFrom}/>
                    <InfoField label={"Platná do"} value={data.dateTo}/>
                    <InfoField label={"Počet povolených průchodů"} value={data.entrance}/>
                    <InfoField label={"ID události"} value={data.eventId}/>
                    <InfoField label={"Sleva vstupenky"} value={getEnigooTurnstileTicketType(data.type)}/>
                    <InfoField label={"Typ vstupenky"} value={getEnigooTurnstileTicketTicketType(data.ticketType)}/>
                    <InfoField label={"Jméno"} value={data.name}/>
                    <InfoField label={"Poznámka"} value={data.note}/>
                    <InfoField label={"Tariff"} value={data?.tariff?.name}/>

                </div>
                {(data.active && data.id) ?
                    <Button link={"/ticket/detail/" + data.id} text={"Přejít na detail"} icon={<FiEdit size={20}/>}
                            type={"submit"}/> : <></>}
            </div>
        </Widget>
    }

    const _renderTable = () => {
        return <Widget>
            <h5>Nalezené průchody</h5>
            <EntranceTableV2 data={data?.entrances ?? []}/>
        </Widget>
    }

    const _renderData = () => {
        return <>
            {_renderInfo()}
            {_renderTable()}
        </>
    }

    const _renderBody = () => {
        if (loading) return <DefaultLoader text={"Hledám vstupenku..."}/>;
        if (!loading && data) return _renderData();
        else return <>{errorMessage ? errorMessage : ""}</>
    }

    return (
        <>
            <UserArealSelect/>
            <Widget>
                <div className={"flex flex-row text-black"}>
                    <h5 className={"align-middle"}>Kontrola vstupenky</h5>
                </div>
            </Widget>
            <Widget>
                <Form onSubmit={checkTicket}
                      render={({handleSubmit, values, form, errors, ...props}) => {
                          return (
                              <div className={"flex flex-col space-y-2"}>
                                  <InputField label={"Barcode"} name={"bc"} isMandatory={true} onEnter={handleSubmit} reference={bcRef}
                                              inputType={"text"} placeholder={"Zadejte barcode vstupenky/karty"}/>
                                  <Button text={"Vyhledat"} icon={<FiSearch/>} type={"submit"} disabled={loading}
                                          onClick={handleSubmit}/>
                              </div>
                          )
                      }}/>
            </Widget>
            {user.activeAreal && _renderBody()}
        </>
    )
}

CheckTicket.propTypes = {}

export default CheckTicket;
