import React, {useEffect, useState} from "react"
import {Button} from "../../components/My/Button";
import Loader from "../../components/loader";
import {useParams} from "react-router-dom";

import InputField from "../../FinalForm/InputField";
import {Form} from "react-final-form";

import {apiGetReaders} from "./Actions";
import ErrorMessage from "../../components/My/ErrorMessage";


const ReaderDetail = () => {

    const {id} = useParams();
    let [data, setData] = useState(null);
    let [loading, setLoading] = useState(false);
    let [error, setError] = useState(null);


    useEffect(() => {
        getReader()
    }, [])

    const getReader = () => {
        setLoading(true)
        setData(null)
        setError(null)
        apiGetReaders((id), (data) => {
            setData(data)
            setLoading(false)
        }, (err) => {
            switch (err.error.type){
                case "READER_NOT_EXIST":
                    setError({title: "Došlo k chybě", text: " Zařízení nebylo nalezeno."})
                    break;
                default:
                    setError({title: "Došlo k chybě", text: " Zařízení nebylo nalezeno."})

            }
            setLoading(false)
        })
    }


    const submitForm = () => {

    }



    if (loading) return <Loader/>
    if (error) return <ErrorMessage text={error?.text} title={error?.title}/>
    if (!data) return null

    if (data) return(
        <Form
            initialValues={data[0]}
            onSubmit={submitForm}
            render={({values, handleSubmit}) => {
                return (
                    <div>
                        <InputField name={"uid"} label={"UID"}/>
                        <InputField name={"model"} label={"Model"}/>
                        <InputField name={"actualPrefix"} label={"Actual Prefix"}/>
                        <InputField name={"lastDate"} label={"Last Date"}/>
                        <InputField name={"version"} label={"Version"}/>
                        <InputField name={"battery"} label={"Battery"}/>
                        <InputField name={"get"} label={"Get"}/>
                        <InputField name={"lastLog"} label={"Last Log"}/>
                        <InputField name={"getLog"} label={"Get Log"}/>
                        <InputField name={"cmf"} label={"CMF"}/>
                        {JSON.stringify(values)}
                        {JSON.stringify({data})}

                        <Button text={"odeslat"} onClick={handleSubmit}/>
                    </div>
                )

            }}/>
    )
}

export default ReaderDetail
