import React, {useEffect, useState} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import Toast from "cogo-toast";
import Widget from "../../../components/widget";
import {Form} from "react-final-form";
import DateTimeField from "../../../FinalForm/DateTimeField";
import moment from "moment/moment";
import InputField from "../../../FinalForm/InputField";
import SelectField from "../../../FinalForm/SelectField";
import LogMessageSelectField from "../../../FinalForm/api/LogMessageSelectField";
import {Button} from "../../../components/My/Button";
import {FiChevronLeft, FiChevronRight, FiDownload, FiRefreshCcw} from "react-icons/fi";
import {DefaultLoader} from "../../../components/DefaultLoader";
import UserArealSelect from "../Turnstiles/UserArealSelect";
import {apiPostEntrancesAll, apiPostExportEntranceAll} from "./Actions";
import EntranceTableV2 from "./EntranceTableV2";
import ApiTariffSelectField from "../../../FinalForm/api/ApiTariffSelectField";
import ApiTurnstileSelectField from "../../../FinalForm/api/ApiTurnstileSelectField";
import ApiZoneSelectField from "../../../FinalForm/api/ApiZoneSelectField";
import FileDownload from "js-file-download";

const EntranceV2 = () => {

    let [data, setData] = useState([]);
    let [apiPage, setApiPage] = useState(0);
    let [type, setType] = useState("data");
    let [loading, setLoading] = useState(false);
    let user = useSelector((state) => state.user)

    let {filterEntrance} = useSelector(
        state => ({
            filterEntrance: state.filterEntrance,
        }),
        shallowEqual
    );

    useEffect(() => {
        if (user.activeAreal) {
            if (!filterEntrance.filterEntranceData || !filterEntrance.filterEntranceData.dateFrom || !filterEntrance.filterEntranceData.dateTo) {
                /*let filData = {dateFrom: getTodayDateFrom(), dateTo: getTodayDateTo(), bc: null, message: null};
                dispatch({
                    type: 'SET_FILTER_ENTRANCE',
                    payload: filData
                })*/

            }
        }
    }, [user.activeAreal])

    const getTodayDateFrom = () => {
        let date = new Date().toISOString();
        return date.slice(0, date.indexOf("T")) + "T00:00";
    }

    const getTodayDateTo = () => {
        let date = new Date().toISOString();
        return date.slice(0, date.indexOf("T")) + "T23:59";
    }

    const dispatch = useDispatch();
    let submit;

    useEffect(() => {
        //getData(filterEntrance.filterEntranceData)
    }, [filterEntrance, apiPage]);

    const getData = (filterValues,page) => {
        setLoading(true);
        let postData = {
            ...filterValues,
            page: page,
            dateFrom: filterValues?.dateFrom ? convertDate(filterValues.dateFrom) : null,
            dateTo: filterValues?.dateTo ? convertDate(filterValues.dateTo) : null,
            withoutLog: filterValues?.withoutLog && filterValues.withoutLog === 1 ? 1 : 0,
            withoutArchive: filterValues?.withoutArchive && filterValues.withoutArchive === 1 ? 1 : 0,
            withoutActual: filterValues?.withoutActual && filterValues.withoutActual === 1 ? 1 : 0,
            ticketTicketType: filterValues?.ticketTicketType && filterValues.ticketTicketType.map((tt) => {
                if (tt === -1) {
                    return 0;
                } else {
                    return tt;
                }
            }),
        }
        if (type === "data") {
            apiPostEntrancesAll(user.activeAreal.id, postData, (data) => {
                    if (data == null) setData([]);
                    else setData(data);
                    setLoading(false);
                }, (user?.activeAreal?.ip ? user.activeAreal.ip : null)
                , (error) => {
                    Toast.error("Při načítání průchodů nastala chyba.");
                    setData([]);
                    setLoading(false);
                })
        } else {
            apiPostExportEntranceAll(user.activeAreal.id, postData, (data) => {
                    if (type === "JSON") {
                        setData(data)
                        setLoading(false)
                    } else {
                        var blob = new Blob([data.data], {type: "application/vnd.ms-excel"});
                        FileDownload(blob, data.headers['content-disposition'].replace('attachment; filename=', ''))
                        setLoading(false)
                    }
                    setLoading(false);
                }, (user?.activeAreal?.ip ? user.activeAreal.ip : null)
                , (error) => {
                    Toast.error("Při stahování průchodů nastala chyba.");
                    setData([]);
                    setLoading(false);
                })
        }

    }

    const convertDate = (date) => {
        if (date) {
            let dateInString = date.replace('T', " ");
            dateInString += ":00";
            return dateInString;
        } else {
            return null;
        }
    }

    const onEnter = (e) => {

        if (e.key === `Enter`) {
            submit();
        }
    }

    const renderFilterForm = () => (
        <Widget>
            <div className={"text-sm"}>
                <Form onSubmit={(values) => {
                    setApiPage(0)
                    dispatch({
                        type: 'SET_FILTER_ENTRANCE',
                        payload: values
                    })
                    getData(values,0);
                }} initialValues={filterEntrance.filterEntranceData}
                      render={({values, handleSubmit}) => {
                          submit = handleSubmit;
                          return (
                              <div className={"grid grid-cols-2 lg:grid-cols-4 gap-2"}>
                                  <DateTimeField name={"dateFrom"} isMandatory={false} valueToCompare={values.dateTo}
                                                 validateFun={(date1, date2) => {
                                                     if (moment(date1).isAfter(moment(date2))) {
                                                         return "Datum od nemůže být po datu do"
                                                     } else {
                                                         return "";
                                                     }
                                                 }} label={"Datum od"} type={"from"} placeholder={"Datum od"}
                                                 showTimeSelect={true} onEnter={submit}/>
                                  <DateTimeField name={"dateTo"} isMandatory={false} valueToCompare={values.dateFrom}
                                                 validateFun={(date1, date2) => {
                                                     if (moment(date2).isAfter(moment(date1))) {
                                                         return "Datum od nemůže být po datu do"
                                                     } else {
                                                         return "";
                                                     }
                                                 }} label={"Datum do"} type={"to"} placeholder={"Datum do"}
                                                 showTimeSelect={true} onEnter={submit}/>
                                  <InputField label={"Událost id"} name={"eventId"} isMandatory={false}
                                              inputType={"number"} onEnter={submit}/>
                                  <InputField label={"BC"} name={"bc"} isMandatory={false} onEnter={submit}/>

                                  <SelectField name={"withoutLog"} options={[
                                      {label: "ANO", value: 1}, {label: "NE", value: -1}
                                  ]} label={"Bez neúspěšných"} isMandatory={false} onEnter={submit}/>

                                  <SelectField name={"withoutArchive"} options={[
                                      {label: "ANO", value: 1}, {label: "NE", value: -1}
                                  ]} label={"Bez archivovaných"} isMandatory={false} onEnter={submit}/>
                                  <SelectField name={"withoutActual"} options={[
                                      {label: "ANO", value: 1}, {label: "NE", value: -1}
                                  ]} label={"Bez aktuálních"} isMandatory={false} onEnter={submit}/>
                                  <LogMessageSelectField onEnter={submit} name={"result"}/>
                                  <ApiTariffSelectField name={"tariffId"} isMandatory={false} isMulti={true}
                                                        onEnter={submit}/>
                                  <ApiTurnstileSelectField name={"turnstileId"} isMandatory={false} isMulti={true}
                                                           onEnter={submit}/>
                                  <ApiZoneSelectField name={"zoneId"} isMandatory={false} isMulti={true}
                                                      onEnter={submit}/>
                                  <InputField label={"Jméno"} name={"ticketName"} isMandatory={false} onEnter={submit}/>
                                  <InputField label={"Poznámka"} name={"ticketNote"} isMandatory={false}
                                              onEnter={submit}/>
                                  <SelectField name={"ticketType"} isMulti={true} options={[
                                      {label: "Děti", value: 1}, {label: "Zlevněná", value: 2}
                                  ]} label={"Sleva vstupenky"} isMandatory={false} onEnter={submit}/>
                                  <SelectField name={"ticketTicketType"} isMulti={true} options={[
                                      {label: "Servisní vstupenka", value: -1}, {
                                          label: "Běžná vstupenka",
                                          value: 1
                                      }, {label: "Permanentka s kreditem", value: 2}
                                  ]} label={"Typ vstupenky"} isMandatory={false} onEnter={submit}/>
                                  <InputField label={"Minimální částka účtovaná za průchod (Kč)"} name={"minPrice"}
                                              inputType={"number"} isMandatory={false} onEnter={submit}/>
                                  <InputField label={"Maximální částka účtovaná za průchod (Kč)"} name={"maxPrice"}
                                              inputType={"number"} isMandatory={false} onEnter={submit}/>

                                  <div className={"lg:col-start-4 mt-6 mb-5 flex justify-end w-full"}>
                                      <div className={"flex flex-row space-x-2"}>
                                          <Button
                                              onClick={() => {
                                                  setType("export")
                                                  handleSubmit()
                                              }} loading={loading} text={"Exportovat vše"}
                                              icon={<FiDownload/>}/>
                                          <Button
                                              onClick={() => {
                                                  setType("data")
                                                  handleSubmit()
                                              }} loading={loading} text={"Aktualizovat"}
                                              icon={<FiRefreshCcw/>}/></div>
                                  </div>
                              </div>
                          );

                      }}/>
            </div>
        </Widget>
    )

    const _renderBody = () => {
        if (loading) return <DefaultLoader text={"Načítám průchody..."}/>;
        if (!loading && data && data.data) return <EntranceTableV2 data={data.data}/>
        else return <>Žádná data</>
    }

    const _renderPagination = () => {
        if (loading || !data || !data.data) {
            return <></>
        }
        return <Widget>
            <div className={"flex flex-col space-y-2"}>
                <div className={"grid grid-cols-3 gap-2 place-items-center"}>
                    <span><b>Celkem dostupných záznamů: </b>{data.maxCount}</span>
                    <span><b>Aktuálně načtených záznamů: </b>{data.countOnActualPage}</span>
                    <span><b>Část </b>{apiPage + 1} z {data.maxPage + 1}</span>
                </div>
                <div className={"px-20 grid grid-cols-2 gap-2"}>
                    <Button
                        disabled={apiPage === null || apiPage <= 0 || !data.maxPage}
                        onClick={() => {
                            let page = apiPage - 1;
                            setApiPage(page);
                            getData(filterEntrance.filterEntranceData,page)
                        }} loading={loading} text={"Načíst předchozí data"}
                        icon={<FiChevronLeft/>}/>
                    <Button
                        disabled={apiPage === null || apiPage >= data.maxPage || !data.maxPage}
                        onClick={() => {
                            let page = apiPage + 1;
                            setApiPage(page);
                            setApiPage(page);
                            getData(filterEntrance.filterEntranceData,page)
                        }} loading={loading} text={"Načíst další data"}
                        icon={<FiChevronRight/>}/>
                </div>

            </div>
        </Widget>
    }

    return (
        <>
            <UserArealSelect loading={false}/>
            <Widget>
                <div className={"flex flex-row text-black"}>
                    <h5 className={"align-middle"}>Průchody</h5>
                    <span className="ml-auto"></span>
                </div>
            </Widget>
            {user.activeAreal && renderFilterForm()}
            {user.activeAreal && data && _renderPagination()}
            {user.activeAreal && _renderBody()}
        </>
    )


}
export default EntranceV2;

