import React from "react"

import Datatable from "../../components/datatable";
import Loader from "../../components/loader";
import Widget from "../../components/widget";
import {FcCancel, FcOk} from "react-icons/fc";

const TableDataServices = ({data, setItem, loading}) => {

   if(loading) return <Loader/>
   if (!data) return null


   let columns = [
      {Header: '#', accessor: "serviceId"},
      {Header: 'Služba', accessor: "communicationType"},
      {
         Header: 'Služba', accessor: d => (
            <div>{`${d.used}/${d.entrance}`}</div>
         )
      },
      {Header: 'Expirace', accessor: "dateExpire"},
      {Header: 'Stav', accessor: d => (
         d.active ? <div className={"flex justify-center"}><FcOk size={20}/></div> : <div className={"flex justify-center"}><FcCancel size={20}/></div>
         )},

   ];


   return (
      <Widget title={"Zaznamenané služby"}>
         <Datatable data={data} columns={columns} showPagination={data.length>=10}/>
      </Widget>
   )
}

export default TableDataServices
