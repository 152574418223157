import React, {useEffect, useState} from "react";
import {apiGetDevice} from "../../pages/ENIGOOTurnstiles/Device/Actions";
import {useSelector} from "react-redux";
import SelectField from "../SelectField";

const ApiDeviceSelectMultiField = ({disabled = false, onEnter = null}) => {

    let [data, setData] = useState(null);
    let [loading, setLoading] = useState(false);
    let user = useSelector((state) => state.user)


    useEffect(() => {
       getData()
    }, [])



    const getData = () => {
        setLoading(true);
        apiGetDevice(user?.activeAreal.id, null, (data) => {
            setData(data);
            setLoading(false);
        }, (error) => {
            setData([]);
            setLoading(false);
        })
    }

    const getOptions = () => {
        let d = []
        if(!data) return []
        data.map((item, index) => {
            d.push({label: item.name, value: item.id})
        })
        return d
    }

    return (
        <SelectField onEnter={onEnter} disabled={disabled} placeholder={loading ? "Načítám data.." : "Zařízení"}
                          name={"device"} options={loading ? [] : getOptions()} label={"Zařízení"} isMulti={true}/>
    )
}

export default ApiDeviceSelectMultiField;
