import React, {useEffect, useState} from "react"
import {apiGetDashboard} from "./Actions";
import TableData from "./TableData";
import {EnigooLoader} from "../../components/My/EnigooLoader";
import {useSelector} from "react-redux";

const Dashboard = () => {

    let [loading, setLoading] = useState(false)
    let [data, setData] = useState(null)
    let user = useSelector((state) => state.user)


    useEffect(() => {
        get()
    }, [])

    const get = () => {
        if (user?.user?.rights?.dashboard) {
            setLoading(true)

            apiGetDashboard((data) => {
                data && setData(data)
                setLoading(false)
            }, error => {
                setLoading(false)
            })
        }
    }

    if (loading) return <EnigooLoader/>
    if (!data) return null

    const getBody = () => {
        if (user?.user?.rights?.dashboard) {
            return <TableData data={data}/>
        } else {
            return (
                <div>
                    Turnikety ENIGOO
                </div>
            )
        }
    }

    return getBody()

}

export default Dashboard
