export default function pages(
    state = {
        turnstileSize:10,
        turnstilePage:0,
        ticketSize:10,
        ticketPage:0,
        tariffSize:10,
        tariffPage:0,
        entranceSize:10,
        entrancePage:0,
        entranceLogSize:10,
        entranceLogPage:0,
        userSize:10,
        userPage:0,
        tokenSize:10,
        tokenPage:0,
        deviceSize:10,
        devicePage:0
    },action
){
    switch (action.type){
        case "SET_TURNSTILE_PAGE":
            return{
                ...state,
                turnstilePage:action.payload
            }
        case "SET_TURNSTILE_PAGE_SIZE":
            return{
                ...state,
                turnstileSize:action.payload
            }
        case "SET_TICKET_PAGE":
            return{
                ...state,
                ticketPage:action.payload
            }
        case "SET_TICKET_PAGE_SIZE":
            return{
                ...state,
                ticketSize:action.payload
            }
        case "SET_TARIFF_PAGE":
            return{
                ...state,
                tariffPage:action.payload
            }
        case "SET_TARIFF_PAGE_SIZE":
            return{
                ...state,
                tariffSize:action.payload
            }
        case "SET_ENTRANCE_PAGE":
            return{
                ...state,
                entrancePage:action.payload
            }
        case "SET_ENTRANCE_PAGE_SIZE":
            return{
                ...state,
                entranceSize:action.payload
            }
        case "SET_ENTRANCE_LOG_PAGE":
            return{
                ...state,
                entranceLogPage:action.payload
            }
        case "SET_ENTRANCE_LOG_PAGE_SIZE":
            return{
                ...state,
                entranceLogSize:action.payload
            }
        case "SET_USER_PAGE_SIZE":
            return{
                ...state,
                userSize:action.payload
            }
        case "SET_USER_PAGE":
            return{
                ...state,
                userPage:action.payload
            }
        case "SET_TOKEN_PAGE":
            return{
                ...state,
                tokenPage:action.payload
            }
        case "SET_TOKEN_PAGE_SIZE":
            return{
                ...state,
                tokenSize:action.payload
            }
        case "SET_DEVICE_PAGE":
            return{
                ...state,
                devicePage:action.payload
            }
        case "SET_DEVICE_PAGE_SIZE":
            return{
                ...state,
                deviceSize:action.payload
            }
        case "PAGE_RESET":
            return {
                ...state, turnstilePage:1,ticketPage:1,userPage:1,tariffPage:1,entrancePage:1,tokenPage:1,turnstileSize:10,ticketSize:10,userSize:10,tariffSize:10,entranceSize:10,tokenSize:10
            }
        default:
            return state;
    }
}
