import React, {useState} from "react"
import Modal from "../../components/My/Modal";
import {Button} from "../../components/My/Button";
import InputField from "../../FinalForm/InputField";
import {Form} from "react-final-form";
import {apiCreateEvent} from "./Actions";
import DateField from "../../FinalForm/DateField";
import Widget from "../../components/widget";
import Toast from "cogo-toast";


const ArenaEventsModal = ({visible, close, accept, subjectId}) => {

    let submit
    let [loading, setLoading] = useState(false)

    if (!visible) return null

    const submitForm = (values) => {
        values.subjectId = subjectId
        setLoading(true)
        apiCreateEvent((values), (data) => {
            setLoading(false)
            Toast.success("Úspěšně přidáno.")
            close(true)
        }, error => {
            switch (error.error.type) {
                case "SUBJECT_NOT_EXIST":
                    Toast.error(" Subjekt neexistuje.")
                    close(true)
                    break;
                case "SUBJECT_NOT_VALID":
                    Toast.error(" Subjekt není pro tuto akci validní.")
                    close(true)
                    break;
                default:
                    Toast.error(" Nastala neočekávaná chyba.")
                    close(true)
            }
            setLoading(false)
        })

    }

    const validate = (values) => {
        let errors = {};
        if (values?.ticketCount){
            if (Number(values.ticketCount)<1){
                errors.ticketCountNumber = true
            }
        }
        return errors;
    }

    return (
        <>
            <Modal title={"Přidání události"}
                   body={
                       <Form
                           validate={validate}
                           onSubmit={submitForm}
                           render={({handleSubmit, errors}) => {
                               submit = handleSubmit
                               return (
                                   <>
                                       <InputField label={"Název událostí"} name={"eventName"} isMandatory={true}/>
                                       <DateField label={"Datum události"} name={"eventDate"} isMandatory={true}/>
                                       <InputField label={"Počet vstupenek"} name={"ticketCount"} inputType={"number"} isMandatory={true}/>
                                       {errors?.ticketCountNumber && <Widget><span className={"text-red-400"}>Musí být alespoň 1 vstupenka.</span></Widget>}
                                   </>
                               )
                           }}/>
                   }
                   visible={visible}
                   close={() => close()}
                   footer={<>
                       <Button text={"Uložit"} onClick={() => submit()} loading={loading}/>
                       <Button text={"Zrušit"} onClick={() => close()}/>
                   </>
                   }
            />
        </>
    )
}

export default ArenaEventsModal
