import React from "react"
import Modal from "../../components/My/Modal";
import {Button} from "../../components/My/Button";


const RulesModal = ({visible, close, accept}) => {


    if (!visible) return null
    return (
        <>
            <Modal body={"Opravdu chete smazat?"}
                   title={"Vymazat pravidlo pro turniket"}
                   visible={visible}
                   close={() => close()}
                   footer={<>
                       <Button text={"Zrušit"} onClick={() => close()}/>
                       <Button text={"Potvrdit"} onClick={()=> accept()}/>
                   </>
                   }
            />
        </>
    )
}

export default RulesModal
