import {instance, onErrorFunction, onSuccessFunction} from "../../../config/axios-cnf";

export const apiPostEntrancesAll = (subjectId,data,callback,error)=>{
    instance.post("/api/v2/enigoo/" + subjectId + "/entrances/all", data)
        .then((response) => callback(onSuccessFunction(response.data)))
        .catch((err) => error(onErrorFunction(err)));
}

export const apiPostExportEntranceAll = (subjectId,data,callback,error)=>{
    instance.post("/api/v2/enigoo/" + subjectId + "/entrances/export", data,{
        responseType: 'arraybuffer'
    })
        .then((response) => callback(response))
        .catch((err) => error(onErrorFunction(err)));
}
