export default function filterTicket(
    state = {
        filterTicketData:null
    },action
){
    switch (action.type){
        case "SET_FILTER_TICKET":
            return{
                ...state,
                filterTicketData:action.payload
            }
        case "FILTER_TICKET_RESET":
            return {
                ...state, filterTicketData:null
            }
        default:
            return state;
    }
}
