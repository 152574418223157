import React, {useEffect, useState} from "react"
import {Button} from "../../components/My/Button";
import {useNavigate, useParams} from "react-router-dom";
import InputField from "../../FinalForm/InputField";
import {Form} from "react-final-form";
import {apiCreateSubject, apiGetSubjectById, apiUpdateSubject} from "./Actions";
import ConnectionField from "../../FinalForm/api/ConnectionField";
import CheckboxField from "../../FinalForm/CheckboxField";
import Widget from "../../components/widget";
import Toast from "cogo-toast";
import TypeJobsField from "../../FinalForm/api/TypeJobsField";
import {EnigooLoader} from "../../components/My/EnigooLoader";


const SubjectForm = () => {

    const {id} = useParams();
    let [data, setData] = useState(null);
    let [loading, setLoading] = useState(false);
    let [loadingButton, setLoadingButton] = useState(false);
    let navigate = useNavigate();


    useEffect(() => {
        id && getSubjects()
    }, [])

    const getSubjects = () => {
        setLoading(true)
        setData(null)
        apiGetSubjectById((id), (data) => {
            setData(data)
            setLoading(false)
        }, (err) => {
            switch (err.error.type) {
                default:
                    Toast.error("Neočekávaná chyba.")
            }
            setLoading(false)
        })
    }


    const submitForm = (e) => {
        setLoadingButton(true)
        if (id) {
            apiUpdateSubject((e), (data) => {
                setLoadingButton(false)
                Toast.success("Dokončeno")
            }, error => {
                switch (error.error.type) {
                    case "CONNECTION_NOT_EXIST":
                        Toast.error("Připojení neexistuje.")
                        break;
                    case "SUBJECT_NOT_EXIST":
                        Toast.error("Subjekt neexistuje.")
                        break;
                    case "PREFIX_ALREADY_EXIST":
                        Toast.error("Prefix už existuje.")
                        break;
                    case "TYPEJOB_NOT_EXIST":
                        Toast.error("Job neexistuje.")
                        break;
                    default:
                        Toast.error("Neočekávaná chyba.")
                }
                setLoading(false)
            })
        } else {
            apiCreateSubject((e), (data) => {
                setLoading(false)
                Toast.success("Dokončeno")
                navigate("/subjects")
            }, error => {
                switch (error.error.type) {
                    case "CONNECTION_NON_EXIST":
                        Toast.error("Připojení neexistuje.")
                        break;
                    case "SUBJECT_EXIST":
                        Toast.error("Subjekt neexistuje.")
                        break;
                    case "PREFIX_ALREADY_EXIST":
                        Toast.error("Prefix už existuje.")
                        break;
                    default:
                        Toast.error("Neočekávaná chyba.")
                }
                setLoading(false)
            })
        }
    }


    const getBody = () => {
        if (loading) return <EnigooLoader/>
        if (id && !data) return null
        return (
            <Form
                initialValues={data ? data[0] : null}
                onSubmit={submitForm}
                render={({handleSubmit}) => {
                    return (
                        <>
                            <div>
                                <div>
                                    <div className={"w-full"}>
                                        <InputField name={"prefix"} label={"Prefix"} isMandatory={true}/>
                                    </div>
                                    <div className={"w-full"}>
                                        <InputField name={"name"} label={"Název subjektu"} isMandatory={true}/>
                                    </div>
                                    <div className={"w-full"}>
                                        <TypeJobsField name={"jobs"} label={"Vyberte job"} isMulti={true}/>
                                    </div>
                                    <div className={"w-full"}>
                                        <ConnectionField name={"connections.id"} label={"Vyberte připojení"}
                                                         validate={value => !value}/>
                                    </div>
                                    <div className={"w-full flex"}>
                                        <div className={"w-1/2"}>
                                            <InputField name={"settingsData.before"}
                                                        label={"Počet hodin před událostí"}
                                                        inputType={"number"}
                                                        isMandatory={true}/>
                                        </div>
                                        <div className={"w-1/2"}>
                                            <InputField name={"settingsData.after"}
                                                        label={"Počet hodin po události"}
                                                        inputType={"number"}
                                                        isMandatory={true}/>
                                        </div>
                                    </div>
                                </div>
                                <div className={"w-full flex flex-wrap"}>
                                    <div className={"w-1/4"}>
                                        <CheckboxField name={"isOnline"} label={"Subjekt je online"}
                                                       isMandatory={false}/>
                                    </div>
                                    <div className={"w-1/4"}>
                                        <CheckboxField name={"autoPush"} label={"Automatické nahrávání událostí"}
                                                       isMandatory={false}/>
                                    </div>
                                    <div className={"w-1/4"}>
                                        <CheckboxField name={"isByRabbit"} label={"Vstupenky se nahrávají Rabbitem"}
                                                       isMandatory={false}/>
                                    </div>
                                    <div className={"w-1/4"}>
                                        <CheckboxField name={"isEnigooTurnikets"} label={"Služba je ENIGOO TURNIKETS"}
                                                       isMandatory={false}/>
                                    </div>
                                </div>

                                <Button text={data ? "Uložit změny" : "Uložit"} onClick={handleSubmit}
                                        loading={loadingButton}/>
                            </div>
                        </>

                    )
                }}/>)
    }


    if (loading) return <EnigooLoader/>

    return (
        <>
            <Widget>
                <div className={"flex flex-row text-black"}>
                    <h5 className={"align-middle"}>{data ? "Upravit subjekt" : "Vytvořit subjekt"}</h5>
                    <span className="ml-auto"></span>
                </div>
            </Widget>
            <Widget>
                {getBody()}
            </Widget>
        </>

    )
}

export default SubjectForm
