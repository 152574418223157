import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import Toast from "cogo-toast";
import {DefaultLoader} from "../../../components/DefaultLoader";
import Widget from "../../../components/widget";
import {IconTabs} from "../../../components/tabs";
import InputField from "../../../FinalForm/InputField";
import {Form} from "react-final-form";
import {Button} from "../../../components/My/Button";

import {FiInfo, FiSave} from "react-icons/fi";
import Header from "../../../components/My/Header";
import ApiZoneSelectField from "../../../FinalForm/api/ApiZoneSelectField";
import {apiGetZone, apiPostZone} from "./Actions";
import ApiTariffSelectField from "../../../FinalForm/api/ApiTariffSelectField";
import ZoneTariffPriceList from "./ZoneTariffPriceList";
import arrayMutators from "final-form-arrays";


const ZoneForm = () => {
    let [loading, setLoading] = useState(false);
    let [saving, setSaving] = useState(false);
    let [data, setData] = useState(null);
    let user = useSelector((state) => state.user)


    const {id} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        if (id) {
            setLoading(true);
            apiGetZone(user.activeAreal.id, id, (data) => {
                let newData = data;
                if(data?.tariffs){
                    newData.selectedTariff = data.tariffs.map(t=>{
                        return t.tariffId;
                    })
                }
                if(data?.parentZone){
                    newData.parentZone = data.parentZone.id;
                }
                setData(newData);
                setLoading(false);
            }, (err) => {
                setLoading(false);
                handleError(err);
                navigate("/zone");
            })
        }
    }

    const handleError = (err) => {
        let error = "Nepodařilo se načíst data o zóně.\n";
        switch (err.data) {
            case "ZONE_NOT_FOUND":
                error += "Zóna nebyla nalezen.";
                break;
            case "UNKNOWN_ERROR":
            default:
                error += "Neznámá chyba.";
        }
        Toast.error(error);
    }

    const onSubmit = (values) => {
        setSaving(true);
        let newValues = {...values,selectedTariff:undefined}

        apiPostZone(user.activeAreal.id, id?id:null,newValues, (data) => {
            let newData = data;
            if(data?.tariffs){
                newData.selectedTariff = data.tariffs.map(t=>{
                    return t.tariffId;
                })
            }
            if(data?.parentZone){
                newData.parentZone = data.parentZone.id;
            }
            setData(newData);
            setSaving(false);
            navigate("/zone/detail/" + data.id);
            Toast.success("Úspěšně uloženo.");
        }, (err) => {
            setSaving(false);
            handleError(err);
        })
    }

    const _renderBody = (values,push) => {
        if (loading) return <DefaultLoader text={"Načítám data tarifu..."}/>;
        else return <Widget><IconTabs tabs={[{
            index: 0,
            title: (<><FiInfo size={18} className={"stroke-current"}/><span
                className={"ml-2"}>Základní informace</span> </>),
            content: <>
                <InputField label={"Název (25)"} name={"name"} isMandatory={true} inputType={"text"}/>
                <InputField label={"Kapacita zóny"} name={"capacity"} isMandatory={false} inputType={"number"}/>
                <InputField label={"Časový limit v zóně"} name={"timeLimit"} isMandatory={false} inputType={"number"}/>

                <ApiZoneSelectField isMulti={false} label={"Nadřazená zóna"} name={"parentZoneId"} />
                {!loading&&<ZoneTariffPriceList values={values} push={push}/>}
            </>

        }]}/>
        </Widget>
    }

    return (
        <Header data={[{text: "Zóny", isMain: false, link: "/zone"}, {text: id ? "Upravit zónu" : "Vytvořit zónu", isMain: true}]}>
            <Form onSubmit={onSubmit} initialValues={data}
                  mutators={{...arrayMutators}}

                  render={({handleSubmit, values,form: {
                      mutators: {push, pop, unshift}
                  }, ...props}) => {
                      return (
                          <div className={"flex flex-col w-full"}>
                              {_renderBody(values,push)}
                              <div className={"items-start"}>
                              <Button text={"Uložit"} onClick={() => handleSubmit()} icon={<FiSave/>} loading={saving}/>
                              </div>
                          </div>
                      )
                  }}/>
        </Header>
    )
}
export default ZoneForm;
