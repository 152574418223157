import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Form} from "react-final-form";
import {apiGetTurnstile, apiPostTurnstile} from "./Actions";
import Toast from "cogo-toast";
import {Button} from "../../../components/My/Button";
import InputField from "../../../FinalForm/InputField";
import Widget from "../../../components/widget";
import {IconTabs} from "../../../components/tabs";
import SelectField from "../../../FinalForm/SelectField";
import ApiTariffSelectField from "../../../FinalForm/api/ApiTariffSelectField";
import {useSelector} from "react-redux";
import {FiInfo, FiPlay, FiPlus, FiSave} from "react-icons/fi";
import ApiDeviceSelectMultiField from "../../../FinalForm/api/ApiDeviceSelectMultiField";
import Header from "../../../components/My/Header";
import ApiZoneSelectField from "../../../FinalForm/api/ApiZoneSelectField";
import {FieldArray} from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";

const TurnstileForm = () => {

    let [loading, setLoading] = useState(false);
    let [saving, setSaving] = useState(false);
    let [data, setData] = useState(null);

    const {id, unknownId} = useParams();
    const navigate = useNavigate();
    let user = useSelector((state) => state.user)
    let submit;

    useEffect(() => {
        user.activeAreal && getData();
    }, [user.activeAreal]);

    const getData = () => {
        if (id) {
            setLoading(true);
            apiGetTurnstile(user.activeAreal.id, id, (data) => {
                setData(data);
                setLoading(false);
            }, (err) => {
                setLoading(false);
                handleError(err);
                navigate("/turnstile");
            })
        }
    }

    const handleError = (err) => {
        let error = "Nepodařilo se načíst data o turniketu.\n";
        switch (err.data) {
            case "TURNSTILE_NOT_FOUND":
                error += "Turniket nebyl nalezen.";
                break;
            case "UNKNOWN_ERROR":
            default:
                if (error.startsWith("TARIFF_")) {
                    error += "Neznámý tarif";
                } else {
                    error += "Neznámá chyba.";
                }
        }
        Toast.error(error);
    }

    const onSubmit = (values) => {
        setSaving(true);
        let newValues = values
        if (id) newValues = {...values, turnstileId: id};

        apiPostTurnstile(user.activeAreal.id, newValues, (data) => {
            setData(data);
            setSaving(false);
            navigate("/turnstile/detail/" + data.id);
            Toast.success("Úspěšně uloženo.");
        }, (err) => {
            setSaving(false);
            handleError(err);
        })
    }

    const _renderBody = (values, push) => {
        return (
            <Widget>
                <IconTabs tabs={[{
                    index: 0,
                    title: (<><FiInfo size={18} className={"stroke-current"}/><span
                        className={"ml-2"}>Základní informace</span> </>),
                    content:
                        <>
                            <InputField label={"IP adresa"} name={"ipAddress"} isMandatory={true} inputType={"text"}/>
                            <InputField label={"Popis"} name={"description"} isMandatory={false} inputType={"text"}/>
                            <SelectField name={"type"} options={[{label: "Vstup", value: 1}, {
                                label: "Výstup",
                                value: 0
                            }, {label: "V areálu (wc apod.)", value: 2}]}
                                         label={"Typ"} isMandatory={true}/>
                            <ApiDeviceSelectMultiField/>
                            <ApiTariffSelectField/>

                            <InputField label={"Open time elvis"} name={"openTime"} isMandatory={false}
                                        inputType={"text"}/>
                            <InputField label={"Port zařízení - příchozí"} name={"source"} isMandatory={false}
                                        inputType={"text"}/>
                            <InputField label={"Port zařízení - odchozí"} name={"destination"} isMandatory={false}
                                        inputType={"text"}/>
                            <SelectField name={"turnstileType"} options={[{label: "VGUANG IP", value: 0}, {
                                label: "RFID čtečka PAPOUCH",
                                value: 1
                            }, {label: "ELVIS", value: 2}]}
                                         label={"Typ (výrobce)"} isMandatory={true}/>
                            <ApiZoneSelectField name={"zone"} isMulti={false}/>
                            {(values?.turnstileType && values.turnstileType === 2) ?
                                <div className={"flex flex-col pt-2"}>
                                    <span className={"font-bold"}>Speciální pravidla:</span>
                                    <FieldArray name={"specialRules"}>
                                        {({fields}) => {

                                            return (<div className={"flex flex-col space-y-2"}>
                                                    <Button text={"Přidat"} icon={<FiPlus/>} onClick={() => {
                                                        fields.push({
                                                            tariffId: null,
                                                            source: null,
                                                            destination: null,
                                                            openTime: null
                                                        })
                                                    }}/>
                                                    <div className={"grid grid-cols-3 gap-2"}>
                                                        {fields.map((name, index) => (
                                                            <div className={"p-2 flex flex-col space-y-2 border"}
                                                                 key={index}>
                                                                <div className={"flex flex-row"}>
                                                                    <span
                                                                        className={"font-bold w-full"}>{`#${index + 1} `}</span>
                                                                    <span
                                                                        onClick={() => {
                                                                            fields.remove(index)
                                                                        }}
                                                                        style={{cursor: 'pointer'}}>❌</span>
                                                                </div>
                                                                <ApiTariffSelectField name={`${name}.tariffId`}
                                                                                      isMulti={false}
                                                                                      isMandatory={true}/>
                                                                <InputField label={"Port příchozí zařízení"}
                                                                            name={`${name}.source`} isMandatory={true}
                                                                            inputType={"number"}/>
                                                                <InputField label={"Port odchozí zařízení"}
                                                                            name={`${name}.destination`}
                                                                            isMandatory={true}
                                                                            inputType={"number"}/>
                                                                <InputField label={"Open time"}
                                                                            name={`${name}.openTime`}
                                                                            isMandatory={true}/>
                                                            </div>
                                                        ))}

                                                    </div>

                                                </div>
                                            )


                                        }}
                                    </FieldArray>
                                </div> : <></>
                            }
                        </>
                }]}/>
            </Widget>
        )
    }

    return (
        <Header data={[{
            text: "Turnikety",
            isMain: false,
            link: "/turnstile"
        }, {text: id ? "Upravit turniket" : "Vytvořit turniket", isMain: true}]}>
            <div className={"w-full flex justify-center bg-grey-50"}>
                <Form onSubmit={onSubmit} initialValues={data}
                      mutators={{...arrayMutators}}
                      render={({
                                   handleSubmit, values, form: {
                              mutators: {push, pop, unshift}
                          }, ...props
                               }) => {
                          submit = handleSubmit
                          if (loading) {
                              return (<></>)
                          } else {
                              return (

                                  <div className={"flex flex-col w-full"}>
                                      {_renderBody(values, push)}
                                      <div className={"items-start"}>
                                          <Button text={"Uložit"} onClick={() => submit()} icon={<FiSave/>}
                                                  loading={saving}/>
                                      </div>
                                  </div>
                              )
                          }
                      }}/>
            </div>
        </Header>
    )
}
export default TurnstileForm;
