import React from "react"
import Widget from "../../components/widget";
import Datatable from "../../components/datatable";
import Loader from "../../components/loader";
import {Button} from "../../components/My/Button";
import {EnigooLoader} from "../../components/My/EnigooLoader";

const TableData = ({data, loading}) => {

    if (loading) return <EnigooLoader/>
    if (!data) return null

    const getStatus = (state) => {
      if(state) return <div className={"bg-green-100 text-black rounded text-center font-bold"}>Online</div>
        return <div className={"bg-red-100 text-black rounded text-center font-bold"}>Offline</div>
    }

    let columns = [
            {Header: 'ID', accessor: "id"},
            {Header: 'prefix', accessor: "prefix"},
            {Header: 'name', accessor: "name"},
            {
                Header: 'Online/Offline', accessor: "isOnline",
                Cell: ({cell: {value}}) => <div>{getStatus(value)}</div>
            },
            {
                Header: 'Automatické nahrávání', accessor: "autoPush",
                Cell: ({cell: {value}}) => <div>{value ? "Ano" : "Ne"}</div>
            },
            {
                Header: 'Nahrávání rabbitem', accessor: "isByRabit",
                Cell: ({cell: {value}}) => <div>{value ? "Ano" : "Ne"}</div>
            },

            {
                Header: 'Možnosti', accessor: d => (
                    <Button text={"Upravit"} link={"/subjects/" + d.id}/>
                )
            }
        ]
    ;


    return (
        <Widget>

            <Datatable data={data} columns={columns}/>
        </Widget>
    )
}

export default TableData
