import React from "react";
import {Routes, Route} from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";
import Events from "./pages/Events/Events";
import Users from "./pages/Users/Users";
import Test from "./pages/Test/Test";
import Validation from "./pages/Validation/Validation";
import ActualStateVcb from "./pages/ArealVcb/ActualStateVcb";
import Readers from "./pages/Readers/Readers";
import ReaderDetail from "./pages/Readers/ReaderDetail";
import Subjects from "./pages/Subjects/Subjects";
import SubjectForm from "./pages/Subjects/SubjectForm";
import RulesForm from "./pages/Rules/RulesForm";
import Rules from "./pages/Rules/Rules";
import Connections from "./pages/Connections/Connections";
import ConnectionsForm from "./pages/Connections/ConnectionsForm";
import ArenaEvents from "./pages/ArenaEvents/ArenaEvents";
import Turnstile from "./pages/ENIGOOTurnstiles/Turnstiles/Turnstile";
import Tariff from "./pages/ENIGOOTurnstiles/Tariff/Tariff";
import Device from "./pages/ENIGOOTurnstiles/Device/Device";
import Ticket from "./pages/ENIGOOTurnstiles/Ticket/Ticket";
import Entrance from "./pages/ENIGOOTurnstiles/Entrance/Entrance";
import {useSelector} from "react-redux";
import TicketForm from "./pages/ENIGOOTurnstiles/Ticket/TicketForm";
import TurnstileForm from "./pages/ENIGOOTurnstiles/Turnstiles/TurnstileForm";
import TariffForm from "./pages/ENIGOOTurnstiles/Tariff/TariffForm";
import DeviceForm from "./pages/ENIGOOTurnstiles/Device/DeviceForm";
import AccessUser from "./pages/ENIGOOTurnstiles/AccessUser/AccessUser";
import AccessUserForm from "./pages/ENIGOOTurnstiles/AccessUser/AccessUserForm";
import AccessToken from "./pages/ENIGOOTurnstiles/AccessToken/AccessToken";
import AccessTokenForm from "./pages/ENIGOOTurnstiles/AccessToken/AccessTokenForm";
import OptionsVcb from "./pages/ArealVcb/OptionsVcb";
import Kiosk from "./pages/Kiosk/Kiosk";
import Cron from "./pages/Cron/Cron";
import Zone from "./pages/ENIGOOTurnstiles/Zone/Zone";
import ZoneForm from "./pages/ENIGOOTurnstiles/Zone/ZoneForm";
import EntranceV2 from "./pages/ENIGOOTurnstiles/EntranceV2/EntranceV2";
import ZoneStats from "./pages/ENIGOOTurnstiles/ZoneStats/ZoneStats";
import CheckTicket from "./pages/ENIGOOTurnstiles/CheckTicket/CheckTicket";

const Routing = () => {

    const navigation = useSelector(state => state.navigation)


    const hasRight = (route, key = null) => {
        if (!navigation) return false
        if (route) {
            let exist = navigation[0].items.findIndex(i => i.url === route)
            return exist !== -1
        }
        if (key) {
            let exist = navigation[0].items.findIndex(i => i.key === key)
            return exist !== -1
        }
    }

    return (
        <Routes>
            <Route path={"/dashboard"} element={<Dashboard/>}/>
            <Route path={"/events"} element={hasRight("/events") ? <Events/> : <Denied/>}/>
            <Route path={"/users"} element={hasRight("/users") ? <Users/> : <Denied/>}/>
            <Route path={"/validation"} element={hasRight("/validation") ? <Validation/> : <Denied/>}/>
            <Route path={"/readers"} element={hasRight("/readers") ? <Readers/> : <Denied/>}/>
            <Route path={`/readers/detail/:id`} element={hasRight("/readers") ? <ReaderDetail/> : <Denied/>}/>
            <Route path={`/subjects`} element={hasRight("/subjects") ? <Subjects/> : <Denied/>}/>
            <Route path={`/subjects/:id`} element={hasRight("/subjects") ? <SubjectForm/> : <Denied/>}/>
            <Route path={`/subjects/add`} element={hasRight("/subjects") ? <SubjectForm/> : <Denied/>}/>

            <Route path={`/rules`} element={hasRight("/rules") ? <Rules/> : <Denied/>}/>
            <Route path={`/rules/add`} element={hasRight("/rules") ? <RulesForm/> : <Denied/>}/>
            <Route path={`/rules/:id`} element={hasRight("/rules") ? <RulesForm/> : <Denied/>}/>

            <Route path={`/connections`} element={hasRight("/connections") ? <Connections/> : <Denied/>}/>
            <Route path={`/connections/add`} element={hasRight("/connections") ? <ConnectionsForm/> : <Denied/>}/>
            <Route path={`/connections/:id`} element={hasRight("/connections") ? <ConnectionsForm/> : <Denied/>}/>

            <Route path={`/arena-events`} element={<ArenaEvents/>}/>

            <Route path={`/turnstile`} element={hasRight(null, "enigooTurnikets") ? <Turnstile/> : <Denied/>}/>
            <Route path={`/turnstile/detail/:id`} element={hasRight(null, "enigooTurnikets") ? <TurnstileForm/> : <Denied/>}/>
            <Route path={`/turnstile/add/:unknownId`} element={hasRight(null, "enigooTurnikets") ? <TurnstileForm/> : <Denied/>}/>
            <Route path={`/turnstile/add`} element={hasRight(null, "enigooTurnikets") ? <TurnstileForm/> : <Denied/>}/>

            <Route path={`/tariff`} element={hasRight(null, "enigooTurnikets") ? <Tariff/> : <Denied/>}/>
            <Route path={`/tariff/detail/:id`} element={hasRight(null, "enigooTurnikets") ? <TariffForm/> : <Denied/>}/>
            <Route path={`/tariff/add`} element={hasRight(null, "enigooTurnikets") ? <TariffForm/> : <Denied/>}/>

            <Route path={`/device`} element={hasRight(null, "enigooTurnikets") ? <Device/> : <Denied/>}/>
            <Route path={`/device/detail/:id`} element={hasRight(null, "enigooTurnikets") ? <DeviceForm/> : <Denied/>}/>
            <Route path={`/device/add`} element={hasRight(null, "enigooTurnikets") ? <DeviceForm/> : <Denied/>}/>

            <Route path={`/ticket`} element={hasRight(null, "enigooTurnikets") ? <Ticket/> : <Denied/>}/>
            <Route path={`/ticket/check`} element={hasRight(null, "enigooTurnikets") ? <CheckTicket/> : <Denied/>}/>
            <Route path={`/ticket/add`} element={hasRight(null, "enigooTurnikets") ? <TicketForm/> : <Denied/>}/>
            <Route path={`/ticket/detail/:id`} element={hasRight(null, "enigooTurnikets") ? <TicketForm/> : <Denied/>}/>

            {/*
                TODO: Vymazat - stará implementace*/}
                <Route path={`/entrance-old`} element={hasRight(null, "enigooTurnikets") ? <Entrance/> : <Denied/>}/>


            <Route path={`/entrance`} element={hasRight(null, "enigooTurnikets") ? <EntranceV2/> : <Denied/>}/>


            {/*TODO: Vymazat - postrádá smysl - uživatelé v enigoo službě se již nepoužívají
            <Route path={`/access/user`} element={hasRight(null, "enigooTurnikets") ? <AccessUser/> : <Denied/>}/>
            <Route path={`/access/user/detail/:id`}
                   element={hasRight(null, "enigooTurnikets") ? <AccessUserForm/> : <Denied/>}/>
            <Route path={`/access/user/add`}
                   element={hasRight(null, "enigooTurnikets") ? <AccessUserForm/> : <Denied/>}/>
             */}

            <Route path={`/access/token`} element={hasRight(null, "enigooTurnikets") ? <AccessToken/> : <Denied/>}/>
            <Route path={`/access/token/detail/:id`}
                   element={hasRight(null, "enigooTurnikets") ? <AccessTokenForm/> : <Denied/>}/>
            <Route path={`/access/token/add`}
                   element={hasRight(null, "enigooTurnikets") ? <AccessTokenForm/> : <Denied/>}/>

            <Route path={`/zone/stats`} element={hasRight(null, "enigooTurnikets") ? <ZoneStats/> : <Denied/>}/>

            <Route path={`/zone`} element={hasRight(null, "enigooTurnikets") ? <Zone/> : <Denied/>}/>
            <Route path={`/zone/add`} element={hasRight(null, "enigooTurnikets") ? <ZoneForm/> : <Denied/>}/>
            <Route path={`/zone/detail/:id`} element={hasRight(null, "enigooTurnikets") ? <ZoneForm/> : <Denied/>}/>


            <Route path={"/test"} element={<Test/>}/>
            <Route path={"/vcb/actual"} element={<ActualStateVcb/>}/>
            <Route path={"/vcb/options"} element={<OptionsVcb/>}/>

            <Route path={`/kiosk-log`} element={hasRight("/kiosk-log") ? <Kiosk/> : <Denied/>}/>
            <Route path={`/cron`} element={hasRight("/cron") ? <Cron/> : <Denied/>}/>
        </Routes>
    );
};

const Denied = () => {
    return (<div>Přístup zamítnut.</div>)
}

export default Routing;
