import React, {useEffect, useState} from "react"
import {apiGetActualStateVcb} from "./Actions";
import Widget from "../../components/widget";
import {Form} from "react-final-form";
import {Button} from "../../components/My/Button";
import moment from "moment";
import CheckboxField from "../../FinalForm/CheckboxField";
import SettingsVcb from "./Settings/SettingsVcb";

const OptionsVcb = () => {

    let [loading, setLoading] = useState(false)
    let [data, setData] = useState(null)

    useEffect(() => {

    }, [])



    const _renderCron = () => {

    }

    return (
        <>
            <h3>Nastavení</h3>
            <SettingsVcb />
        </>
    )

}

export default OptionsVcb
