import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import Toast from "cogo-toast";
import Datatable from "../../../components/datatable";
import {apiDeleteTurnstile} from "./Actions";
import {FiPlus} from "react-icons/fi";

const UnknownTurnstileTable = ({data, setData}) => {

    let tableData = React.useMemo(() => {
        return data;
    }, [data])

    let user = useSelector((state) => state.user)

    const deleteItem = (id) => {
        apiDeleteTurnstile(user.activeAreal.id, id, (response) => {
            let array = [...data.unknownTurnstile];
            array = array.filter((val) => val.id !== id);
            setData(array);
            Toast.success("Turniket byl odstraněn");
        }, (error) => {
            let errorMessage = "Nepodařilo se odstranit turniket.\n"
            switch (error.data) {
                case "TURNSTILE_NOT_FOUND":
                    errorMessage += "Nebyl nalezen.";
                    break;
                default:
                    errorMessage += "Neznámá chyba";
                    break;
            }
            Toast.error(errorMessage);
        })

    }

    let columns = React.useMemo(() => [
        {Header: '#ID', accessor: 'id'},
        {Header: 'IP adresa', accessor: 'ipAddress'},
        {Header: 'MAC adresa', accessor: 'macAddress'},
        {Header: 'Poslední akce',accessor: 'lastAction'},
        {
            Header: "Možnosti", accessor: d => (
                <div className={"flex flex-row"}>
                    <Link to={"/turnstile/add/"+d.id}>
                        <button
                            className={"btn btn-default rounded btn-icon bg-blue-500 hover:bg-blue-400 space-x-2 mt-1 px-5 text-white "}>
                            <FiPlus size={20}/>
                        </button>
                    </Link>
                </div>
            )
        }
    ])

    return <Datatable data={tableData} columns={columns} selectable={false}/>
}

export default UnknownTurnstileTable;
