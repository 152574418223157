import React, {useEffect} from "react";
import SelectField from "../SelectField";

const LogMessageSelectField = ({disabled=false,onChange=null,onEnter=null,name="message"}) =>{

    let data = [
        {label:"Úspěšný vchod",value:"ENTRY_SUCCESS"},
        {label:"Úspěšný východ",value:"EXIT_SUCCESS"},
        {label:"Úspěšný průchod v areálu",value:"ENTRY_IN_AREA"},
        {label:"Vstupenka nenalezena",value:"NOT_FOUND"},
        {label:"Již vstoupil",value:"IN_ZONE"},
        {label:"Ještě nevstoupil",value:"NOT_IN_ZONE"},
        {label:"Vstupenka vyčerpána",value:"CARD_USED"},
        {label:"Špatná zóna",value:"INCORRECT_ZONE"},
        {label:"Neznámý turniket",value:"UNKNOWN_TURNSTILE"},
    ];

    useEffect(()=>{
    },[])

    const getData = () =>{
    }


    return(
        <SelectField onEnter={onEnter} onChange={onChange} disabled={disabled} placeholder={"Výsledek"} name={name} options={data} label={"Výsledek"} isMulti={true}/>
    )
}

export default LogMessageSelectField;
