import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { FiUpload } from 'react-icons/fi';

const FileField = (props) => {
    const onDrop = React.useCallback((acceptedFiles) => {
         props.onChange(acceptedFiles[0]);
    }, [props]);

    const {acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, maxFiles: 1 });

    const files = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));

    return (
        <Field {...props} >
            {({ input, meta }) => (
                <section className={"flex flex-col items-center p-5 bg-gray-100 space-y-5"}>
                    <div {...getRootProps(
                        {className: 'dropzone w-full h-48 p-6 border-2 border-dashed border-gray-400 rounded-md text-center cursor-pointer hover:bg-gray-400 flex items-center justify-center'})}>
                        <input {...getInputProps()}/>
                        <h6 className={"flex-center"}>Přesuňte soubor zde nebo klikněte pro výběr.</h6>
                    </div>

                    {files.length>0 && <aside className={"w-full"}>
                        <h6 className={"mb-2 font-light text-gray-800"}>Soubor</h6>
                        <ol className={"list-decimal pl-5"}>{files}</ol>
                    </aside>}
                    {meta.error && meta.touched && <div className="text-red-500"><FiUpload /> {meta.error}</div>}
                </section>
            )}
        </Field>
    );
};

FileField.propTypes = {
    onChange: PropTypes.func.isRequired,
};

export default FileField;
