import React, {useEffect, useState} from "react"
import TableData from "./TableData";
import Widget from "../../components/widget";
import {Button} from "../../components/My/Button";
import {apiGetTurniketRules} from "./Actions";
import DeletingModal from "../../FinalForm/api/DeletingModal";
import Toast from "cogo-toast";

const Subjects = () => {

    let [loading, setLoading] = useState(false)
    let [data, setData] = useState(null)
    let [showDeleteModal, setShowDeleteModal] = useState(false)
    let [itemData, setItemData] = useState(null)


    useEffect(() => {
       if (showDeleteModal === false){
           getRules();
       }
    }, [showDeleteModal])

    const getRules = () => {
        setLoading(true)
        apiGetTurniketRules((data) => {
            setData(data)
            setLoading(false)
        }, (err) => {
            setLoading(false)
            Toast.warn("Došlo k chybě. Opakujte akci znovu.")
        })
    }


    return (
        <>
            <Widget>
                <div className={"flex flex-row text-black"}>
                    <h5 className={"align-middle"}>Pravidla turniketů</h5>
                    <span className="ml-auto"></span>
                    <div className={"flex"}>
                        <Button text={"Přidat pravidlo"} link={"/rules/add"}/>
                    </div>
                </div>
            </Widget>
            <TableData data={data} loading={loading} prepareItem={(item) => {
                setShowDeleteModal(true)
                setItemData(item)
            }}/>
            <DeletingModal
                visible={showDeleteModal}
                item={itemData}
                accept={() => {
                    setShowDeleteModal(false)
                }}
                close={() => {
                    setShowDeleteModal(false)
                }}
                title={"Odstranění subjektu"}
                text={"Přejete si smazat pravidlo?"}
                url={"/api/v2/turniket/rule"}
            />
        </>
    )
}

export default Subjects
