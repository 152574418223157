import React from "react";
import InputField from "../../FinalForm/InputField";
import {FieldArray} from 'react-final-form-arrays'
import {Button} from "../../components/My/Button";

const FieldArrayRules = ({name, label, subCategory, push, buttonText}) => {

    return (
        <div className={"w-full"}>
            <div className="buttons w-full">
                <Button
                    onClick={() => push(`${name}`, undefined)}
                    color={"bg-purple-500"}
                    text={buttonText}
                    type={"button"}
                />
            </div>
            <div className={"w-full flex flex-wrap"}>
                <FieldArray name={`${name}`}>
                    {({fields}) =>
                        <div className={"w-full flex flex-wrap"}>
                            {fields.map((name, index) => (
                                <div key={name} className={"lg:w-1/6 md:w-1/3 flex items-center"}>
                                    <div className={"w-full"}>
                                        <InputField
                                            inputType={"number"}
                                            label={label + ' ' + (index + 1)}
                                            name={`${name}.id`}
                                            isMandatory={true}
                                        />
                                    </div>
                                    <span
                                        onClick={() => {
                                            fields.remove(index)
                                        }}
                                        style={{cursor: 'pointer'}}>❌</span>
                                </div>
                            ))}
                        </div>
                    }
                </FieldArray>
            </div>
        </div>
    )
}

export default FieldArrayRules
