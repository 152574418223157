import React, {useState} from "react"

import Datatable from "../../components/datatable";
import Loader from "../../components/loader";
import Widget from "../../components/widget";
import {FcCancel, FcOk} from "react-icons/fc";
import {Button} from "../../components/My/Button";
import Toast from "cogo-toast";
import {apiImportEvent} from "./Actions";

const TableDataServices = ({data, setItem, loading}) => {

   if(loading) return <Loader/>
   if (!data) return null

   const startImport = (item) => {
      apiImportEvent({subjectId: item.subjectId, eventId: item.subactionId}, data => {
         Toast.success("Import dokončen.")
      }, err => {
         console.log(err)
      })

   }

   let columns = [
      {Header: '#', accessor: "subactionId"},
      {Header: 'Subjekt', accessor: "subjectName"},
      {Header: 'Událost', accessor: "eventName"},
      {Header: 'Termín', accessor: "eventDate"},
      {
         Header: 'Joby', accessor: d => (
            d.subjectJobs ? <div>{d.subjectJobs.map(job => job.name).join(', ')}</div> : <div>"-"</div>
         )
      },
      {Header: 'Importováno', accessor: d => (
            d.imported ? <div><FcOk size={20}/></div> : <Button text={"Importovat"} loading={loading} color={"bg-red-500"} onClick={()=>startImport(d)}/>
         )},

   ];


   return (
      <Widget title={"Dnešní události"}>
         <Datatable data={data} columns={columns} showPagination={data.length>=10}/>
      </Widget>
   )
}

export default TableDataServices
