import React from "react";
import { Field } from "react-final-form";
import PropTypes from "prop-types"
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import cs from 'date-fns/locale/cs';
import {FiArrowUp} from "react-icons/fi";
registerLocale('cs', cs)


const DateField = (props) => {


    const format = (date) => {
        if (!date) return null
        if (props.withTime) return date.format("DD.MM.YYYY HH:mm")
        return date.format("DD.MM.YYYY")
    }

    const defaultTime = (onChange) => {
        let date = new Date()
        if (props.midnight) {
            date.setHours(23)
            date.setMinutes(59)
        } else if (!props.actual) {
            date.setHours(2)
            date.setMinutes(0)
            date.setSeconds(0)
        }

        //console.log("PST:", moment(date).tz("Europe/Prague").format("YYYY-MM-DD HH:mm:ss"));
        //console.log(date.toUTCString())
        onChange(date)

    }

    const errorMessage = (message) => {
        return <div className={"flex flew-row text-red-500"} data-test="errorMes"><FiArrowUp/> {message}</div>;
    }

    const renderError = (_props) => {
        if (_props.meta.error && _props.meta.touched) {
            if (!_props.input.value || _props.input.value === "") return "Povinné pole"
        }
        return null;
    }

    return <Field {...props}
                  validate={
                      value => {
                          if (props.isMandatory && !value) return true;
                      }
                  }
                  parse={(value) => {
                      //console.log("PARSE " + value)
                      return value
                  }}
                  format={
                      (value) => {
                          //console.log("FORMAT " + value)
                          return value
                      }
                  }
                  render={data => {
                      return (
                          <div className={"form-element w-full p-1"}>
                              <div className={"form-label font-bold"}>{props.label}{props.isMandatory && "*"}</div>
                              <DatePicker dateFormat="dd.MM.yyyy"
                                          onFocus={() => {
                                              if (!data.input.value) defaultTime(data.input.onChange)
                                          }}
                                          selected={data.input.value} locale={cs}
                                          isClearable={props.withDelete}
                                          customInput={
                                              <input onClick={data.input.onChange} className={"form-input"}
                                                     data-test={props.dataTest}/>
                                          }
                                          onChange={(date) => data.input.onChange(date)}/>
                              <div className={"text-sm pt-1"}> {renderError(data)} </div>
                          </div>
                      )
                  }}
    />

}


DateField.defaultProps = {
    withTime: false,
    isMandatory: false,
    midnight: false,
    withDelete: true
}

DateField.propTypes = {
    label: PropTypes.string,
    withDelete: PropTypes.bool,
    midnight: PropTypes.bool,
    actual: PropTypes.bool,
    isMandatory: PropTypes.bool,
    dataTest: PropTypes.string,

}

export default DateField;
