import React from "react"
import Widget from "../../components/widget";
import Datatable from "../../components/datatable";
import {Button} from "../../components/My/Button";
import Loader from "../../components/loader";
import {EnigooLoader} from "../../components/My/EnigooLoader";

const TableData = ({data, loading}) => {


    if (loading) return <EnigooLoader/>
    if (!data) return null
    //const navigate = useNavigate()


    let columns = [
            {Header: 'ID', accessor: "id"},
            {Header: 'UID', accessor: "uid"},
            {
                Header: 'Model', accessor: "model",
                Cell: ({cell: {value}}) => <div>{value}</div>
            },
            // {
            //     Header: 'Poslední akce', accessor: "lastAction",
            //     Cell: ({cell: {value}}) => <div>{value && moment(value).format("DD.MM.YYYY HH:mm:ss")}</div>
            // }
            {
                Header: 'ActualPrefix', accessor: "actualPrefix",
                Cell: ({cell: {value}}) => <div>{value}</div>
            },
            {
                Header: 'Možnosti', accessor: d => (
                    <Button text={"Upravit"} link={"/readers/detail/"+ d.id}/>
                )
            }
        ]
    ;


    return (
        <Widget>
            <Datatable data={data} columns={columns}/>
        </Widget>
    )
}

export default TableData
