import {instance} from "../../config/axios-cnf";


export const apiGetDashboard = (callback, error) => {
    instance.get("/api/v2/dashboard")
        .then(response => callback(response.data))
        .catch(err => error(err))
}

export const apiImportEvent = (data, callback, error) => {
    instance.post("/api/v2/event", data)
            .then(response => callback(response.data))
            .catch(err => error(err))
}