import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import Toast from "cogo-toast";
import {DefaultLoader} from "../../../components/DefaultLoader";
import Widget from "../../../components/widget";
import {IconTabs} from "../../../components/tabs";
import InputField from "../../../FinalForm/InputField";
import SelectField from "../../../FinalForm/SelectField";
import {Form} from "react-final-form";
import {Button} from "../../../components/My/Button";
import {apiGetTariff, apiPostCreateTariff, apiPostTariff, apiPutUpdateTariff} from "./Actions";
import {FiInfo, FiSave} from "react-icons/fi";
import Header from "../../../components/My/Header";
import {apiPostTurnstile} from "../Turnstiles/Actions";


const TariffForm = () => {
    let [loading, setLoading] = useState(false);
    let [saving, setSaving] = useState(false);
    let [data, setData] = useState(null);
    let user = useSelector((state) => state.user)


    const {id} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        if (id) {
            setLoading(true);
            apiGetTariff(user.activeAreal.id, id, (data) => {
                setData(data);
                setLoading(false);
            }, (err) => {
                setLoading(false);
                handleError(err);
                navigate("/tariff");
            })
        }
    }

    const handleError = (err) => {
        let error = "Nepodařilo se načíst data o tarifu.\n";
        switch (err.data) {
            case "TARIFF_NOT_FOUND":
                error += "Tarif nebyl nalezen.";
                break;
            case "UNKNOWN_ERROR":
            default:
                error += "Neznámá chyba.";
        }
        Toast.error(error);
    }

    const onSubmit = (values) => {
        setSaving(true);
        let newValues = values
        if (id) newValues = {...values, tariffId: id};

        apiPostTariff(user.activeAreal.id, newValues, (data) => {
            setData(data);
            setSaving(false);
            navigate("/tariff/detail/" + data.id);
            Toast.success("Úspěšně uloženo.");
        }, (err) => {
            setSaving(false);
            handleError(err);
        })
    }

    const _renderBody = (values) => {
        if (loading) return <DefaultLoader text={"Načítám data tarifu..."}/>;
        else return <Widget><IconTabs tabs={[{
            index: 0,
            title: (<><FiInfo size={18} className={"stroke-current"}/><span
                className={"ml-2"}>Základní informace</span> </>),
            content: <>
                <InputField label={"Název (25)"} name={"name"} isMandatory={true} inputType={"text"}/>

                <SelectField name={"reverse"} options={[
                    {label: "ANO", value: 0}, {label: "NE", value: 1}]} label={"Musí nejdříve vstoupit"} isMandatory={true}
                             placeholder={"Umožňuje tarif reverzní průchody"}/>
                <SelectField name={"daily"} options={[
                    {label: "ANO", value: 1}, {label: "NE", value: 0}
                ]} label={"Prochází denně (permanentka)"} isMandatory={true} placeholder={"Počítat průchody za den"}/>
            </>

        }]}/>
        </Widget>
    }

    return (
        <Header data={[{text: "Tarify", isMain: false, link: "/tariff"}, {text: id ? "Upravit tarif" : "Vytvořit tarif", isMain: true}]}>
            <Form onSubmit={onSubmit} initialValues={data}
                  render={({handleSubmit, values, ...props}) => {
                      return (
                          <div className={"flex flex-col w-full"}>
                              {_renderBody(values)}
                              <div className={"items-start"}>
                              <Button text={"Uložit"} onClick={() => handleSubmit()} icon={<FiSave/>} loading={saving}/>
                              </div>
                          </div>
                      )
                  }}/>
        </Header>
    )
}
export default TariffForm;
