import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import Toast from "cogo-toast";
import {DefaultLoader} from "../../../components/DefaultLoader";
import Widget from "../../../components/widget";
import {IconTabs} from "../../../components/tabs";
import InputField from "../../../FinalForm/InputField";
import SelectField from "../../../FinalForm/SelectField";
import {Form} from "react-final-form";
import {Button} from "../../../components/My/Button";
import {apiGetToken, apiPostGenerateToken, apiPutUpdateToken} from "./Actions";
import DateTimeField from "../../../FinalForm/DateTimeField";
import moment from "moment";
import {FiInfo, FiSave} from "react-icons/fi";

const AccessTokenForm = () => {

    let [loading, setLoading] = useState(false);
    let [saving, setSaving] = useState(false);
    let [data, setData] = useState(null);

    const {id} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        getData();
    }, []);

    let user = useSelector((state) => state.user)

    const getData = () => {
        if (id) {
            setLoading(true);
            apiGetToken(user.activeAreal.id,id, (data) => {
                setData(data);
                setLoading(false);
            }, (err) => {
                setLoading(false);
                handleError(err);
                navigate("/access/token");
            })
        }
    }

    const handleError = (err) => {
        let error = "Nepodařilo se načíst data o tokenu.\n";
        if (typeof err.data === "string" || err.data instanceof String) {
            switch (err.data) {
                case "TOKEN_NOT_FOUND":
                    error += "Token nebyl nalezen.";
                    break;
                case "DATE_FORMAT_INVALID":
                    error += "Špatný formát data.";
                    break;
                case "UNKNOWN_ERROR":
                default:
                    error += "Neznámá chyba.";
            }
        } else {
            if (err.data.dateFrom) {
                error += " Datum od";
                error += getErrorMessage(err.data.password);
            }
            if (err.data.dateTo) {
                error += " Datum do";
                error += getErrorMessage(err.data.email);
            }
            if (err.data.name) {
                error += " Jméno";
                error += getErrorMessage(err.data.name);
            }
            if (err.data.competency) {
                error += " Oprávnění";
                error += getErrorMessage(err.data.competency);
            }

        }
        Toast.error(error);
    }

    const getErrorMessage = (type) => {
        switch (type) {
            case "INVALID_LENGTH":
                return " má nepovolenou délku."
            case "NULL":
            case "EMPTY":
                return " je prázdný/é.";
            default:
                return " neznámá chyba.";
        }
    }

    const onSubmit = (values) => {
        setSaving(true);
        if (id) {
            apiPutUpdateToken(user.activeAreal.id,id, {
                ...values, dateFrom: convertDate(values.dateFrom),
                dateTo: convertDate(values.dateTo)
            }, (data) => {
                setData(data);
                setSaving(false);
                Toast.success("Úspěšně uloženo.");
            }, (err) => {
                setSaving(false);
                handleError(err);
            })
        } else {
            apiPostGenerateToken(user.activeAreal.id,{
                ...values, dateFrom: convertDate(values.dateFrom),
                dateTo: convertDate(values.dateTo)
            }, (data) => {
                setData(data);
                setSaving(false);
                navigate("/access/token/detail/" + data.id);
                Toast.success("Úspěšně uloženo.");
            }, (err) => {
                setSaving(false);
                handleError(err);
            })
        }
    }

    const convertDate = (date) => {
        if (date) {
            let dateInString = date.replace('T', " ");
            dateInString += ":00";
            return dateInString;
        } else {
            return null;
        }
    }

    const _renderBody = (values) => {
        if (loading) return <DefaultLoader text={"Načítám data tokenu..."}/>;
        else return <Widget><IconTabs tabs={[{
            index: 0,
            title: (<><FiInfo size={18} className={"stroke-current"}/><span
                className={"ml-2"}>Základní informace</span> </>),
            content: <>
                <InputField label={"Token"} name={"token"} maxLength={50}
                            isMandatory={false} disabled={true} placeholder={"Bude vygenerováno"} inputType={"text"}/>
                <InputField label={"Jméno"} name={"name"} maxLength={50}
                            isMandatory={true} inputType={"text"}/>
                <DateTimeField name={"dateFrom"} isMandatory={false} valueToCompare={values.dateTo}
                               validateFun={(date1, date2) => {
                                   if (moment(date1).isAfter(moment(date2))) {
                                       return "Datum od nemůže být po datu do"
                                   } else {
                                       return "";
                                   }
                               }} label={"Datum od"} type={"from"} placeholder={"Datum od"}
                               showTimeSelect={true}/>
                <DateTimeField name={"dateTo"} isMandatory={false} valueToCompare={values.dateFrom}
                               validateFun={(date1, date2) => {
                                   if (moment(date2).isAfter(moment(date1))) {
                                       return "Datum od nemůže být po datu do"
                                   } else {
                                       return "";
                                   }
                               }} label={"Datum do"} type={"to"} placeholder={"Datum do"} showTimeSelect={true}/>
                <SelectField name={"competency"} options={[{label: "Plná práva", value: "full"},
                    {label: "Externí přístup", value: "external"},
                    {label: "Čtečky", value: "reader"}
                ]} label={"Právo"} isMandatory={true}/>
            </>

        }]}/>
        </Widget>
    }

    return (
        <div className={"w-full flex justify-center bg-grey-50"}>
            <Form onSubmit={onSubmit} initialValues={data}
                  render={({handleSubmit, values, ...props}) => {
                      return (
                          <div className={"flex flex-col w-full items-center"}>
                              <Widget>
                                  <div className={"flex flex-row text-black"}>
                                      <h5 className={"align-middle"}>Nový token</h5>
                                      <span className="ml-auto"></span>
                                      <div className={"flex"}>
                                          <Button text={"Uložit"} onClick={() => handleSubmit()} icon={<FiSave/>}
                                                  loading={saving}/>
                                      </div>
                                  </div>
                              </Widget>
                              {_renderBody(values)}
                          </div>
                      )
                  }}/>

        </div>
    )

}
export default AccessTokenForm;
