import React, {useEffect, useState} from "react";
import {apiGetTariff} from "../../pages/ENIGOOTurnstiles/Tariff/Actions";
import SelectField from "../SelectField";
import SelectMultiField from "../SelectMultiField";
import {useSelector} from "react-redux";

const ApiTariffSelectField = ({
                                  disabled = false,
                                  isMulti = true,
                                  onChange = null,
                                  isMandatory = false,
                                  onEnter = null,
                                  name = "tariff"
                              }) => {

    let [data, setData] = useState([]);
    let [loading, setLoading] = useState(false);
    let user = useSelector((state) => state.user)


    useEffect(() => {
        user.activeAreal && getData();
    }, [user.activeAreal])

    const getData = () => {
        setLoading(true);
        apiGetTariff(user.activeAreal.id, null, (data) => {
            setData(data);
            setLoading(false);
        }, (error) => {
            setData([]);
            setLoading(false);
        })
    }

    const getOptions = () => {
        if(!data){
            return []
        }
        return data?.map((item, index) => {
            return {label: item.name, value: item.id}
        })
    }
    if (isMulti) {
        return (
            <SelectField onEnter={onEnter} isMandatory={isMandatory} disabled={disabled}
                              placeholder={loading ? "Načítám data.." : "Tarif"} name={name}
                              options={loading ? [] : getOptions()} label={"Tarify"} isMulti={true} onChange={onChange}/>
        )
    } else {
        return (
            <SelectField onEnter={onEnter} isMandatory={isMandatory} name={name} options={loading ? [] : getOptions()}
                         label={"Tarif"} isMulti={false} onChange={onChange}/>
        )
    }
}

export default ApiTariffSelectField;
