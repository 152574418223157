import dayGridPlugin from "@fullcalendar/daygrid";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import csLocale from "@fullcalendar/core/locales/cs";
import interactionPlugin from "@fullcalendar/interaction";
import React, {useState} from "react";
import "./styles.css"
import {millisecondsToHours} from "date-fns";


export const Calendar = ({events}) => {
   const [_events, _setEvents] = useState(events)

   return (
      <FullCalendar
         plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
         initialView={"dayGridMonth"}
         headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth timeGridWeek"
         }}
         events={_events}
         eventClick={function (info) {
            console.log("event: ", info.event._def)
         }}
         eventTimeFormat={{
            hour: "numeric",
            minute: "2-digit",
            omitZeroMinute: false,
            meridiem: false,
            hour12: false,
         }}
         eventDisplay={"block"}
         displayEventEnd={true}
         eventContent={(arg) => {
            return (
               <>
                  <div
                     className={"fc-event-time"} >{arg.event.extendedProps.halfDay ? arg.timeText : ""}</div >
                  <div className={"fc-event-title-container"} >
                     <div
                        className={"fc-event-title fc-sticky"} >{arg?.event?.extendedProps?.name}{arg?.event?.extendedProps?.halfDay ? " - půl den" : arg.event.title}</div >
                  </div >
               </>
            )
         }}
         slotLabelFormat={{
            hour: "2-digit",
            minute: "2-digit",
            omitZeroMinute: false,
            meridiem: "narrow",
            hour12: false,
         }}
         weekends={false}
         showNonCurrentDates={true}
         slotEventOverlap={false}
         eventBackgroundColor={"#3b82f6"}
         locales={[csLocale]}
         selectable={true}
         select={(arg) => {
            const title = prompt("Kdo si bere dovolenou?");
            if (title) {
               if (millisecondsToHours(arg.end - arg.start) <= 24) {
                  const isHalfDay = window.confirm("Bereš si půl den volna?");
                  const isHalfDayTime = isHalfDay ? window.confirm("Bereš si půl den volna ráno(ok) nebo odpoledne(zrušit)?") : null;

                  const newEvent = {
                     name: title,
                     start: isHalfDay ? (isHalfDayTime ? arg.start.setHours(8) : arg.start.setHours(12)) : arg.start,
                     end: isHalfDay ? (isHalfDayTime ? arg.start.setHours(12) : arg.start.setHours(16)) : arg.end,

                     ...(isHalfDay ? {halfDay: true} : {})
                  }
                  _setEvents([..._events, newEvent])

               } else {
                  const newEvent = {
                     title,
                     start: arg.start,
                     end: arg.end
                  }
                  _setEvents([..._events, newEvent])

               }
            }

         }}
      />
   )
}