import {instance, onErrorFunction} from "../../config/axios-cnf";


export const apiGetConnections = (callback, error) => {
    instance.get(`/api/v2/connections`)
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}
export const apiCreateConnection = (values, callback, error) => {
    instance.put(`/api/v2/connections`, values)
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}
export const apiUpdateConnection = (values, callback, error) => {
    instance.post(`/api/v2/connections`, values)
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}
export const apiGetConnectionById = (id, callback, error) => {
    instance.get(`/api/v2/connections/${id}`)
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}