import React from "react";
import { Provider } from "react-redux";
import {PersistGate} from "redux-persist/integration/react";

import Token from "./Token";
import {store,persistor} from "./store";



function App() {

     return (
         <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                 <Token />
             </PersistGate>
         </Provider>
     );
}

export default App;
