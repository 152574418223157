import {combineReducers} from 'redux'
import config from './config'
import leftSidebar from './left-sidebar'
import palettes from './palettes'
import navigation from './navigation'
import user from './user'
import filterEntrance from "./filterEntrance";
import filterTicket from "./filterTicket";
import pages from "./pages";



export const rootReducer = combineReducers({
  navigation,
  config,
  leftSidebar,
  palettes,
  user,
  filterEntrance,
  filterTicket,
  pages
})


