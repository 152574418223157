import React, {useState} from "react"
import Widget from "../../components/widget";
import Datatable from "../../components/datatable";
import {EnigooLoader} from "../../components/My/EnigooLoader";
import moment from "moment/moment";

const TableData = ({data, setItem, loading}) => {


    if(loading) return <EnigooLoader/>
    if (!data) return null

    let columns = [
        {Header: 'ID', accessor: "id"},
        {Header: 'Datum', accessor: "date",
            Cell: ({cell: {value}}) => <div>{value && moment(value).format("DD.MM.YYYY HH:mm:ss")}</div>},
        {Header: 'Název zařízení', accessor: "deviceName"},
        {Header: 'Text data', accessor:"textData"}
    ];


    return (
        <Widget>
            <Datatable data={data} columns={columns}/>
        </Widget>
    )
}

export default TableData
