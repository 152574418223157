import React, {useState} from "react";
import {instance} from "../../config/axios-cnf";
import Toast from "cogo-toast";
import Modal from "../../components/My/Modal";
import {Form} from "react-final-form";
import {Button} from "../../components/My/Button";

const DeletingModal = ({visible,close, accept, item, title, text, url }) => {
    let submit
    let [loading, setLoading] = useState(false)

    const submitForm = (values) => {
        setLoading(true)
        apiDeleteSubject(item, url,()=>{
            Toast.success("Úspěšně odstraněno")
            close(true)
            setLoading(false)
        }, error => {
           switch (error.error.type){
               case "SUBJECT_NOT_EXIST":
                   Toast.error(" Subjekt neexistuje.")
                   close(true)
                   break;
               default:
                   Toast.error(" Nastala neočekávaná chyba.")
           }
           setLoading(false)
        })
    }

    return (
        <>
            <Modal title={title}
                   body={
                       <Form
                           onSubmit={submitForm}
                           render={({handleSubmit, errors}) => {
                               submit = handleSubmit
                               return (
                                   <>
                                       <p>{text}</p>
                                   </>
                               )
                           }}/>
                   }
                   visible={visible}
                   close={() => close()}
                   footer={<>
                       <Button text={"Potvrdit odstranění"} onClick={() => submit()} loading={loading}/>
                       <Button text={"Zrušit"} onClick={() => close()}/>
                   </>
                   }
            />
        </>
    )

}

export const apiDeleteSubject = (item, url, callback, error) => {
    instance.delete(url+`/${item.id}`)
        .then(response => callback(response.data))
}

export default DeletingModal