import React, {useEffect, useState} from "react"
import {apiGetActualStateVcb} from "./Actions";
import Widget from "../../components/widget";
import {Form} from "react-final-form";
import DateTimeField from "../../FinalForm/DateTimeField";
import {Button} from "../../components/My/Button";
import moment from "moment";
const FileDownload = require('js-file-download');

const ActualStateVcb = () => {

    let [loading, setLoading] = useState(false)
    let [data, setData] = useState(null)

    useEffect(() => {
        getData()
    }, [])

    const getData = (dateValue = null, type = "JSON") => {
        setData(null)
        setLoading(true)
        apiGetActualStateVcb({date: dateValue, type: type}, data => {
            if(type === "JSON"){
                setData(data)
                setLoading(false)
            }else {
                var blob = new Blob([data.data], {type: "application/vnd.ms-excel"});
                FileDownload(blob, data.headers['content-disposition'].replace('attachment; filename=', ''))
                setLoading(false)
            }

        }, error => {
            setLoading(false)
            setData(null)
        })
    }

    const getDate = () => {
        if (!data) return null
        return moment(data.date).format('DD. MM. YYYY')
    }

    const getBody = () => {
        if (loading) return "Načítám data"
        if (!data) return null
        if (data) {
            return (
                <>
                    <div className={"w-full flex flex-row"}>
                        <div className={"flex w-1/2"}>
                            <Widget>
                                <h5>Načtených vstupenek</h5>
                                <h1>{data?.total?.in}</h1>
                            </Widget>
                        </div>
                        <div className={"flex w-1/2"}>
                            <Widget>
                                <h5>Načtených výstupů</h5>
                                <h1>{data?.total?.out}</h1>
                            </Widget>
                        </div>
                    </div>
                    <h5>Dle tarifů</h5>
                    <Widget>

                        <table className={"table table-striped"}>
                            <thead>
                            <th>Název</th>
                            <th>Počet</th>
                            </thead>
                            <tbody>
                            {data?.byTariff.map(item => (
                                <tr>
                                    <td>{item.id}</td>
                                    <td>{item.count}</td>
                                </tr>)
                            )}
                            </tbody>
                        </table>
                    </Widget>
                    <h5>Dle kategorií</h5>
                    <Widget>

                        <table className={"table table-striped"}>
                            <thead>
                            <th>Název kategorie</th>
                            <th>Název slevy</th>
                            <th>Počet</th>
                            </thead>
                            <tbody>
                            {data?.byCategory.map(item => {
                                return item.map(it =>
                                    (
                                        <tr>
                                            <td>{it.categoryName}</td>
                                            <td>{it.discountName}</td>
                                            <td>{it.count}</td>
                                        </tr>
                                    ))
                            })
                            }
                            </tbody>
                        </table>
                    </Widget>
                    <h5>Dle typu objednávky</h5>
                    <Widget>

                        <table className={"table table-striped"}>
                            <thead>
                            <th>Typ objednávky</th>
                            <th>Počet</th>
                            </thead>
                            <tbody>
                            {data?.byTypeOrder.map(item => (
                                <tr>
                                    <td>{item.id == 1 ? "Klasická objednávka" : "Partnerský program"}</td>
                                    <td>{item.count}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </Widget>
                </>
            )
        }
    }

    const _renderFilter = () => {
        return (
            <Form onSubmit={(values) => getData(values.date, values.type)}
                  render={({handleSubmit, values, form}) => {
                return (
                    <Widget>
                        <div className={"w-full flex flex-col"}>
                            <div className={"flex w-1/2"}>
                                <DateTimeField name={"date"} label={"Zobrazit jiný den"} isMandatory={true}/>
                            </div>
                            <div className={"flex w-1/2"}>
                                <Button sizeButton={"btn-sm"} text={"Zobrazit"} loading={loading}
                                        disabled={loading} extraStyle={"mr-1"}
                                        onClick={() => {
                                            form.change('type', "JSON")
                                            handleSubmit()}
                                        }/>
                                <Button sizeButton={"btn-sm"} text={"Stáhnout XLS"} loading={loading}
                                        onClick={() => {
                                            form.change('type', "XLS")
                                            handleSubmit()}
                                        }
                                        disabled={loading}/>
                            </div>
                        </div>
                    </Widget>
                )
            }}/>
        )
    }

    return (
        <>
            <h3>Aktuální stav průchody {getDate()}</h3>
            {_renderFilter()}
            {getBody()}
        </>
    )

}

export default ActualStateVcb
