import axios from "axios";
import Toast from "cogo-toast";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { USER_RESET } from "../reducers/user";
import { config } from './index'

export const instance = axios.create({
    baseURL: config.backend.apiBaseUrl,
    headers: {
        'Content-Type': 'application/json',
    }
});
export const instanceENIGOOTurnikety = axios.create({
    headers: {
        'Content-Type': 'application/json',
    }
});

instance.interceptors.response.use(undefined, function (error) {
    if (error?.response?.status === 401) {
        localStorage.removeItem("authorization-leg-enigoo");
        Toast.success("Došlo k odhlášení. Prosím přihlašte se znovu.");

        let dispatch = useDispatch();
        dispatch({ type: USER_RESET });
        let navigate = useNavigate();
        navigate("/login");
    }
    if (error?.response?.status === 403) {
        Toast.warn("Nemáte dostatečná oprávnění.");
    }
    return Promise.reject(error);
});

export const onErrorFunction = (error) => {
    if(error?.response?.data){
        return error.response.data
    }else{
        return {type: null, message: null, data: null}
    }
}

export const onSuccessFunction = (data)=>{
    if(data?.result && data.data){
        return data.data;
    }
}


