import {Form} from "react-final-form";
import Widget from "../../../components/widget";
import CheckboxField from "../../../FinalForm/CheckboxField";
import {Button} from "../../../components/My/Button";
import React, {useEffect, useState} from "react";
import {apiGetSettingsVcb, apiSetSettingsVcb} from "../Actions";
import Toast from "cogo-toast";


const SettingsVcb = () => {

    let [data, setData] = useState(null);
    let [loading, setLoading] = useState(false)

    useEffect(() => {
        getSettings()
    }, []);

    const getSettings = () => {
        setLoading(true)
        apiGetSettingsVcb((data)=>{
            setData(data)
            setLoading(false)
        }, error=>{
            setLoading(false)
        })
    }

    const saveSettings = (values) => {
        apiSetSettingsVcb(values, (data)=>{
            Toast.success("Dokončeno.")
            setLoading(false)
        }, error=>{
            setLoading(false)
        })
    }

    if(loading) return <div>Loading...</div>;
    if(!data) return null

    return (
        <Form onSubmit={saveSettings} initialValues={data}
              render={({handleSubmit, values, form}) => {
                  return (
                      <Widget>
                          <div className={"w-full flex flex-col"}>
                              <div className={"flex w-1/2"}>
                                  <CheckboxField name={"cronRun"} label={"Stažení statistik zapnuto"} />
                              </div>
                              <div className={"flex w-1/2"}>
                                  <Button sizeButton={"btn-sm"} text={"Uložit"} onClick={handleSubmit}/>
                              </div>
                          </div>
                      </Widget>
                  )
              }}/>
    )
}

export default SettingsVcb
