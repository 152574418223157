import {instance, onErrorFunction, onSuccessFunction} from "../../../config/axios-cnf";

/**
 * @deprecated Old implementation
 */
export const apiPostEntrances = (subjectId, data, callback, error) => {
    instance.post("/api/v2/enigoo/" + subjectId + "/entrances", data)
        .then((response) => callback(onSuccessFunction(response.data)))
        .catch((err) => error(onErrorFunction(err)));

}
