import React from "react";
import {useDispatch, useSelector} from "react-redux";
import Datatable from "../../../components/datatable";
import {FiEdit} from "react-icons/fi";
import {Link} from "react-router-dom";
import moment from "moment/moment";

const TicketTable = ({data}) => {

   const dispatch = useDispatch();

   const {ticketSize, turnstilePage: ticketPage} = useSelector((state) => state.pages);

   let tableData = React.useMemo(() => {
      return data;
   }, [data])

   const pageSelectValueChanged = (value) => {
      dispatch({type: "SET_TICKET_PAGE_SIZE", payload: value})
   }

   const pageChanged = (value) => {
      dispatch({type: "SET_TICKET_PAGE", payload: value})
   }


   let columns = React.useMemo(() => [
      {Header: 'ID', accessor: 'id'},
      {Header: 'BC', accessor: 'bc'},
      {Header: 'Tarif', accessor: d => d.tariff.name},
      {Header: 'Platnost od a do', accessor: d => <div className={"flex flex-col"}>
            <div>{d.dateFrom ? moment(d.dateFrom).format("DD.MM.YYYY HH:mm:ss") : "-"}</div>
            <div>{d.dateTo ? moment(d.dateTo).format("DD.MM.YYYY HH:mm:ss") : "-"}</div>
         </div>
      },
      {Header: 'Počet vstupů', accessor: "entrance"},
      {Header: 'Událost id', accessor: "eventId"},
      {Header: "Jméno", accessor: d => d.name},
       {Header: "Blokováno", accessor: d => d.blocked ? <div className={"font-bold text-red-600"}>ANO</div> : <div className={"font-bold text-green-600"}>NE</div>},
      {
         Header: "Možnosti", accessor: d => (
            <div className={"flex flex-row"} >
               <Link to={"/ticket/detail/" + d.id} >
                  <button
                     className={"btn btn-default rounded btn-icon bg-blue-500 hover:bg-blue-400 space-x-2 mt-1 px-5 text-white "} >
                     <FiEdit size={20} />
                  </button>
               </Link>
            </div>
         )
      },

   ])

   return <Datatable data={tableData} columns={columns} selectable={false}
                     onPageChange={pageChanged} onPageSelectChange={pageSelectValueChanged}
                     pageSelectDefaultValue={ticketSize} defaultPage={ticketPage} />
}
export default TicketTable;
