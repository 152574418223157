import {Form} from "react-final-form";
import Widget from "../../components/widget";
import CheckboxField from "../../FinalForm/CheckboxField";
import {Button} from "../../components/My/Button";
import React, {useEffect, useState} from "react";

import Toast from "cogo-toast";
import {apiGetCron} from "./Action";
import TableData from "./TableData";


const Cron = () => {

    let [data, setData] = useState(null);
    let [loading, setLoading] = useState(false)

    useEffect(() => {
        getSettings()
    }, []);

    const getSettings = () => {
        setLoading(true)
        apiGetCron((data)=>{
            setData(data)
            setLoading(false)
        }, error=>{
            setLoading(false)
        })
    }

    const saveSettings = (values) => {
        /*apiSetSettingsVcb(values, (data)=>{
            Toast.success("Dokončeno.")
            setLoading(false)
        }, error=>{
            setLoading(false)
        })*/
    }

    if(loading) return <div>Loading...</div>;
    if(!data) return null

    return <TableData data={data} loading={loading} />
}

export default Cron
