import React, {useState} from 'react';
import DaySelectField from "../../../FinalForm/DaySelectField";
import DateTimeField from "../../../FinalForm/DateTimeField";
import InputField from "../../../FinalForm/InputField";
import PropTypes from "prop-types";

const ZoneTariffPriceListItem = ({name, index, remove}) => {
    return (
        <div className={"border border-grey-500 rounded p-2"}>
            <div className={"flex flex-row"}>
                <span className={"w-full"}>{`Ceník #${index + 1}`}</span>
                <span
                    onClick={() => {
                        remove(index)
                    }}
                    style={{cursor: 'pointer'}}>❌</span>
            </div>
            <DaySelectField name={`${name}.days`} isMulti={true}/>
            <DateTimeField onlyTime={true} type={"froms"} name={`${name}.timeFrom`} label={"Čas od"} isMandatory={true}/>
            <DateTimeField onlyTime={true} type={"to"} name={`${name}.timeTo`} label={"Čas do"} isMandatory={true}/>
            <InputField label={"Cena"} name={`${name}.price`} inputType={"number"} isMandatory={true}/>
        </div>
    )
}
ZoneTariffPriceListItem.propTypes = {
    name: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    remove: PropTypes.func.isRequired
}
export default ZoneTariffPriceListItem;
