import Datatable from "../../../components/datatable";
import React from "react";

const EntranceTableV2 = ({data,setData})=>{

    let tableData = React.useMemo(()=>{
        return data;
    },[data])

    const getTurnstileCel = (d)=>{
        let cell = "";
        if(d.turnstileDescription){
            cell += d.turnstileDescription;
        }
        if(d.turnstileIpAddress){
            cell += " (";
            cell += d.turnstileIpAddress;
            cell += ")";
        }
        if(cell.length === 0){
            return "-";
        }
        return cell;
    }

    const getResultCell = (d) => {

        switch(d.result){
            case "ENTRY_SUCCESS":
                return <span className={"text-green-600 font-bold"}>Úšpešný vchod</span>;
            case "EXIT_SUCCESS":
                return <span className={"text-green-600 font-bold"}>Úspěšný východ</span>;
            case "ENTRY_IN_AREA":
                return <span className={"text-green-600 font-bold"}>Úspěšný průchod v areálu</span>;
            case "NOT_FOUND":
                return <span className={"text-red-600 font-bold"}>Vstupenka nenalezena</span>;
            case "IN_ZONE":
                return <span className={"text-red-600 font-bold"}>Již vstoupil</span>;
            case "NOT_IN_ZONE":
                return <span className={"text-red-600 font-bold"}>Ještě nevstoupil</span>;
            case "CARD_USED":
                return <span className={"text-red-600 font-bold"}>Vstupenka vyčerpána</span>;
            case "INCORRECT_ZONE":
                return <span className={"text-red-600 font-bold"}>Špatná zóna/vchod</span>;
            case "UNKNOWN_TURNSTILE":
                return <span className={"text-red-600 font-bold"}>Neznámý turniket</span>;
            case "NOT_IN_PARENT_ZONE":
                return <span className={"text-red-600 font-bold"}>Není v nadřazené zóně</span>;
            default:
                return <span className={"text-red-600 font-bold"}>Neznámý stav</span>;
        }
    }

    let columns = React.useMemo(()=>[
        {Header: 'Datum', accessor: 'date'},
        {Header: 'BC', accessor: 'bc'},
        {Header: 'Jméno',accessor: 'ticketName'},
        {Header: 'Sleva vstupenky',accessor: d=>{
                switch(d.ticketType){
                    case 1:
                        return "Děti";
                    case 2:
                        return "Zlevněná (ZTP, Student, Senior)";
                    default:
                        return "";
                }
            }},
        {Header: 'Typ vstupenky',accessor: d=>{
                switch (d.ticketTicketType){
                    case 0:
                        return "Servisní karta";
                    case 2:
                        return "Permanentka s kreditem";
                    case 1:
                        return "Běžná vstupenka"
                    default:
                        return "-";

                }
            }},
        {Header: 'Tarif',accessor: 'tariffName'},
        {Header: 'Událost id', accessor: 'eventId'},
        {Header: 'Turniket',accessor: d=> {return getTurnstileCel(d)}},
        {Header: 'Výsledek',accessor: d=> {return getResultCell(d)}},
        {Header: 'Typ průchodu', accessor: d => {
            switch(d.type){
                case 0:
                    return "Východ";
                case 1:
                    return "Vchod";
                case 2:
                    return "Průchod v areálu";
                default:
                    return "-";
            }}
        },
        {Header: 'Zóna', accessor: 'zoneName'},
        {Header: 'Částka',accessor: 'price'}
    ],[])

    return <Datatable data={tableData} columns={columns} selectable={false}/>
}
export default EntranceTableV2;
