import React, {useState} from "react"
import QrReader from 'react-qr-scanner'
import Widget from "../../components/widget";
import {Form} from "react-final-form";
import SubjectField from "../../FinalForm/api/SubjectField";
import {Button} from "../../components/My/Button";
import InputField from "../../FinalForm/InputField";
import {apiGetValidateTicket} from "./Actions";
import Toast from "cogo-toast";
import TableData from "./TableData";
import TableDataServices from "./TableDataServices";
import {EnigooLoader} from "../../components/My/EnigooLoader";


const Validation = () => {

    let [loading, setLoading] = useState(false)
    let [qrReader, setQrReader] = useState(false)
    let [data, setData] = useState(null)
    let valuesModified = null
    let [init, setInit] = useState(false)


    const search = (values) => {
        setData(null)
        setLoading(true)
        apiGetValidateTicket(values, (data) => {
            setData(data)
            setLoading(false)
        }, error => {
            switch (error.response.data?.error?.type) {
                case "ENTRY_TICKET_NOT_FOUND":
                case "TICKET_NOT_FOUND":
                    Toast.error("Vstupenka nenalezena.")
                    break;
                case "UNAUTHORIZED":
                case "SUBJECT_NOT_EXIST":
                    Toast.error("Vyskytla se chyba.")
                    break;
                default:
                    Toast.error("Nastala neočekávaná chyba.")
            }
            setLoading(false)
        })
    }

    const validate = (values) => {
        let error = {}
        if (!values.subjectId) error.subjectId = true;
        if (!values.ticketId && !values.bc) {
            error.ticketId = true;
            error.bc = true;
        }

        return error
    }

    const getInfo = () => {
        if(!data) return null
        if (!data.isSubjectValidation) {
            return <h3>Tento areál není na validaci nastaven.</h3>
        }
        if (data?.information?.length === 0) {
            return <h3>Vstupenka nebyla nalezena.</h3>
        }

        return (
            <Widget title={"Informace o vstupence"}>
                <div className={"relative overflow-x-auto"}>
                    <table className={"table striped"}>
                        <tbody className={""}>
                        <tr>
                            <td>Barcode:</td>
                            <td>{data?.information?.barcode}</td>
                        </tr>
                        <tr>
                            <td>Datum prodeje</td>
                            <td>{data?.information?.datetime}</td>
                        </tr>
                        <tr>
                            <td>Typ vstupenek</td>
                            <td>{data?.information?.type}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </Widget>
        )
    }

    const getEntrances = () => {
        if (!data || data?.information?.length === 0) return null
        if (data?.entrances?.length === 0) {
            return <h4>Nebyly nalezeny žádné průchody.</h4>
        }
        return <TableData data={data.entrances}/>
    }

    return (
        <div className={"flex flex-column "}>
            <div className={"w-full lg:w-1/2"}>
                <Widget>
                    <Form onSubmit={search}
                          validate={validate}
                          initialValues={init}
                          render={({values, handleSubmit, form}) => {
                              valuesModified = values
                              return (
                                  <div>
                                      <SubjectField name={"subjectId"} label={"Subject"}
                                                    onSingleItem={(item) => setInit({subjectId: item.id})}/>
                                      <InputField onEnter={handleSubmit} name={"bc"} label={"Barcode vstupenky"}
                                                  isMandatory={false}/>

                                      <Button text={"Vyhledat"} onClick={handleSubmit} loading={loading}/>
                                      {values?.subjectId && ((qrReader) ?
                                          <QrReader
                                              delay={500}
                                              style={{width: "100%"}}
                                              onError={(err) => {
                                                  console.log("error: ", err)
                                              }}
                                              onScan={(data) => {
                                                  if (data) {
                                                      setQrReader(false)
                                                      form.change("bc", data.text)
                                                      form.submit()
                                                  }

                                              }}
                                          /> :
                                          <Button text={"Spustit čtení qr"} loading={loading}
                                                  onClick={() => setQrReader(true)}/>)}
                                  </div>
                              )
                          }}/>
                </Widget>
                {loading && <EnigooLoader/>}
                {getInfo()}
                {data?.services?.length > 0 && <TableDataServices data={data.services}/>}
                {getEntrances()}

            </div>
        </div>
    )

}

export default Validation
