import React, {useEffect} from "react";
import Toast from "cogo-toast";
import Datatable from "../../../components/datatable";
import {apiDeleteTariff} from "./Actions";
import {Link} from "react-router-dom";
import {FiDelete, FiEdit} from "react-icons/fi";
import {useSelector} from "react-redux";

const TariffTable = ({data, setData}) => {

    let tableData = React.useMemo(() => {
        return data;
    }, [data])
    let user = useSelector((state) => state.user)

    const deleteItem = (id) => {
        apiDeleteTariff(user.activeAreal.id,id, (response) => {
            let array = [...data];
            array = array.filter((val) => val.id != id);
            setData(array);
            Toast.success("Tarif byl odstraněna");
        }, (error) => {
            let errorMessage = "Nepodařilo se odstranit tarif.\n"
            switch (error.data) {
                case "TARIFF_NOT_FOUND":
                    errorMessage += "Nebyl nalezen.";
                    break;
                default:
                    errorMessage += "Neznámá chyba";
                    break;
            }
            Toast.error(errorMessage);
        })

    }

    let columns = React.useMemo(() => [
        {Header: 'ID', accessor: 'id'},
        {Header: 'Název', accessor: 'name'},
        {Header: 'Musí nejdříve vstoupit', accessor: d => d.reverse === 0 ? "ANO" : "NE"},
        {Header: 'Prochází denně', accessor: d => d.daily === 1 ? "ANO" : "NE"},
        {
            Header: "Možnosti", accessor: d => (
                <div className={"flex flex-row"}>
                    <Link to={"/tariff/detail/" + d.id}>
                        <button
                            className={"btn btn-default rounded btn-icon bg-blue-500 hover:bg-blue-400 space-x-2 mt-1 px-5 text-white "}>
                            <FiEdit size={20}/>
                        </button>
                    </Link>
                        {/*<button
                            className={"btn btn-default rounded btn-icon bg-red-500 hover:bg-blue-400 space-x-2 mt-1 px-5 text-white ml-2 "}
                            onClick={() => {
                                deleteItem(d.id)
                            }}>
                            <FiDelete size={20}/>
                        </button>*/}
                </div>
            )
        }
    ])

    return <Datatable data={tableData} columns={columns} selectable={false} />
}
export default TariffTable;
