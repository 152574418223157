import React, {useEffect, useState} from "react"
import TableData from "./TableData";
import Widget from "../../components/widget";
import {Button} from "../../components/My/Button";
import {apiGetUsers} from "./Actions";
import UserFormModal from "./UserFormModal";

const Users = () => {

    let [loading, setLoading] = useState(false)
    let [data, setData] = useState(null)
    let [item, setItem] = useState(null)
    let [modalUser, setModalUser] = useState(false)

    useEffect(() => {
        getUser()
    }, [])

    const getUser = () => {
        setLoading(true)
        apiGetUsers((data) => {
            setData(data)
            setLoading(false)
        }, (err) => {
            //console.log(err)
        })
    }

    return (
        <>
            <Widget>
                <div className={"flex flex-row text-black"}>
                    <h5 className={"align-middle"}>Uživatelé</h5>
                    <span className="ml-auto"></span>
                    <div className={"flex"}>
                        <Button text={"Přidat uživatele"} onClick={() => setModalUser(true)}/>
                    </div>
                </div>
            </Widget>
            <TableData data={data} setItem={(item) => {
                setItem(item)
                setModalUser(true)
            }} loading={loading}/>
            <UserFormModal data={item} visible={modalUser} close={(renew) => {
                setItem(null)
                setModalUser(false)
                if(renew) getUser()
            }}/>

        </>
    )

}

export default Users
